import { Link, useMatch, useParams } from "react-router-dom";
import { myCourseDataType } from "../../../types";
import SidebarWrapper from "../SidebarWrapper";
import MyCourses from "./MyCourses";
import { useCourseContext } from "../../../contexts/CourseContext";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { SidebarLink } from "../SidebarLink";
import { GridSquareIcon } from "../../../../shared/icons/GridSquareIcon";
import AllCourses from "../../../../shared/icons/AllCourses";
import { SkeletonCircle, SkeletonText } from "../../Skeleton";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";

const FAKE_LINKS = [
  "Et reprehenderit proident",
  "Eiusmod ipsum",
  "In enim deserunt",
  "Aliqua aliqua laborum",
  "Aliquip nostrud",
  "Lorem consectetur mollit dolore",
];

export default function Sidebar(): JSX.Element {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const courseData = courseContext.getCourseData(coursePath);
  const allCoursesData = courseContext.getAllCoursesData();
  const { isSidebarMinimized } = useLearnerContext();

  const unitMatch = useMatch(
    `${REACT_APP_LEARNER_LINK}/:coursePath/:unitPath/*`
  );
  const isUnit = unitMatch !== null && coursePath !== undefined;

  const isDashboard = useMatch(`${REACT_APP_LEARNER_LINK}/dashboard`) !== null;

  const learnerContext = useLearnerContext();

  const currentCourses = courseContext.getCurrentLearnerCourses(
    courseData?.id,
    learnerContext
  );

  return (
    <SidebarWrapper showCalculator={false} upperSection={<></>}>
      <div className="flex flex-col gap-2 pb-4">
        <SidebarLink
          to={`${REACT_APP_LEARNER_LINK}/dashboard`}
          icon={<GridSquareIcon />}
        >
          {!isSidebarMinimized && "Dashboard"}
        </SidebarLink>
        <div className="sidebar-inner-scroll flex flex-col gap-2 overflow-auto">
          {allCoursesData.length
            ? currentCourses.map((course: myCourseDataType) => (
                <MyCourses
                  key={`mycourse-${course?.id}`}
                  id={course?.id}
                  name={course?.name}
                  progress={course?.progress}
                  units={course?.units}
                />
              ))
            : FAKE_LINKS.map((link) => (
                <div className="p-2" key={link}>
                  <div className="flex gap-2">
                    <div className="h-6 w-6">
                      <SkeletonCircle />
                    </div>
                    <SkeletonText>{link}</SkeletonText>
                  </div>
                </div>
              ))}
        </div>
        {learnerContext.isStaff() && (
          <SidebarLink
            to={`${REACT_APP_LEARNER_LINK}/courses`}
            icon={<AllCourses />}
          >
            {!isSidebarMinimized && "Browse All Courses"}
          </SidebarLink>
        )}
      </div>
    </SidebarWrapper>
  );
}
