import { useEffect, useState, useRef } from "react";
import * as Sentry from "@sentry/react";
import NavBar from "./layout/NavBar";
import Sidebar from "./layout/Sidebar";
import Main from "./layout/Main";
import Solve from "./layout/Solve";
import Link from "./layout/Link";
import Cookies from "js-cookie";
import {
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_STUDENT_LINK,
  REACT_APP_URL_PREFIX,
  isRequestFromLocal,
  isRequestFromReviewapp,
  isTokenExpired,
  useDMQuery,
} from "../utils";
import { useQueryClient } from "react-query";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import StudentSectionsContext from "./_context/StudentSectionsContext";
import Loading from "./components/Loading";
import {
  getActiveSectionData,
  processAssignmentData,
  findNearestUpcoming,
  useFieldFocus,
  autoArchive,
} from "./utils";
import Profile from "./components/Profile";
import Copyright from "./components/Copyright";
import Login from "../manager/components/Login";
import "./index.css";
import { renderA11yString } from "./utils/render-a11y-string";
import CodeModules from "./components/code-modules/code-modules";
import TimedModal from "./components/TimedModal";
import DMKeyboard, { AnswerData } from "./components/calculator/DMKeyboard";
import AddStudentId from "./components/AddStudentId";
import { useUserContext } from "../shared/contexts/UserContext";
import { useDeltaToastContext } from "../shared/contexts/ToasterContext";
import ReactTooltip from "react-tooltip";
import StudentHelpVideo from "./components/StudentHelpVideo";
import { clone, isEmpty } from "lodash";
import IdleCheck from "./components/IdleCheck";
type Props = {
  section?: string;
};

export default function Student({ section = "main" }: Props): JSX.Element {
  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const toastContext = useDeltaToastContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);
  const [dmSectionsData, setDmSectionsData] = useState<any>([]);
  const [dmAssignmentData, setDmAssignmentData] = useState<any>({});
  const customExternalFiles = useRef<Map<string, any>>(new Map());
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false); // determines whether to render calculator or not
  const [calcAvailable, setCalcAvailable] = useState(false);
  const [globalInputsMap, setGlobalInputsMap] = useState<Map<string, any>>(
    new Map()
  );
  const [globalFocusedInput, { handleGlobalFocus }] = useFieldFocus("");
  const [answerData, setAnswerData] = useState<AnswerData>({
    latex: "",
    prevLatex: "",
    currentAnswer: "",
    currentAnswerFull: 0,
    prevAnswer: "",
    prevAnswerFull: 0,
  });

  const [loadingData, setLoadingData] = useState<any>({
    error: false,
    isShowing: true,
    title: "Error",
    message: "You encountered an error",
  });

  const [userValues, setUserValues] = useState<any>({
    hasPlus: false,
  });

  const [openAssignment, setOpenAssignment] = useState<any>(null);

  const [isMfeLoaded, setIsMfeLoaded] = useState<boolean>(false);

  const { sectionId: activeSection, teacherId } = useParams();

  const [studentNextProblems, setStudentNextProblems] = useState<
    Record<number, any>
  >({});

  const [currentProblemData, setCurrentProblemData] = useState<any>(undefined);

  const [isTimedModalShowing, setTimedModalShowing] = useState<boolean>(false);

  const [showPastDue, setShowPastDue] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string | null>(null);

  const [openAccordions, setOpenAccordions] = useState<any>([]);

  // If this exists in local storage it was an LTI launch
  const ltiPayload = JSON.parse(
    localStorage.getItem("lti_assignment_payload") || "{}"
  );
  const ltiResource = ltiPayload.isLtiResourceLaunch;
  const ltiLmsId = ltiPayload.lti_context?.lms_id;

  // if id user had when they logged in initially is now different
  // from what's in localstorage, send them to the homepage

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const currentUserId = useRef(user?._id);

  // console.log("initial user._id:", user?._id);
  // console.log("initial currentUserId.current:", currentUserId?.current);
  // if currentUserId isn't defined, try every second to set its value
  useEffect(() => {
    // console.log("UE initial currentUserId.current:", currentUserId?.current);
    const interval: any = currentUserId?.current
      ? null
      : setInterval(() => {
          const user = JSON.parse(localStorage.getItem("user") || "{}");
          // console.log("UE interval user._id:", user?._id);
          if (user?._id) {
            // console.log(
            //   "interval before currentUserId.current:",
            //   currentUserId?.current
            // );
            currentUserId.current = user?._id;
            // console.log(
            //   "interval after currentUserId.current:",
            //   currentUserId?.current
            // );
            clearInterval(interval);
          }
        }, 1000);
    // console.log("typeof interval:", typeof interval);
    return () => clearInterval(interval);
  }, [currentUserId?.current]);

  useEffect(() => {
    function handleStorage() {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      // console.log("handleStorage() user._id:", user?._id);
      // console.log(
      //   "handleStorage() currentUserId?.current:",
      //   currentUserId?.current
      // );
      // console.log(
      //   "handleStorage() currentUserId.current !== user?._id:",
      //   currentUserId.current !== user?._id
      // );
      // console.log(
      //   "handleStorage() !!currentUserId?.current:",
      //   !!currentUserId?.current
      // );

      if (!!currentUserId?.current && currentUserId.current !== user?._id) {
        console.warn("mismatched user ids.", currentUserId.current, user?._id);
        window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
        window.removeEventListener("storage", handleStorage);
      }
    }

    // console.log("storage currentUserId.current:", currentUserId?.current);

    if (!currentUserId?.current) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      // console.log("storage user._id:", user?._id);
      currentUserId.current = user?._id;
    }
    window.addEventListener("storage", handleStorage);

    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  useEffect(() => {
    if (showCalculator) {
      handleGlobalFocus("math-input-0");
    }
  }, [showCalculator]);

  useEffect(() => {
    document.title = "DeltaMath Student Application";
    document.body.classList.add("h-full");
    const html = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
    html?.setAttribute("class", "h-full bg-gray-100");
    const root = document.getElementById("root");
    root?.classList.add("h-full");
  }, []);

  useEffect(() => {
    if (activeSection && dmSectionsData) {
      const section = getActiveSectionData(activeSection, dmSectionsData);
      if (section) {
        if (section.calculator === false) {
          setCalcAvailable(false);
        } else {
          setCalcAvailable(true);
        }
      }
    }
  }, [activeSection, dmSectionsData]);

  const logout = () => {
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    userContext.clearJWT();
    Cookies.remove("refresh_token_javascript");
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    Sentry.setUser(null);
    setLoggedIn(false);
  };

  const { refetch: sectionsRefresh, status: sectionsStatus } = useDMQuery({
    path: "/student/data/sections",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDmSectionsData(data);
      },
      onError: (error: any) => {
        if (error.code === 403) {
          logout();
        }
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: `${error?.response?.data?.message || error?.error || ""} ${
            prevState.message || ""
          }`,
        }));
      },
    },
  });

  const {
    refetch: assignmentsRefresh,
    isFetched: assignmentsIsFetched,
    status: assignmentsStatus,
  } = useDMQuery({
    path: "/student/data/assignments",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDmAssignmentData({
          ...dmAssignmentData,
          ...processAssignmentData(data),
        });
        if (!loadingData?.error) {
          setLoadingData({ ...loadingData, isShowing: false });
        }

        const resetErrors = Object.values(data)
          .flat()
          .filter(
            (assigmnent: any) => assigmnent.sa.sampleTimedReset === true
          ).length;
        if (resetErrors > 0) {
          toastContext.addToast({
            status: "Warning",
            message: `A Timed Assignment was reset, erasing any previous timers. This only occurs while using the Sample Student feature.`,
            dismiss: "manual",
          });
        }
      },
      onError: (error: any) => {
        if (error.code === 403) {
          logout();
        }
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: `${
            error?.response?.data?.message ||
            error?.error ||
            error?.message ||
            prevState.message ||
            ""
          }`,
        }));
      },
    },
  });

  const { refetch: archivedSectionRefetch } = useDMQuery({
    path: `/student/data/assignments/?secId=${activeSection}`,
    // cacheKey: ["/student/data/assignments", `${activeSection}`],
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDmAssignmentData({
          ...dmAssignmentData,
          ...processAssignmentData(data),
        });
        setLoadingData({ ...loadingData, isShowing: false });
      },
      onError: (error: any) => {
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: `${error?.response?.data?.message || error?.error || ""} ${
            prevState.message || ""
          }`,
        }));
      },
    },
  });

  useEffect(() => {
    const user_to_login = JSON.parse(
      localStorage.getItem("user_to_login") || "{}"
    );
    if (user_to_login.user) {
      localStorage.setItem("user_to_login", "");
      localStorage.setItem("user", JSON.stringify(user_to_login.user));
      if (user_to_login.admin) {
        localStorage.setItem("admin", JSON.stringify(user_to_login.admin));
      }
      localStorage.setItem(
        "preferences",
        JSON.stringify(user_to_login.user.preferences)
      );
    }
  }, []);

  useEffect(() => {
    const jwt = userContext.getJWT();
    const refresh_token_cookie = Cookies.get("refresh_token_javascript");
    // User has non-expired JWT or refresh token cookie
    if (
      (jwt && !isTokenExpired(jwt)) ||
      refresh_token_cookie ||
      isRequestFromReviewapp(window.location.origin)
    ) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      // Set them as logged in
      if (user?.login) {
        setLoadingData({ ...loadingData, isShowing: true });
        sectionsRefresh();
        assignmentsRefresh();
        Sentry.setUser({
          id: user._id,
        });
        setLoggedIn(true);
      } else {
        // Log them out
        queryClient.invalidateQueries();
        userContext.clearJWT();
        localStorage.removeItem("admin");
        localStorage.removeItem("user");
        Sentry.setUser(null);
        setLoggedIn(false);

        if (
          !isRequestFromReviewapp(window.location.origin) &&
          !isRequestFromLocal(window.location.origin)
        ) {
          // Gatsby doesn't need the /app/ prefix for the redirect
          const newPath = window.location.pathname.replace(
            `${REACT_APP_URL_PREFIX}/`,
            ""
          );
          window.location.href = `${REACT_APP_HOMEPAGE_LINK}/sign-in?redirectUrl=${newPath}`;
        }
      }
    } else if (
      !isRequestFromReviewapp(window.location.origin) &&
      !isRequestFromLocal(window.location.origin)
    ) {
      // Gatsby doesn't need the /app/ prefix for the redirect
      const newPath = window.location.pathname.replace(
        `${REACT_APP_URL_PREFIX}/`,
        ""
      );
      window.location.href = `${REACT_APP_HOMEPAGE_LINK}/sign-in?redirectUrl=${newPath}`;
      Sentry.setUser(null);
      setLoggedIn(false);
    } else {
      Sentry.setUser(null);
      setLoggedIn(false);
    }
    // The implied else case is that loggedIn is false, and we show the login form below
  }, [loggedIn]);

  const isLocked = userContext.getIsLocked();
  const jwtTeacherId = userContext.getTAid()?.toString();

  // if assignment is locked/restricted, force student into that assignment
  useEffect(() => {
    // The assignment API request is what will update the JWT if necessary so before we navigate
    // we want to make sure assignment api request is successful
    if (
      isLocked &&
      teacherId !== jwtTeacherId &&
      assignmentsStatus === "success"
    ) {
      // This is just making sure that we actually have the assignment loaded in to redirect to
      const sections = Object.keys(dmAssignmentData);
      const assignmentLoaded = sections.some((section) =>
        dmAssignmentData[Number(section)].some(
          (assignment: any) => assignment.ta._id === Number(jwtTeacherId)
        )
      );
      if (assignmentLoaded) {
        navigate(`${REACT_APP_STUDENT_LINK}/link/${jwtTeacherId}`);
      }
    }
  }, [isLocked, teacherId, jwtTeacherId, assignmentsStatus, dmAssignmentData]);

  // if user is at default /student page, redirect them to
  // the section with their first upcoming assignment
  useEffect(() => {
    if (
      typeof activeSection === "undefined" &&
      Array.isArray(dmSectionsData) &&
      dmSectionsData.length > 0 &&
      assignmentsIsFetched &&
      !location.pathname.includes("profile") &&
      !location.pathname.includes("code-modules") &&
      !location.pathname.includes("help-video") &&
      section !== "link" &&
      !ltiResource &&
      !isLocked
    ) {
      if (ltiLmsId) {
        const sectionToNavigateTo = dmSectionsData.find(
          (section) => section.lms_id === ltiLmsId
        );
        if (sectionToNavigateTo) {
          navigate(
            `${REACT_APP_STUDENT_LINK}/${sectionToNavigateTo._id}/upcoming`,
            { replace: true }
          );
          return;
        }
      }

      const allSectionsSorted =
        dmSectionsData.length > 1
          ? dmSectionsData.sort((a, b) => b._id - a._id)
          : dmSectionsData;
      const currentSections = dmSectionsData
        .filter(
          (section) =>
            section.term === "current" &&
            !autoArchive(dmAssignmentData, section._id)
        )
        .map((section) => section._id);

      // Most recent section starts by sorting our current section and picking the one with the highest id
      // if we only have 1 current section we chose that
      // if we don't have any current sections we chose the first of the potentially sorted sections
      const mostRecentSection =
        currentSections.length > 1
          ? currentSections.sort((a, b) => b - a)[0]
          : currentSections.length > 0
          ? currentSections[0]
          : allSectionsSorted[0]._id;

      const archivedSections = dmSectionsData
        .filter(
          (section) =>
            section.term !== "current" ||
            autoArchive(dmAssignmentData, section._id)
        )
        .map((section) => section._id);

      // this will take a clone of dmAssignmentData and remove any potentially archived sections from the object
      // we do this so we can in the navigate function below find the nearest upcoming of current sections only
      const assignmentData = clone(dmAssignmentData);
      for (const section of archivedSections) {
        delete assignmentData[section];
        // delete dmSectionsData[section]
      }

      navigate(
        `${REACT_APP_STUDENT_LINK}/${
          findNearestUpcoming(assignmentData) ?? mostRecentSection
        }/upcoming`,
        { replace: true }
      );
    }
  }, [activeSection, dmSectionsData, assignmentsIsFetched]);

  // wait for the initial assignment data to be sorted before checking if the activeSection
  // exists in the list, and if not, call the archived endpoint
  useEffect(() => {
    if (!assignmentsIsFetched || section === "link") {
      return;
    } else if (
      activeSection &&
      dmAssignmentData &&
      !(activeSection in dmAssignmentData) &&
      Object.keys(dmSectionsData).length &&
      getActiveSectionData(activeSection, dmSectionsData)?.term !== undefined &&
      getActiveSectionData(activeSection, dmSectionsData)?.term !== "current" &&
      !location.pathname.includes("profile") &&
      !location.pathname.includes("code-modules") &&
      !location.pathname.includes("help-video")
    ) {
      // if activeSection exists, activeSection is not in dmAssignmentData yet, and
      // activeSession is not 'current' in dmSectionsData, it must be an archived assignment
      // we have not fetched yet
      setLoadingData({ ...loadingData, isShowing: true });
      archivedSectionRefetch();
    }
  }, [dmAssignmentData, activeSection, dmSectionsData]);

  // Check if user has Plus account
  useEffect(() => {
    if (activeSection && Object.keys(dmSectionsData).length) {
      setUserValues({
        ...userValues,
        hasPlus:
          getActiveSectionData(activeSection, dmSectionsData)?.has_plus ||
          false,
      });

      const section = getActiveSectionData(activeSection, dmSectionsData);

      if (section === undefined) {
        navigate(`${REACT_APP_STUDENT_LINK}/${activeSection}/upcoming`);
        return;
      }
    }
  }, [activeSection, dmSectionsData]);

  window.renderA11yString = renderA11yString;

  ReactTooltip.rebuild();

  /***********************************
   *
   * LTI HIDING MAIN AND REDIRECTING
   *
   ***********************************/

  useEffect(() => {
    if (
      ltiResource &&
      loggedIn &&
      !window.location.href.includes("profile") &&
      !window.location.href.includes("code-modules") &&
      !window.location.href.includes("help-video") &&
      section !== "solve" &&
      section !== "link"
    ) {
      if (dmAssignmentData) {
        const keys = Object.keys(dmAssignmentData);
        if (keys && keys.length > 0) {
          const assignmentToRedirectTo = dmAssignmentData[keys[0]];
          const taId =
            assignmentToRedirectTo &&
            assignmentToRedirectTo.length > 0 &&
            assignmentToRedirectTo[0].ta
              ? assignmentToRedirectTo[0].ta._id
              : undefined;
          if (taId) {
            navigate(`${REACT_APP_STUDENT_LINK}/link/${taId}`);
          }
        }
      }
    }
  }, [dmAssignmentData, ltiResource, loggedIn, section, window.location.href]);

  if (
    ltiResource &&
    loggedIn &&
    !window.location.href.includes("profile") &&
    !window.location.href.includes("code-modules") &&
    !window.location.href.includes("help-video") &&
    section !== "solve" &&
    section !== "link"
  ) {
    return <Loading {...loadingData} setLoadingData={setLoadingData} />;
  }
  /***********************************
   *
   * END LTI REDIRECT LOGIC
   *
   ***********************************/

  if (loggedIn === undefined) {
    return <Loading {...loadingData} setLoadingData={setLoadingData}></Loading>;
  }

  return (
    <>
      {!loggedIn && <Login setLoggedIn={setLoggedIn} />}
      {loggedIn && dmSectionsData ? (
        <StudentSectionsContext.Provider
          value={{
            dmSectionsData,
            sectionsStatus,
            assignmentsIsFetched,
            activeSection,
            loadingData,
            setLoadingData,
            assignmentsRefresh,
            sectionsRefresh,
            dmAssignmentData,
            setDmAssignmentData,
            openAssignment,
            setOpenAssignment,
            userValues,
            isMfeLoaded,
            setIsMfeLoaded,
            studentNextProblems,
            setStudentNextProblems,
            setTimedModalShowing,
            customExternalFiles,
            showKeyboard,
            setShowKeyboard,
            showCalculator,
            setShowCalculator,
            globalInputsMap,
            setGlobalInputsMap,
            globalFocusedInput,
            handleGlobalFocus,
            calcAvailable,
            setCalcAvailable,
            currentProblemData,
            setCurrentProblemData,
            showPastDue,
            setShowPastDue,
            setTooltipText,
            tooltipText,
            openAccordions,
            setOpenAccordions,
          }}
        >
          <IdleCheck />
          {loggedIn && section !== "link" && (
            <div className="flex h-full flex-col">
              <>
                <a
                  className="skipToMainContent bg-white text-dm-blue hover:underline"
                  href="#main-content"
                >
                  Skip to main content
                </a>
                <NavBar setSidebarOpen={setSidebarOpen} />
                <div className="flex flex-grow flex-col bg-dm-background-blue-100 sm:flex-row">
                  <Sidebar
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    pageType={section === "solve" ? "solve" : "standard"}
                  />
                  <div className="flex w-full flex-grow flex-col justify-end lg:w-3/4">
                    {window.location.href.includes("profile") ? (
                      <Profile />
                    ) : window.location.href.includes("code-modules") ? (
                      <CodeModules />
                    ) : window.location.href.includes("help-video") ? (
                      <StudentHelpVideo />
                    ) : section !== "solve" ? (
                      <Main />
                    ) : (
                      <Solve />
                    )}
                    <Copyright />
                  </div>
                  {showKeyboard && (
                    <DMKeyboard
                      close={() => setShowKeyboard(false)}
                      focusedInput={globalFocusedInput}
                      input={globalInputsMap}
                      showCalculator={showCalculator}
                      // previousAnswer={previousAnswer}
                      // setPreviousAnswer={setPreviousAnswer}
                      answerData={answerData}
                      setAnswerData={setAnswerData}
                      showKeyboard={showKeyboard}
                      handleGlobalFocus={handleGlobalFocus}
                    />
                  )}
                </div>
              </>
            </div>
          )}
          {loggedIn &&
            section === "link" &&
            dmAssignmentData &&
            !isEmpty(dmAssignmentData) && (
              <div className="sm:px-auto fixed inset-0 flex flex-col items-center justify-center bg-gray-600 px-2">
                <Link
                  assignments={dmAssignmentData}
                  refetchAssignments={() => {
                    assignmentsRefresh();
                  }}
                />
              </div>
            )}
          <AddStudentId />
          <ReactTooltip
            id="piechart-app"
            effect="solid"
            delayShow={100}
            delayHide={50}
            multiline={true}
            html={true}
            place="right"
            getContent={() => tooltipText}
          />
        </StudentSectionsContext.Provider>
      ) : null}
      {loggedIn && <Loading {...loadingData} setLoadingData={setLoadingData} />}
      <TimedModal
        isShowing={isTimedModalShowing}
        setTimedModalShowing={setTimedModalShowing}
        setLoadingData={setLoadingData}
      />
    </>
  );
}
