import clsx from "clsx";
import { HTMLAttributes } from "react";

export const TiltedBanner: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({
  className,
  ...props
}) => (
  <h5
    className={clsx(
      "absolute rotate-[18deg] rounded-full bg-[#7BD78C] px-8 py-2",
      className
    )}
    {...props}
  >
    For Parents and Learners
  </h5>
);
