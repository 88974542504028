import { useState } from "react";
import { SearchInput } from "./SearchInput";
import { SearchResults } from "./SearchResults";

export const CourseSearch: React.FC = () => {
  const [query, setQuery] = useState("");
  const id = "search-results";
  return (
    <div className="relative w-full">
      <SearchInput resultsId={id} onQuery={(q) => setQuery(q)} />
      <SearchResults id={id} query={query} />
    </div>
  );
};
