import React, { useState, Fragment } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { GetStudentInfo, DeleteStudent } from "../api/index";
import { deltamathAPI, getDatesArray } from "../utils";
import { DeltaMathSearchableTable } from "../../shared/DeltaMathSearchableTable";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import axios from "axios";

//[MB - 2.13.24] probably not needed now but adding some interfaces on the theory that this may get built more down the line

interface StudentInfoInterface {
  first: string;
  last: string;
  login: string;
  _id: string;
  lms_email?: string;
}

interface MenuOptionsInterface {
  deleteAccount: boolean;
  changeInfo: boolean;
}

const menuReducer = (state: MenuOptionsInterface, action: { type: any }) => {
  switch (action.type) {
    case "reset":
      return {
        deleteAccount: false,
        changeInfo: false,
      };

    case "delete-account":
      return {
        deleteAccount: true,
        changeInfo: false,
      };

    case "change-info":
      return {
        deleteAccount: false,
        changeInfo: true,
      };

    default:
      throw new Error();
  }
};

export const SearchStudent = () => {
  const toastContext = useDeltaToastContext();

  const currentUnixTime = Math.floor(Date.now() / 1000);
  const october = getDatesArray({})[0];
  const octoberUnix = Math.floor(october.getTime() / 1000.0); // convert to UNIX epoch
  const queryClient = useQueryClient();

  const defaultStudent: StudentInfoInterface = {
    first: "",
    last: "",
    login: "",
    _id: "",
  };

  const [viewingStudent, setViewingStudent] =
    useState<StudentInfoInterface>(defaultStudent);

  let rowData: StudentInfoInterface[] = [];

  const [payload, setPayload] =
    useState<Partial<StudentInfoInterface>>(defaultStudent);

  const restoreDefaults = () => {
    setPayload(defaultStudent);
    setViewingStudent(defaultStudent);
    rowData = [];
  };

  const [menuState, dispatch] = React.useReducer(menuReducer, {
    deleteAccount: false,
    changeInfo: false,
  });

  const deleteSuccess = (successData: {
    success: boolean;
    message: string;
    deletedEmail: string;
  }) => {
    const { success, message } = successData;
    if (success) {
      toastContext.addToast({
        status: "Success",
        message: message,
        dismiss: "manual",
        largeDismissButton: true,
      });
    }
  };

  const deleteError = (error: any) => {
    toastContext.addToast({
      status: "Error",
      message: `${error}`,
    });
  };

  const deleteStudent = DeleteStudent(deleteSuccess, deleteError);

  const { isLoading, error, data, refetch, isSuccess } =
    GetStudentInfo(payload);

  if (isSuccess) {
    rowData = data?.students;
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        style: { width: "50px" },
      },
      {
        Header: "Email",
        accessor: "login",
      },
      {
        Header: "LMS Email",
        accessor: "lms_email",
        Cell: ({ value }: { value: string }) => {
          return value ? value : "N/A";
        },
      },
      {
        Header: "First",
        accessor: "first",
      },
      {
        Header: "Last",
        accessor: "last",
      },
    ],
    []
  );

  const modifyStudent = (action: string) => {
    switch (action) {
      case "delete-account":
        dispatch({ type: "delete-account" });
        break;
      case "change-info":
        dispatch({ type: "change-info" });
        break;
    }
  };

  const selectAccount = async (student: any) => {
    dispatch({ type: "reset" });
    setViewingStudent({
      first: student.first,
      last: student.last,
      login: student.login,
      _id: student._id,
      ...(student.lms_email ? { lms_email: student.lms_email } : {}),
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (Object.values(payload).join("").length === 0) {
      return;
    }
    await refetch();
    setViewingStudent(defaultStudent);
    if (error) {
      console.error(error);
    }
  };

  if (error) {
    return <div>{`An error has occurred: ${error}`}</div>;
  }

  const { mutate } = useMutation(
    async ({
      studentID,
      requestBody,
    }: {
      studentID: string;
      requestBody: Record<string, string>;
    }) => {
      return await axios.put(
        `${deltamathAPI()}/manager_new/manage/student/${studentID}`,
        requestBody
      );
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(["get-student-info"]);
        toastContext.addToast({
          status: data.success ? "Success" : "Error",
          message: data.message || "Student info changed successfully",
        });
        refetch();
      },
      onError: (error) => {
        toastContext.addToast({
          status: "Error",
          message: `A submission error has occurred: ${error}`,
        });
      },
    }
  );

  const handleInfoChange = (e: any) => {
    e.preventDefault();
    const first = e.target.first.value;
    const last = e.target.last.value;
    const login = e.target.email.value;
    const lms_email = e.target.lms_email?.value;
    const studentID = viewingStudent._id;
    const requestBody: Partial<StudentInfoInterface> = {};
    if (first.trim().length > 0 && first !== viewingStudent.first) {
      requestBody.first = first;
    }
    if (last.trim().length > 0 && last !== viewingStudent.last) {
      requestBody.last = last;
    }
    if (login.trim().length > 0 && login !== viewingStudent.login) {
      requestBody.login = login;
    }
    if (
      lms_email?.trim().length > 0 &&
      lms_email !== viewingStudent.lms_email
    ) {
      requestBody.lms_email = lms_email;
    }
    mutate({ studentID, requestBody });
    setPayload((prev) => {
      return {
        ...prev,
        ...(prev.login && requestBody.login
          ? { login: requestBody.login }
          : {}),
      };
    });
  };

  return (
    <>
      <div className="px-2">
        <h1 className="mx-4 py-4 text-2xl font-semibold text-gray-900">
          Student Search/Delete
        </h1>

        {viewingStudent && viewingStudent._id && (
          <div className="mx-4 inline-flex font-semibold text-gray-900">
            <div>
              <label className="block" htmlFor="options-menu">
                Viewing Student:
              </label>
              <Menu
                id="options-menu"
                as="div"
                className="relative z-50 mb-2 inline-block text-left"
              >
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    {viewingStudent.first} {viewingStudent.last} (
                    {viewingStudent._id})
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.deleteAccount
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyStudent("delete-account");
                            }}
                          >
                            Delete Student
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.changeInfo
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyStudent("change-info");
                            }}
                          >
                            Change Student Info
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            {menuState.deleteAccount && (
              <div className="mt-4">
                <button
                  type="submit"
                  id="delete-student"
                  name="confirm-delete"
                  className="mb-2 ml-2 mt-0 block justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                  value="Submit"
                  color="secondary"
                  onClick={() => {
                    deleteStudent.mutate({
                      studentId: viewingStudent._id,
                      student: {
                        _id: parseInt(viewingStudent._id, 10),
                        first: viewingStudent.first,
                        last: viewingStudent.last,
                        login: viewingStudent.login,
                      },
                    });
                    dispatch({ type: "reset" });
                  }}
                >
                  Confirm Delete
                </button>
              </div>
            )}
            {menuState.changeInfo && (
              <div className="mx-4">
                <form onSubmit={handleInfoChange}>
                  <div className="flex flex-row space-x-2">
                    <div>
                      <label htmlFor="first" className="block">
                        First Name:
                      </label>
                      <input
                        className="my-2 rounded-md px-1"
                        name="first"
                        defaultValue={viewingStudent?.first}
                      ></input>
                    </div>
                    <div>
                      <label htmlFor="last" className="block">
                        Last:
                      </label>
                      <input
                        name="last"
                        className="my-2 rounded-md px-1"
                        defaultValue={viewingStudent?.last}
                      ></input>
                    </div>
                    <div>
                      <label htmlFor="email" className="block">
                        Login:
                      </label>
                      <input
                        className="my-2 min-w-[300px] rounded-md px-1"
                        name="email"
                        defaultValue={viewingStudent?.login}
                        disabled={
                          typeof viewingStudent?.lms_email !== "undefined" &&
                          viewingStudent?.lms_email.trim() !== ""
                        }
                      ></input>
                    </div>
                    {viewingStudent?.lms_email && (
                      <div>
                        <label htmlFor="lms_email" className="block">
                          LMS Email:
                        </label>
                        <input
                          name="lms_email"
                          className="my-2 min-w-[300px] rounded-md px-1"
                          defaultValue={viewingStudent?.lms_email}
                        ></input>
                      </div>
                    )}

                    <button
                      type="submit"
                      id="change-student-name"
                      name="confirm-change"
                      className="mb-2 mt-6 justify-center rounded-md border border-transparent bg-indigo-600 px-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      value="Submit"
                      color="secondary"
                    >
                      Confirm Change
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        )}
        {rowData && (
          <DeltaMathSearchableTable
            columns={columns}
            data={rowData}
            currentSearch={payload}
            updateSearch={setPayload}
            handleSubmit={handleSubmit}
            refetch={refetch}
            isLoading={isLoading}
            restoreDefaults={restoreDefaults}
            selectAccount={selectAccount}
            type={"student"}
            notSearchable={["First", "Last", "LMS Email"]}
          />
        )}
      </div>
      <div className="m-5">
        {!data?.success && <span>There are no results to display</span>}
      </div>
    </>
  );
};

export default SearchStudent;
