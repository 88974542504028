import { Fragment, useMemo, useState, useEffect } from "react";
import { useDMQuery } from "../../utils";
import DeltaMathTable from "../../shared/DeltaMathTable";
import JsonDisplay from "../../shared/JSONDisplay";

const AssignmentDiffSearch = () => {
  const [teacherID, setTeacherID] = useState<string>("");
  const [selectedAssignment, setSelectedAssignment] = useState<any>("");
  const [rowData, setRowData] = useState<any>([]);

  const selectAssignment = (assignment: any) => {
    setSelectedAssignment(assignment);
  };

  const { data, refetch, isLoading } = useDMQuery({
    path: `/manager_new/manage/assignment-diffs/${teacherID}`,
    queryOptions: {
      enabled: false,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Updated At",
        accessor: "last_edit.updated",
        Cell: ({ value }: { value: number }) => {
          return (
            <span key={`updated-${value}`}>
              {new Date(value * 1000).toLocaleString()}
            </span>
          );
        },
        align: "left",
      },
      {
        Header: "Original",
        accessor: "last_edit.original",
        Cell: ({ value }: { value: number }) => {
          return (
            <span key={`updated-${value}`}>
              {new Date(value * 1000).toLocaleString()}
            </span>
          );
        },
        align: "left",
      },
      {
        Header: "Assignment Diff",
        accessor: "diff",
        Cell: ({ row }: { row: any }) => {
          return (
            <div key={`diff-${row.id}`}>
              <JsonDisplay data={row.original} />
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (data?.success && data?.results?.length > 0) {
      setRowData(data.results);
    }
  }, [data]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    refetch();
  };

  return (
    <Fragment>
      <div className="px-2 md:px-0">
        <h1 className="py-4 text-2xl font-semibold text-gray-900 md:mx-2">
          Assignment Diff Search
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="grid grid-cols-1 md:grid-cols-8">
              <input
                type="text"
                id="assign-diff-search"
                name="assign-diff-search"
                placeholder="Teacher Assignment ID"
                value={teacherID}
                onChange={(e) => setTeacherID(e.target.value)}
                className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-l-md"
              />

              <button
                type="submit"
                className="relative -ml-px items-center space-x-2 border border-gray-300 bg-gray-50 px-4 py-2 text-center text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 md:rounded-r-md"
              >
                <span>{"Search"}</span>
              </button>
            </div>
          </div>
        </form>

        {rowData && rowData.length > 0 && (
          <DeltaMathTable data={rowData} columns={columns} />
        )}
        {(!rowData || rowData?.length === 0) && (
          <div className="text-gray-600">No data found</div>
        )}
      </div>
    </Fragment>
  );
};

export default AssignmentDiffSearch;
