import { useEffect, useState } from "react";
import Button from "../../../student/components/generic/button";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";

type Props = {
  name: string;
  friendlyUrl: string;
  description?: string;
  type: "course" | "unit" | "subunit";
  id: string;
  callback: () => void;
  versionState: "draft" | "past" | "published" | "deleted";
};

const EditName = (props: Props) => {
  const toastContext = useDeltaToastContext();

  const [edit, setEdit] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name);
  const [friendlyUrl, setFriendlyUrl] = useState<string>(props.friendlyUrl);
  const [description, setDescription] = useState<string>(
    props.description ?? ""
  );

  const saveEdit = useMutation(
    (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/${props.type}/${props.id}`,
        body,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
    },
    {
      onSuccess() {
        if (props.callback) {
          props.callback();
        }
      },
      onError(e) {
        console.error(e);
        toastContext.addToast({
          status: "Error",
          message: "Problem creating corse",
        });
      },
    }
  );

  useEffect(() => {
    if (props.name) {
      setName(props.name);
    }
    if (props.friendlyUrl) {
      setFriendlyUrl(props.friendlyUrl);
    }
    if (props.description) {
      setDescription(props.description);
    }
  }, [props.name, props.friendlyUrl, props.description]);

  const save = () => {
    const body = {
      name: name,
      friendlyUrl: friendlyUrl,
      description: description,
    };

    saveEdit.mutate(JSON.stringify(body));
    setEdit(false);
  };

  return (
    <>
      {edit ? (
        <>
          <div className="mb-2 flex gap-2">
            <h4
              className={`${
                props.type !== "subunit" ? "text-2xl" : ""
              } font-bold`}
            >
              Name:{" "}
            </h4>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="h-full"
            />
          </div>
          <div className="mb-2 flex gap-2">
            <p
              className={`${
                props.type !== "subunit" ? "text-xl" : ""
              } font-bold`}
            >
              Friendly Url:{" "}
            </p>
            {props.versionState === "draft" ? (
              <input
                value={friendlyUrl}
                onChange={(e) => setFriendlyUrl(e.target.value)}
              />
            ) : (
              <p>{friendlyUrl}</p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            {props.type !== "subunit" && (
              <div>
                <h4 className={`text-2xl font-bold`}>Description: </h4>
                <textarea
                  name="message"
                  rows={4}
                  cols={60}
                  onChange={(e) => setDescription(e.target.value)}
                >
                  {description}
                </textarea>
              </div>
            )}
            <div>
              <Button onClick={save}>Save</Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h4
            className={`${props.type !== "subunit" ? "text-xl" : ""} font-bold`}
          >
            {props.name}
          </h4>
          <p
            className={`${props.type !== "subunit" ? "text-xl" : ""} font-bold`}
          >
            Friendly Url: {props.friendlyUrl}
          </p>
          {props.type !== "subunit" && <div>{description}</div>}
          <Button onClick={() => setEdit(true)} className="mt-5">
            Edit
          </Button>
        </>
      )}
    </>
  );
};

export default EditName;
