import React, { useEffect, useState, Fragment } from "react";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { deltamathAPI } from "../../utils";
import axios from "axios";
import { LicenseTree } from "../../utils/quoteUtils";

interface SecondaryLicenseLookupProps {
  email: string;
  initialTree: LicenseTree | undefined;
  quoteNCES: string;
  name: string;
  setLicenseBasedWarnings: React.Dispatch<React.SetStateAction<boolean>>;
  quotedLicenseType?: string;
}

const SecondaryLicenseLookup: React.FC<SecondaryLicenseLookupProps> = ({
  email,
  initialTree,
  quoteNCES,
  setLicenseBasedWarnings,
  quotedLicenseType,
}) => {
  const [licenseFromEmail, setLicenseFromEmail] = useState<
    LicenseTree | undefined
  >(undefined);

  const [matchOnNameAndZip, setMatchOnNameAndZip] =
    useState<LicenseTree["Schools"][0]>();
  const [matchOnEmail, setMatchOnEmail] = useState<LicenseTree["Schools"][0]>();

  const [triggerLookup, setTriggerLookup] = useState<boolean>(false);

  const [checkedWithEngineering, setCheckedWithEngineering] =
    useState<boolean>(false);

  const toastContext = useDeltaToastContext();

  type ParamsType =
    | {
        name: string;
        zip: string;
      }
    | { email: string };

  const secondaryLookup = async (params: ParamsType) => {
    const url = new URL(`${deltamathAPI()}/manager_new/license/lookup`);
    for (const key in params) {
      url.searchParams.set(key, params[key as keyof typeof params]);
    }
    try {
      const response = await axios.get(url.toString());
      return response.data;
    } catch (error) {
      console.error(error);
      toastContext.addToast({
        status: "Error",
        message: "There was an error looking up alternate license info",
      });
    }
  };

  const getLicenseFromEmail = async () => {
    const data = await secondaryLookup({ email });
    if (data) {
      setLicenseFromEmail(data);
    } else {
      setLicenseFromEmail(undefined);
    }
  };

  useEffect(() => {
    if (triggerLookup) {
      if (email && email !== "") {
        getLicenseFromEmail();
      }
    }
  }, [triggerLookup, email]);

  useEffect(() => {
    if (licenseFromEmail) {
      const schoolMatch = licenseFromEmail.Schools?.filter(
        (sch: LicenseTree["Schools"][0]) => sch?.match?.email === email
      ).find((sch: LicenseTree["Schools"][0]) => !!sch.dmLicense?.expiration);

      const thisSchoolIsInAdminsDistrict =
        licenseFromEmail.District &&
        licenseFromEmail.District.districtID !== "" && //we can infer from the presence of a district w/ a non-empty NCES that a real district was found and made part of the license tree from the backend.
        (licenseFromEmail.Schools.some(
          (sch: LicenseTree["Schools"][0]) => sch.schoolid === quoteNCES
        ) || //when there is a district, the Schools array will include only schools in that district
          initialTree?.District?.districtID ===
            licenseFromEmail.District?.districtID);

      if (
        schoolMatch?.schoolid !== quoteNCES &&
        !thisSchoolIsInAdminsDistrict
      ) {
        setMatchOnEmail(schoolMatch);
      } else {
        setMatchOnEmail(undefined);
      }
    } else {
      setMatchOnEmail(undefined);
    }
  }, [licenseFromEmail, quoteNCES]);

  useEffect(() => {
    const matchByName = initialTree?.Schools?.find(
      (sch: LicenseTree["Schools"][0]) => sch.match
    );
    //only run logic if we are currently quoting for a school license
    if (quotedLicenseType === "school") {
      if (
        matchByName?.dmLicense?.expiration &&
        matchByName.schoolid !== quoteNCES
      ) {
        setMatchOnNameAndZip(matchByName);
      } else {
        setMatchOnNameAndZip(undefined);
      }

      //if there was no license tree, or the license tree on the name/zip came back w/ a school match, always lookup for an email match since we want to make sure we capture a license associated w/ the email on the quote if there's a possible snn to nces activation
      if (
        !initialTree ||
        matchByName?.schoolid !== quoteNCES ||
        !matchByName?.dmLicense?.expiration ||
        matchByName?.dmLicense?.expiration * 1000 < Date.now()
      ) {
        setTriggerLookup(true);
      } else {
        setTriggerLookup(false);
        setMatchOnEmail(undefined);
      }
    } else {
      setMatchOnNameAndZip(undefined);
      setMatchOnEmail(undefined);
    }
  }, [initialTree, quoteNCES]);

  useEffect(() => {
    if ((matchOnNameAndZip || matchOnEmail) && !checkedWithEngineering) {
      setLicenseBasedWarnings(true);
    } else {
      setLicenseBasedWarnings(false);
    }
  }, [
    matchOnNameAndZip,
    matchOnEmail,
    setLicenseBasedWarnings,
    checkedWithEngineering,
  ]);

  if (!matchOnNameAndZip && !matchOnEmail) {
    return <></>;
  }

  return (
    <div className="my-4 flex flex-col space-y-2 text-sm text-red-700">
      {matchOnNameAndZip && (
        <p>{`A school with the same name/zip but a different NCES (${matchOnNameAndZip.schoolid}) has had a previous license activation.`}</p>
      )}
      {matchOnEmail && (
        <p>{`The admin on this school quote is matched to a school with a prior activation but different NCES (${matchOnEmail.schoolid})`}</p>
      )}
      {(matchOnEmail || matchOnNameAndZip) && (
        <div className="">
          <p>
            It is possible there was a prior SNN activation and this site now
            has a proper NCES
          </p>
          <div className="mt-2">
            An engineering employee is ready to merge schools after activation
            <input
              type="checkbox"
              className="mx-2 inline"
              checked={checkedWithEngineering}
              onChange={(e) => setCheckedWithEngineering(e.target.checked)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SecondaryLicenseLookup;
