import { FormattedLearnerSubscriptionPlan } from "../../types";

export const Pricing: React.FC<{
  plans: FormattedLearnerSubscriptionPlan[] | undefined;
  ctaText: string;
  onCtaClick: () => void;
}> = ({ plans, ctaText, onCtaClick }) => {
  if (!plans) {
    return <p>Loading!</p>;
  }

  return (
    <div className="w-full px-4">
      <p>TODO: pricing!</p>
      <pre className="w-full overflow-auto">
        {JSON.stringify(plans, null, 2)}
      </pre>
    </div>
  );
};
