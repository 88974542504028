import axios from "axios";
import md5 from "md5";
import { useState } from "react";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import Button from "../../../student/components/generic/button";
import { evaluatePassword, PasswordErrorMessages } from "../../../utils";
import PasswordBox from "../BetaSignUp/PasswordBox";

type FormData = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

export const ChangePassword: React.FC<{ hideForm: () => void }> = ({
  hideForm,
}) => {
  const toastContext = useDeltaToastContext();
  const [formData, setFormData] = useState<FormData>({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState<{
    [K in keyof FormData]?: string;
  }>({});
  const [error, setError] = useState<string | undefined>(undefined);

  const passwordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "primary" | "secondary" | "current"
  ) => {
    if (error) setError(undefined);
    if (type === "primary") {
      // check primary password for errors
      if (e.target.value && !evaluatePassword(e.target.value)) {
        formErrors["password"] = "true";
      } else if (formErrors["password"]) {
        delete formErrors["password"];
      }

      // check if confirm password exists and matches
      if (
        formData.confirmPassword !== "" &&
        formData.confirmPassword !== e.target.value
      ) {
        formErrors["confirmPassword"] = "Passwords do not match.";
      } else if (formData.confirmPassword === e.target.value) {
        delete formErrors["confirmPassword"];
      }

      setFormData({
        ...formData,
        password: e.target.value,
      });
    } else if (type === "secondary") {
      // check if primary password exists and matches
      if (formData.password !== "" && formData.password !== e.target.value) {
        formErrors["confirmPassword"] = "Passwords do not match.";
      } else if (formData.password === e.target.value) {
        delete formErrors["confirmPassword"];
      }

      setFormData({
        ...formData,
        confirmPassword: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        currentPassword: e.target.value,
      });
    }
  };

  const updatePassword = useMutation(
    (body: { password: string; currentPassword: string }) => {
      return axios.put<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/shared/update_password`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        toastContext.addToast({
          message: "You have successfully changed your password.",
          status: "Success",
        });
        hideForm();
      },
      onError: (e) => {
        setError(
          "An error occurred while changing your password. Please try again."
        );
      },
    }
  );

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      password: md5("deltamath" + formData.password),
      currentPassword: md5("deltamath" + formData.currentPassword),
    };

    updatePassword.mutate(body);
  };

  const signUpEnabled =
    formData.currentPassword &&
    formData.password &&
    formData.confirmPassword &&
    !Object.keys(formErrors).length;

  return (
    <form
      onSubmit={submit}
      className="flex flex-col gap-4 rounded bg-dm-background-blue-100 p-4 sm:w-96"
    >
      <PasswordBox
        label="Type Current Password"
        value={formData.currentPassword}
        error={undefined}
        onChange={(e) => passwordChange(e, "current")}
      />
      <div>
        <PasswordBox
          label="New Password"
          value={formData.password}
          error={undefined}
          onChange={(e) => passwordChange(e, "primary")}
        />
        {formErrors.password && (
          <PasswordErrorMessages passwordValue={formData.password} />
        )}
      </div>
      <PasswordBox
        label="Confirm New Password"
        value={formData.confirmPassword}
        error={formErrors.confirmPassword}
        onChange={(e) => passwordChange(e, "secondary")}
      />
      <Button
        className="w-full"
        disabled={!signUpEnabled || updatePassword.isLoading}
        submit
      >
        Update Password
      </Button>
      <Button className="w-full !py-0" type="link" onClick={hideForm}>
        Cancel
      </Button>
      {error && <div className="text-dm-error-500">{error}</div>}
    </form>
  );
};
