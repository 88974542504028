import { ReactNode, useState, Fragment, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useMediaQuery, useOnClickOutside, useScrollLock } from "usehooks-ts";
import clsx from "clsx";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import CalculatorButton from "./CalculatorButton";
import ProfileButton from "./ProfileButton";
import { SidebarPoints } from "./SidebarPoints";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { getFilePath } from "../../../utils";

type Props = {
  showCalculator?: boolean;
  upperSection?: ReactNode;
  midSection?: ReactNode;
  children?: ReactNode;
};

export default function SidebarWrapper(props: Props): JSX.Element {
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");

  const mobileSidebarRef = useRef(null);
  const closeButtonHover = useRef(false);
  useOnClickOutside(mobileSidebarRef, (e) => {
    if (!closeButtonHover.current) setIsMenuOpen(false);
  });

  const learnerContext = useLearnerContext();
  const { isSidebarMinimized, learner } = learnerContext;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { isLocked, lock, unlock } = useScrollLock({
    autoLock: false,
  });

  useEffect(() => {
    if (isSmallDevice) {
      isMenuOpen ? lock() : unlock();
    } else if (isLocked) {
      unlock();
    }

    return () => unlock();
  }, [isLocked, isMenuOpen, isSmallDevice, lock, unlock]);

  useEffect(() => {
    setIsMenuOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  if (isSmallDevice)
    return (
      <>
        <div className="sticky inset-x-0 top-0 z-[9] flex items-center justify-between gap-x-5 border-b border-dm-charcoal-100 bg-white px-4 py-3">
          <Link
            to={`${REACT_APP_LEARNER_LINK}/dashboard`}
            className="h-9 bg-white focus:outline-none"
          >
            <ResponsiveImage
              className="z-2 transition-width h-9 duration-300 ease-out"
              srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
              alt="DeltaMath Home"
            />
          </Link>
          {learner?.pointsEarned !== null && (
            <Link
              className="points flex-grow text-right text-sm font-bold"
              to={`${REACT_APP_LEARNER_LINK}/points`}
            >
              <i className="far fa-stars fa-fw text-base text-dm-purple-800"></i>{" "}
              {learner?.pointsEarned} pts
            </Link>
          )}
          <button
            className="!text-dm-gray-200"
            onPointerEnter={() => (closeButtonHover.current = true)}
            onPointerLeave={() => (closeButtonHover.current = false)}
            onClick={(e) => setIsMenuOpen((prev: boolean) => !prev)}
            aria-label={
              !isMenuOpen ? "open sidebar menu" : "close sidebar menu"
            }
          >
            {!isMenuOpen ? (
              <i className="fas fa-fw fa-bars text-lg" aria-hidden="true"></i>
            ) : (
              <i
                className="fas fa-fw fa-times text-lg !text-dm-gray-200"
                aria-hidden="true"
              ></i>
            )}
          </button>
        </div>
        <Transition.Root
          show={isMenuOpen}
          as="div"
          className={clsx(
            "fixed z-[8] flex flex-col overflow-clip bg-white text-dm-charcoal-800 lg:hidden",
            "inset-y-0 left-0 border border-r border-dm-charcoal-100"
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>
          <div
            className="fixed bottom-0 left-0 top-14 flex w-full bg-white p-2 md:max-w-80"
            ref={mobileSidebarRef}
          >
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-150 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <nav
                className="relative flex w-full flex-1 flex-col pt-5"
                aria-label="Sidebar"
              >
                <div className="shrink-0">{props.upperSection}</div>
                {props.midSection}
                {props.children && (
                  <div className="-mx-2 flex flex-grow flex-col justify-start overflow-auto px-2">
                    {props.children}
                  </div>
                )}

                <div className="flex shrink-0 flex-col border-t border-dm-charcoal-100 bg-white pt-4">
                  {!!props.showCalculator && <CalculatorButton />}
                  <ProfileButton link={`${REACT_APP_LEARNER_LINK}/profile`} />
                </div>
              </nav>
            </Transition.Child>
          </div>
        </Transition.Root>
      </>
    );

  return (
    <div
      className={clsx(
        "transition-padding sticky inset-y-0 z-10 h-screen border-r border-dm-charcoal-100 bg-white duration-300 ease-out",
        isSidebarMinimized ? "px-4" : "px-6"
      )}
    >
      <button
        className={clsx(
          "absolute top-8 z-50 rounded-md bg-dm-charcoal-100 px-1 text-dm-gray-200",
          isSidebarMinimized ? "-right-2" : "right-2"
        )}
        onClick={() =>
          learnerContext.setIsSidebarMinimized(!isSidebarMinimized)
        }
        aria-hidden="true"
      >
        {isSidebarMinimized ? (
          <i className="far fa-chevron-double-right"></i>
        ) : (
          <i className="far fa-chevron-double-left"></i>
        )}
      </button>
      <div
        className={clsx(
          "transition-width hidden flex-col overflow-hidden pb-4 pt-8 text-dm-charcoal-800 duration-300 ease-out lg:flex",
          "h-screen",
          isSidebarMinimized ? "w-12" : "w-80"
        )}
      >
        <nav
          className={clsx("flex h-0 w-80 flex-1 flex-col overflow-y-hidden")}
          aria-label="Sidebar"
        >
          <div className="shrink-0">
            <div className="relative mb-9 flex gap-x-1">
              <Link
                to={`${REACT_APP_LEARNER_LINK}/dashboard`}
                className={clsx("block bg-white focus:outline-none")}
              >
                <ResponsiveImage
                  className="z-2 transition-width duration-300 ease-out"
                  srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
                  alt="DeltaMath Home"
                />
              </Link>
            </div>
            {props.upperSection}
          </div>
          {props.midSection}
          {props.children && (
            <div className="flex flex-grow flex-col justify-start overflow-auto">
              {props.children}
            </div>
          )}

          <div
            className={clsx(
              "flex shrink-0 flex-col border-t border-dm-charcoal-100 bg-white pt-4",
              isSidebarMinimized ? "gap-5" : "gap-3"
            )}
          >
            {!!props.showCalculator && <CalculatorButton />}
            <SidebarPoints />
            <ProfileButton link={`${REACT_APP_LEARNER_LINK}/profile`} />
          </div>
        </nav>
      </div>
    </div>
  );
}
