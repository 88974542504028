import { LearnerStatsKind } from "../types";
import { createAnalyticsEvent } from "./createAnalyticsEvent";
import { ALL_PLUGINS } from "./plugins";
import { AnalyticsAssignmentData, AnalyticsSkillData } from "./types";

/* LEARNER EVENTS */

/** Learner did a full page load */
export const initialDataLoadEvent = () =>
  createAnalyticsEvent("initialDataLoad", {}, ALL_PLUGINS);

/** Learner clicked to expand a prize on the prizes page */
export const openedPrizeAccordionEvent = (data: { prize: string }) =>
  createAnalyticsEvent("openedPrizeAccordion", data, ALL_PLUGINS);

/** Learner solved a problem */
export const solveProblemEvent = (
  data: { correct: boolean } & AnalyticsAssignmentData & AnalyticsSkillData
) => createAnalyticsEvent("solveProblem", data, ALL_PLUGINS);

/** Learner unsubmitted a problem */
export const unsubmitProblemEvent = (
  data: AnalyticsAssignmentData & AnalyticsSkillData
) => createAnalyticsEvent("unsubmitProblem", data, ALL_PLUGINS);

/** Learner started a quiz or test */
export const startAssessmentEvent = (
  data: {
    attemptNumber: number;
  } & AnalyticsAssignmentData
) => createAnalyticsEvent("startAssessment", data, ALL_PLUGINS);

/** Learner submitted a quiz or test */
export const submitAssessmentEvent = (
  data: {
    score: number;
    attemptNumber: number;
  } & AnalyticsAssignmentData
) => createAnalyticsEvent("submitAssessment", data, ALL_PLUGINS);

/** Learner previewed a quiz or test */
export const previewAssessmentEvent = (data: AnalyticsAssignmentData) =>
  createAnalyticsEvent("previewAssessment", data, ALL_PLUGINS);

/** Learner used a test hint */
export const usedTestHintEvent = (
  data: {
    numCorrect: number;
    numIncorrect: number;
    numBlank: number;
  } & AnalyticsAssignmentData
) => createAnalyticsEvent("usedTestHint", data, ALL_PLUGINS);

/** Learner used a question swap */
export const usedQuestionSwapEvent = (
  data: AnalyticsAssignmentData & AnalyticsSkillData
) => createAnalyticsEvent("usedQuestionSwap", data, ALL_PLUGINS);

/** Learner skipped a pre-quiz */
export const skipPreQuizEvent = (
  data: { numCompleted: number } & AnalyticsAssignmentData
) => createAnalyticsEvent("skipPreQuiz", data, ALL_PLUGINS);

/** Learner watched a video */
export const watchVideoEvent = (
  data: {
    watchPercentStart: number;
    watchPercentEnd: number;
    secondsWatched: number;
    skillCode: string;
  } & AnalyticsAssignmentData
) => createAnalyticsEvent("watchVideo", data, ALL_PLUGINS);

/** Learner viewed examples on a practice problem */
export const viewedExamplesPracticeEvent = (
  data: AnalyticsAssignmentData & AnalyticsSkillData
) => createAnalyticsEvent("viewedExamplesPractice", data, ALL_PLUGINS);

/** Learner reviewed a problem in a completed quiz or test */
export const viewedAssessmentProblemLogEvent = (
  data: AnalyticsAssignmentData & AnalyticsSkillData
) => createAnalyticsEvent("viewedAssessmentProblemLog", data, ALL_PLUGINS);

/** Learner searched for something in the course search box */
export const searchEvent = (data: { searchTerm: string; numResults: number }) =>
  createAnalyticsEvent("search", data, ALL_PLUGINS);

/** Learner clicked a search result */
export const searchResultClickedEvent = (data: { searchTerm: string }) =>
  createAnalyticsEvent("searchResultClicked", data, ALL_PLUGINS);

/** Learner opened the weekly goal modal */
export const openedWeeklyGoalEvent = () =>
  createAnalyticsEvent("openedWeeklyGoal", {}, ALL_PLUGINS);

/** Learner clicked the "Continue Learner" button on the dashboard */
export const continueLearningButtonEvent = () =>
  createAnalyticsEvent("continueLearningButton", {}, ALL_PLUGINS);

/** Learner opened the "How points work" modal */
export const learnHowPointsWorkModalEvent = () =>
  createAnalyticsEvent("learnHowPointsWorkModal", {}, ALL_PLUGINS);

/** Learner entered points into Pascal's Triangle */
export const enterPointsTriangleEvent = (data: {
  pointsEarnedTotal: number;
  pointsRemaining: number;
  pointsRedeemed: number;
}) => createAnalyticsEvent("enterPointsTriangle", data, ALL_PLUGINS);

/** Learner unlocked a prize */
export const unlockPrizeEvent = (data: { prize: string }) =>
  createAnalyticsEvent("unlockPrize", data, ALL_PLUGINS);

/** Learner clicked the button in the prize accordion */
export const clickedPrizeCTAEvent = (data: { prize: string }) =>
  createAnalyticsEvent("clickedPrizeCTA", data, ALL_PLUGINS);

/** Learner changed their confetti */
export const newConfettiEvent = (data: { effect: string; shape: string }) =>
  createAnalyticsEvent("newConfetti", data, ALL_PLUGINS);

/** Learner changed their loading animation */
export const newAnimationEvent = (data: { animation: string }) =>
  createAnalyticsEvent("newAnimation", data, ALL_PLUGINS);

/** Learner lost a game. Applies to both snake and factor bubbles */
export const gameLostEvent = (data: {
  prize: string;
  snakeNumCorrect: number;
  snakeLoseReason: string;
}) => createAnalyticsEvent("gameLost", data, ALL_PLUGINS);

/** Learner won a game */
export const gameWonEvent = (data: { prize: string }) =>
  createAnalyticsEvent("gameWon", data, ALL_PLUGINS);

/** Learner closed the game modal */
export const gameModalClosedEvent = (data: { prize: string }) =>
  createAnalyticsEvent("gameModalClosed", data, ALL_PLUGINS);

/* PARENT EVENTS */

/** Learner did a full page load */
export const parentInitialDataLoadEvent = () =>
  createAnalyticsEvent("parentInitialDataLoad", {}, ALL_PLUGINS);

/** Parent opened the "How points work" modal */
export const parentLearnHowPointsWorkModalEvent = () =>
  createAnalyticsEvent("parentLearnHowPointsWorkModal", {}, ALL_PLUGINS);

/** Parent opened recent activity modal */
export const parentOpenRecentActivityModalEvent = () =>
  createAnalyticsEvent("parentOpenRecentActivityModal", {}, ALL_PLUGINS);

/** Parent opened an accordion on the recent activity modal */
export const parentRecentActivityDropdownsEvent = (data: {
  assignmentType: string;
}) => createAnalyticsEvent("parentRecentActivityDropdowns", data, ALL_PLUGINS);

/** Parent opened the course details modal */
export const parentViewCourseProgressDetailsEvent = () =>
  createAnalyticsEvent("parentViewCourseProgressDetails", {}, ALL_PLUGINS);

/** Parent changed the stats view */
export const parentChangeLearnerStatsEvent = (data: {
  statType: LearnerStatsKind;
  interval: "daily" | "weekly";
}) => createAnalyticsEvent("parentChangeLearnerStats", data, ALL_PLUGINS);

/** Parent impersonated a child */
export const parentImpersonationEvent = () =>
  createAnalyticsEvent("parentImpersonation", {}, ALL_PLUGINS);
