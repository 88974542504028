import clsx from "clsx";
import { getFilePath } from "../../../utils";
import Accordion from "../Accordion";
import { ResponsiveImage } from "../ResponsiveImage";
import { SectionTitle } from "./SectionTitle";
import { useState } from "react";
import { MAX_CONTAINER_WIDTH } from "./constants";

export const Faq: React.FC = () => {
  const [activeQs, setActiveQs] = useState<number[]>([]);
  const faqItems: string[][] = [
    [
      "Lorem ex ut adipisicing anim incididunt?",
      "Et minim nostrud excepteur velit esse est.",
    ],
    [
      "Irure laborum pariatur veniam velit ea cupidatat proident do eiusmod laboris ad?",
      "Commodo in anim voluptate ex labore deserunt consectetur Lorem elit non et. Veniam amet eu nisi tempor magna id sunt minim est aute proident mollit consequat aute. Sunt amet sit excepteur eu proident aute ea esse incididunt.",
    ],
    [
      "In dolore reprehenderit enim sunt magna sunt in nisi id?",
      "Nisi elit ex adipisicing velit nostrud ea.",
    ],
    [
      "Incididunt in ullamco laboris do cillum Lorem?",
      "Proident exercitation reprehenderit Lorem mollit Lorem commodo eu commodo dolor cillum ea do proident.",
    ],
    [
      "Ex proident pariatur laboris anim esse enim in incididunt?",
      "Quis ea laboris ullamco magna in aliquip. Eiusmod culpa enim adipisicing eiusmod. Qui laboris amet sunt commodo laboris sint cillum est consectetur incididunt veniam enim pariatur. Consectetur velit sit incididunt laborum duis. Minim consectetur ad deserunt eu cupidatat magna laboris.",
    ],
    [
      "Reprehenderit non ut officia sit?",
      "Eu non sint id commodo laboris cupidatat quis. Cillum aliquip eiusmod non laboris cupidatat laboris eu in cillum veniam exercitation veniam sint. Eiusmod officia elit in tempor laboris in id occaecat aliqua ea anim.",
    ],
  ];

  return (
    <section
      className={clsx(
        "relative w-full px-4 md:px-6 xl:px-0",
        MAX_CONTAINER_WIDTH
      )}
    >
      <SectionTitle
        title="Your Questions, Answered"
        subtitle=" Find answers to common questions about DeltaMath for Home"
      />

      <div className="overflow-hidden rounded-lg border-2 border-dm-brand-blue-500">
        {faqItems.map(([question, answer], index) => (
          <Accordion
            key={question}
            isOpen={activeQs.includes(index)}
            onToggle={() => {
              if (activeQs.includes(index)) {
                setActiveQs(activeQs.filter((i) => i !== index));
              } else {
                setActiveQs([...activeQs, index]);
              }
            }}
            buttonClasses={clsx(
              "px-8 py-5 rounded-none text-left",
              index > 0 && "border-t border-dm-brand-blue-500",
              activeQs.includes(index) && "bg-dm-brand-blue-100"
            )}
            titleButton={<h5 className="font-bold">{question}</h5>}
            panel={<p className="px-8 py-5">{answer}</p>}
          />
        ))}
      </div>

      <ResponsiveImage
        className="absolute -left-40 -top-28 hidden md:block"
        srcs={[
          getFilePath("/images/learner/home/notebook.png"),
          getFilePath("/images/learner/home/notebook@2x.png"),
        ]}
        alt="Notebook with 'A+' on the front"
      />
      <ResponsiveImage
        className="absolute -left-4 -top-10 hidden lg:block xl:-left-8"
        srcs={[
          getFilePath("/images/learner/home/target.png"),
          getFilePath("/images/learner/home/target@2x.png"),
        ]}
        alt="Target with arrows hitting the bullseye"
      />
    </section>
  );
};
