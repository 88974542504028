import { ContinueLearningCard } from "./ContinueLearningCard";
import { TotalPointsCard } from "./TotalPointsCard";
import { SectionsCompletedCard } from "./SectionsCompletedCard";
import { QuestionsAnsweredCorrectlyCard } from "./QuestionsAnsweredCorrectlyCard";
import { UnitsCompletedCard } from "./UnitsCompletedCard";
import { LearningStreakCard } from "./LearningStreakCard";
import { NextPrizeCard } from "./NextPrizeCard";
import { WeeklyGoalCard } from "./WeeklyGoalCard";
import { PascalsTriangleCard } from "./PascalsTriangleCard";
import { LearnerPage } from "../Layouts/LearnerPage";
import { useLearnerContext } from "../../contexts/LearnerContext";
import Button from "../../../student/components/generic/button";
import { useCourseContext } from "../../contexts/CourseContext";
import { Skeleton, SkeletonText } from "../Skeleton";
import { ResponsiveImage } from "../ResponsiveImage";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { LearnerOnboardingModal } from "./LearnerOnboardingModal";
import { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";

export const Dashboard: React.FC = () => {
  const { learner, state } = useLearnerContext();
  const learnerContext = useLearnerContext();

  const courseContext = useCourseContext();
  const isSmallDevice = useMediaQuery("(max-width : 767px)");

  const welcomeMessage = learner?.first ? `Hi ${learner.first}!` : "Welcome!";

  const isEmpty = !state.isLoadingProgress && state.progresses.length === 0;

  const ptsUnlocked = (learner?.pascalsRow?.length ?? 0) > 0;

  const courses = courseContext.getAllCoursesData();
  const course = learner?.assignedCourse
    ? courses.find((c) => c.id === learner.assignedCourse)
    : courses[0];

  // to make sure that we have an updated learner object we want to make sure to refetch the learner here
  // this will help with the updated correct answered questions and other properties used by the dashboard
  useEffect(() => {
    learnerContext.refetchLearner();
  }, []);

  return (
    <LearnerPage title="Dashboard">
      {isEmpty ? (
        <div className="flex h-full items-center justify-center self-stretch md:pb-28">
          <div className="flex w-[550px] max-w-full flex-col items-center gap-4">
            <ResponsiveImage
              className="w-[300px]"
              srcs={[
                getFilePath(
                  "/images/learner/dashboard/welcome-empty-state-dashboard.png"
                ),
                getFilePath(
                  "/images/learner/dashboard/welcome-empty-state-dashboard@2x.png"
                ),
              ]}
              alt=""
              aria-hidden="true"
            />
            <div className="flex flex-col items-center gap-1 text-center">
              <h1 className="font-serif text-lg font-bold">
                <SkeletonText loaded={!!learner}>
                  {welcomeMessage} Let&rsquo;s get started.
                </SkeletonText>
              </h1>
              <p>
                Build a strong foundation in {course?.name} and boost your math
                confidence. Ready to sharpen your skills?
              </p>
            </div>
            {course ? (
              <Button
                className="w-48"
                href={`${REACT_APP_LEARNER_LINK}/course/${course.path}`}
              >
                Start Course
              </Button>
            ) : (
              <div className="h-12 w-48">
                <Skeleton />
              </div>
            )}
          </div>
          <LearnerOnboardingModal />
        </div>
      ) : (
        <>
          <header className="relative flex w-full p-6">
            <div className="z-[7] flex-grow">
              <h1 className="font-serif text-2xl font-bold">
                {welcomeMessage}
              </h1>
              <p className="text-lg">Check out your progress so far.</p>
            </div>
            {!isSmallDevice && (
              <ResponsiveImage
                className="absolute -top-4 right-0 w-40 object-contain"
                srcs={[
                  getFilePath("/images/learner/dashboard/dashboard-deltie.png"),
                  getFilePath(
                    "/images/learner/dashboard/dashboard-deltie@2x.png"
                  ),
                ]}
                alt=""
                aria-hidden="true"
              />
            )}
          </header>

          <div className="z-[7] flex grid-cols-3 flex-col gap-4 md:grid">
            <div className="col-start-1 col-end-3 row-start-1 row-end-4">
              <ContinueLearningCard />
            </div>
            <div className="col-start-1 col-end-3 row-start-4 row-end-7">
              <PascalsTriangleCard />
            </div>

            <div className="col-start-3 row-start-1 row-end-3">
              <TotalPointsCard />
            </div>
            <div className="col-start-3 row-start-3 row-end-5">
              <WeeklyGoalCard />
            </div>
            <div className="col-start-3 row-start-5 row-end-7">
              <LearningStreakCard
                streaks={learner?.streaks}
                longestStreak={learner?.longest_streak}
              />
            </div>
            {ptsUnlocked ? <NextPrizeCard /> : <UnitsCompletedCard />}
            <SectionsCompletedCard />
            <QuestionsAnsweredCorrectlyCard />
          </div>
        </>
      )}
    </LearnerPage>
  );
};
