import { useReadLocalStorage } from "usehooks-ts";
import { useLearnerContext } from "../contexts/LearnerContext";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../manager/utils";
import { useEffect } from "react";

/**
 * Determine if user should see onboarding modal, sends to backend if
 * user is able to view
 */
export function useShowOnboarding() {
  const { learner } = useLearnerContext();
  const loginCount = useReadLocalStorage<{ login_count: number }>(
    "user"
  )?.login_count;
  const [params] = useSearchParams();

  // Only show on the first login and hasn't already viewed, or if
  // using the `?onboarding` query param
  const showOnboarding =
    (loginCount !== undefined &&
      loginCount <= 1 &&
      learner &&
      !learner.viewedOnboarding) ||
    params.has("onboarding");

  const { mutate } = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/learner/shared/onboarding`,
      undefined,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  });

  // Prevent showing onboarding again
  useEffect(() => {
    if (showOnboarding) {
      mutate();
    }
  }, [mutate, showOnboarding]);

  return showOnboarding;
}
