import React, { AnchorHTMLAttributes } from "react";
import { REACT_APP_HOMEPAGE_LINK } from "../../../utils";

const FooterLink: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  ...props
}) => (
  <a
    {...props}
    target="_blank"
    className="text-dm-charcoal-800 hover:text-dm-charcoal-500"
  >
    {children}
  </a>
);

export const PageFooter: React.FC = () => (
  <footer className="flex w-full justify-center px-6 text-xs text-dm-gray-200">
    <div className="flex w-full flex-wrap justify-around gap-x-6 gap-y-2 md:w-3/4">
      <nav className="md:shrink-0">
        <ul className="flex flex-wrap justify-center gap-x-6 gap-y-2">
          <li>
            <FooterLink href={`${REACT_APP_HOMEPAGE_LINK}/contact`}>
              Contact Us
            </FooterLink>
          </li>
          <li>
            <FooterLink href={`${REACT_APP_HOMEPAGE_LINK}/terms-of-service`}>
              Terms and Conditions
            </FooterLink>
          </li>
          <li>
            <FooterLink href={`${REACT_APP_HOMEPAGE_LINK}/privacy-policy`}>
              Privacy Policy
            </FooterLink>
          </li>
          <li>
            <FooterLink href="https://forms.clickup.com/8590370/f/86512-17877/P0GTWRV42LOX5WRM1Y">
              Beta User Support
            </FooterLink>
          </li>
        </ul>
      </nav>
      <span className="shrink-0">
        &copy; {new Date().getFullYear()} DeltaMath. All Rights Reserved.
      </span>
    </div>
  </footer>
);
