import { PropsWithChildren, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import { useUserContext } from "../shared/contexts/UserContext";
import {
  REACT_APP_LEARNER_LINK,
  isRequestFromReviewapp,
  isTokenExpired,
} from "../utils";
import { CourseContextProvider } from "./contexts/CourseContext";
import { LearnerContextProvider } from "./contexts/LearnerContext";
import { FocusContextProvider } from "./contexts/FocusContext";
import { LearnerEntitlement } from "./types";

export const RouteWithProtectedContext: React.FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const jwt = userContext.getJWT();
    const refreshTokenCookie = Cookies.get("refresh_token_javascript");
    // User has non-expired JWT or refresh token cookie
    if (
      (jwt && !isTokenExpired(jwt)) ||
      refreshTokenCookie ||
      isRequestFromReviewapp(window.location.origin)
    ) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const entitlements: LearnerEntitlement[] = Array.isArray(
        user.entitlements
      )
        ? user.entitlements
        : [];

      // Set them as logged in
      if (user?.email) {
        Sentry.setUser({ id: user._id });
        setIsLoggedIn(true);
        if (
          entitlements.includes("parent") &&
          !pathname.startsWith(`${REACT_APP_LEARNER_LINK}/parent`)
        ) {
          navigate(`${REACT_APP_LEARNER_LINK}/parent`);
        }
        return;
      }
    }

    // Force log out
    queryClient.invalidateQueries();
    userContext.clearJWT();
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    Sentry.setUser(null);
    window.location.href = `${REACT_APP_LEARNER_LINK}/sign-in?redirectUrl=${window.location.pathname.replace(
      REACT_APP_LEARNER_LINK,
      ""
    )}`;

    // Including userContext here causes it to redirect to the current url when the user logs out
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, queryClient]);

  return isLoggedIn ? (
    <CourseContextProvider>
      <LearnerContextProvider>
        <FocusContextProvider>
          <>{children}</>
        </FocusContextProvider>
      </LearnerContextProvider>
    </CourseContextProvider>
  ) : null;
};
