import { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Column, Row } from "react-table";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { US_STATES, formatNumber } from "../utils";
import { useDMQuery } from "../../utils";

export const Quote = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [stateSearch, setStateSearch] = useState(
    searchParams.get("state") || "NY"
  );
  const [schoolSearch, setSchoolSearch] = useState(
    searchParams.get("school") || ""
  );

  const { status, data, isFetching, refetch } = useDMQuery({
    cacheKey: ["search-school-or-district", schoolSearch, stateSearch],
    path: "/manager_new/manage/search-school-or-district",
    params: {
      state: stateSearch,
      search: schoolSearch,
    },
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (schoolSearch && stateSearch) {
      refetch();
    }
  }, []);

  return (
    <>
      <div className="px-2 md:px-0">
        <h1 className="py-4 text-2xl font-semibold text-gray-900 md:mx-2">
          School / District Search
        </h1>
        <form
          onSubmit={async (e) => {
            setSearchParams({
              state: stateSearch,
              school: schoolSearch,
            });
            refetch();
            e.preventDefault();
          }}
        >
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="grid grid-cols-1 md:grid-cols-8">
              <input
                type="text"
                id="school-search"
                name="search"
                placeholder="District or School Name"
                value={schoolSearch || ""}
                onChange={(e) => setSchoolSearch(e.target.value)}
                className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-l-md"
              />
              <select
                id="state"
                name="state"
                placeholder="State"
                required
                onChange={(e) => {
                  setStateSearch(e.target.value);
                  refetch();
                }}
                value={stateSearch}
                className="-ml-px block border-gray-300 text-sm"
              >
                <option disabled value="-">
                  Select State
                </option>
                {Object.entries(US_STATES).map(([key, name]) => (
                  <option key={key} value={key}>
                    {name}
                  </option>
                ))}
              </select>
              <button
                type="submit"
                className="relative -ml-px items-center space-x-2 border border-gray-300 bg-gray-50 px-4 py-2 text-center text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 md:rounded-r-md"
              >
                <span>{"Search"}</span>
              </button>
            </div>
          </div>
        </form>

        <DistrictDisplay
          data={data}
          isFetching={isFetching}
          status={status}
          state={stateSearch}
        />
      </div>
    </>
  );
};

const getParochialDOEStatus = ({
  isPrivate,
  privateOrientation,
  DOE,
}: {
  isPrivate: boolean;
  privateOrientation: string;
  DOE: boolean;
}) => {
  if (isPrivate && privateOrientation !== "Nonsectarian") {
    return "Parochial";
  } else {
    return DOE ? "DOE" : "";
  }
};

export const DistrictDisplay = ({
  data,
  isFetching,
  status,
  state,
}: {
  data: any;
  isFetching: any;
  status: any;
  state: string;
}) => {
  const columns: Array<Column> = useMemo(
    () => [
      {
        id: "view",
        Cell: ({ row }: { row: Row }) =>
          row ? (
            <span>
              <Link
                to={
                  row.values.districtID
                    ? `district/${row.values.districtID}`
                    : `school/${row.values.id}`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </Link>
            </span>
          ) : null,
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: (props: any) => (
          <span>
            {props.row.values.type === "district" ? "District" : "School"}
          </span>
        ),
      },
      ...(state === "NY"
        ? [
            {
              Header: "Parochial/DOE?",
              Cell: (props: any) => (
                <span>{getParochialDOEStatus(props.row.original)}</span>
              ),
            },
          ]
        : []),
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Level",
        accessor: "lowGrade",
        Cell: (props: any) => (
          <span className="pl-4">{`${props.row.values.lowGrade} - ${props.row.values.highGrade}`}</span>
        ),
      },
      {
        id: "highGrade",
        accessor: "highGrade",
        Cell: () => <span></span>,
      },
      {
        Header: "Size",
        accessor: "students",
        Cell: (props: any) => (
          <span>{formatNumber(props.row.values.students)}</span>
        ),
      },
      {
        Header: "Address",
        accessor: "address",
        Cell: ({ cell: { value } }: { cell: { value: any } }) => (
          <>
            <div>{value.street}</div>
            <div>
              {value.city}
              {", "}
              {value.state} {value.zip}
            </div>
          </>
        ),
      },
      {
        Header: "NCES ID",
        accessor: "id",
      },
      {
        Header: "Link",
        accessor: "url",
        Cell: ({ cell: { value } }: { cell: { value: any } }) => (
          <a
            className="text-teal-300"
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            SchoolDigger
          </a>
        ),
      },
      {
        id: "districtID",
        accessor: "districtID",
      },
    ],
    [state]
  );
  return (
    <>
      <div>
        {status === "loading"
          ? "Loading..."
          : status === "error"
          ? "Error..."
          : data &&
            Array.isArray(data) &&
            data.length > 0 && (
              <>
                <div>
                  <DeltaMathTable columns={columns} data={data} />
                </div>
                <div>{isFetching ? "Background Updating..." : " "}</div>
              </>
            )}
      </div>
    </>
  );
};

export default Quote;
