import clsx from "clsx";
import { ReactElement } from "react";
import { PlaceholderImage } from "../PlaceholderImage";
import Button from "../../../student/components/generic/button";
import { SectionTitle } from "./SectionTitle";
import { MAX_CONTAINER_WIDTH } from "./constants";

interface Feature {
  title: string;
  description: string;
}

const FeatureListSection: React.FC<{
  title: string;
  features: Feature[];
  image: ReactElement;
  bgColorClass: string;
  imageAlign: "right" | "left";
  ctaText: string;
  onCtaClick: () => void;
}> = ({
  title,
  features,
  image,
  bgColorClass,
  imageAlign,
  ctaText,
  onCtaClick,
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col gap-8 md:flex-row md:gap-6",
        imageAlign === "right" && "md:flex-row-reverse"
      )}
    >
      <div
        className={clsx(
          "flex w-full flex-none items-center justify-center self-stretch rounded-xl px-8 py-14 md:w-[300px] md:px-10 md:py-0 lg:w-[500px] xl:w-[607px]",
          bgColorClass
        )}
      >
        {image}
      </div>
      <div className="flex flex-col gap-6 md:gap-8">
        <h5 className="font-serif text-lg font-bold text-dm-brand-blue-600 md:text-2xl">
          {title}
        </h5>
        <ul className="flex flex-col gap-6">
          {features.map((feature) => (
            <li key={feature.title}>
              <h5 className="mb-2 font-serif font-bold text-dm-brand-blue-600 md:text-lg">
                {feature.title}
              </h5>
              <p>{feature.description}</p>
            </li>
          ))}
        </ul>
        <Button
          className="mt-1 h-10 w-full text-sm md:mt-0"
          onClick={onCtaClick}
        >
          {ctaText}
        </Button>
      </div>
    </div>
  );
};

export const FeatureList: React.FC<{
  ctaText: string;
  onCtaClick: () => void;
}> = ({ ctaText, onCtaClick }) => {
  return (
    <section
      className={clsx(
        "-mt-14 w-full px-4 md:px-6 xl:px-0",
        MAX_CONTAINER_WIDTH
      )}
    >
      <SectionTitle
        title="Build Skills, Gain Confidence"
        subtitle="An all-in-one platform for effective learning and measurable progress"
      />

      <div className="flex flex-col gap-20 md:gap-32">
        <FeatureListSection
          title="For Learners"
          features={[
            {
              title: "Personalized Learning Path",
              description:
                "DeltaMath for Home adapts to your individual skill level, ensuring you're constantly challenged and engaged with targeted practice problems.",
            },
            {
              title: "Stay Motivated & Celebrate Success",
              description:
                "Earn points, unlock rewards, and track your progress in real-time. DeltaMath makes learning fun and rewarding, keeping you motivated every step of the way",
            },
            {
              title: "Learn Anytime, Anywhere",
              description:
                "Access a vast library of math courses from any device, anytime. Learn at your own pace and revisit concepts whenever you need.",
            },
            {
              title: "Interactive & Engaging Content",
              description:
                "Experience math in a new way with interactive exercises, videos, and games that make learning fun and effective.",
            },
          ]}
          image={<PlaceholderImage width={500} height={400} />}
          bgColorClass="bg-dm-purple-500"
          imageAlign="left"
          ctaText={ctaText}
          onCtaClick={onCtaClick}
        />
        <FeatureListSection
          title="For Learners"
          features={[
            {
              title: "Personalized Learning Path",
              description:
                "DeltaMath for Home adapts to your individual skill level, ensuring you're constantly challenged and engaged with targeted practice problems.",
            },
            {
              title: "Stay Motivated & Celebrate Success",
              description:
                "Earn points, unlock rewards, and track your progress in real-time. DeltaMath makes learning fun and rewarding, keeping you motivated every step of the way",
            },
            {
              title: "Learn Anytime, Anywhere",
              description:
                "Access a vast library of math courses from any device, anytime. Learn at your own pace and revisit concepts whenever you need.",
            },
            {
              title: "Interactive & Engaging Content",
              description:
                "Experience math in a new way with interactive exercises, videos, and games that make learning fun and effective.",
            },
          ]}
          image={<PlaceholderImage width={500} height={400} />}
          bgColorClass="bg-[#F8EC5F]"
          imageAlign="right"
          ctaText={ctaText}
          onCtaClick={onCtaClick}
        />
      </div>
    </section>
  );
};
