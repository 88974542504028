import React, { useState } from "react";
import {
  preLoginResponseType,
  loginRequestType,
} from "../../../manager/components/Login";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { useNavigate } from "react-router-dom";
import md5 from "md5";
import { deltamathAPI } from "../../../manager/utils";
import PasswordBox from "../BetaSignUp/PasswordBox";
import Button from "../../../student/components/generic/button";

type Props = {
  setLoggedIn: (loggedIn: boolean) => void;
  updatePageState: (state: "forgot") => void;
};

const LoginForm = (props: Props) => {
  const queryClient = useQueryClient();
  const userContext = useUserContext();

  const [loginLoading, setLoginLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  function processPreloginFactor(
    res: preLoginResponseType,
    data: loginRequestType
  ): loginRequestType {
    const num = res.number;
    let factor = 0;
    for (let i = 2; i < num; i++) {
      if (num % i === 0) {
        factor = i;
        break;
      }
    }
    data.prelogin_factor = factor;
    data.prelogin_token = res.token;
    data.account_type = "learner";
    return data;
  }

  const processLoginResponse = (loginData: any) => {
    if (loginData?.user?.timeOffset !== undefined)
      loginData.user.timeOffset =
        loginData.user.timeOffset * 1000 - +Date.now();
    let bad_login = true;
    if (loginData.admin) {
      localStorage.setItem("admin", JSON.stringify(loginData.admin));
      bad_login = false;
    }
    if (loginData.user) {
      localStorage.setItem("user", JSON.stringify(loginData.user));
      bad_login = false;
    }
    if (loginData.customer_service_token) {
      bad_login = false;
      localStorage.setItem(
        "customer_service_token",
        loginData.customer_service_token
      );
    }
    if (loginData.jwt) {
      userContext.setJWT(loginData.jwt);
      navigate(window.location.search);
      props.setLoggedIn(true);
    }
    if (bad_login) {
      props.setLoggedIn(false);
      localStorage.clear();
      window.location.reload();
    }
  };

  const login = (preLoginData: preLoginResponseType) => {
    const passwordHash = md5("deltamath" + password);
    const data = {
      prelogin_factor: 0,
      prelogin_token: "",
      email: email,
      password: passwordHash,
    };
    const theData = processPreloginFactor(preLoginData, data);
    (async () => {
      try {
        const loginRequest = await fetch(deltamathAPI() + "/login", {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(theData),
        });
        if (loginRequest.ok) {
          const loginData = await loginRequest.json();
          processLoginResponse(loginData);
        }
        // we are doing this because we were getting errors logged in sentry
        else {
          setError(
            "Unable to login with that username and password. Please try again."
          );
          setLoginLoading(false);
        }
      } catch (e) {
        setError("An error occurred while logging in. Please try again.");
        throw e;
      }
    })();
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    setLoginLoading(true);
    setError(undefined);
    (async () => {
      try {
        const preLoginRequest = await fetch(deltamathAPI() + "/prelogin", {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        });
        if (preLoginRequest.ok) {
          const preLoginData = await preLoginRequest.json();
          login(preLoginData);
        } else {
          setLoginLoading(false);
          setError("An error occurred while logging in. Please try again.");
        }
      } catch (e) {
        setLoginLoading(false);
        setError("An error occurred while logging in. Please try again.");
        throw e;
      }
    })();
  };

  return (
    <>
      <div className="md:mt-16">
        <h2 className="mt-6 text-center font-[Lora] text-2xl font-bold text-dm-brand-blue-600">
          Log In
        </h2>
      </div>

      <div className="mt-2 flex-grow sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={submitForm}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-bold text-dm-charcoal-800"
              >
                Email or Username
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type={"text"}
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <PasswordBox
                label="Password"
                value={password}
                error={undefined}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mt-4 w-full text-center">
              <button
                type="button"
                className="cursor-pointer py-1 text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70"
                disabled={loginLoading}
                onClick={() => props.updatePageState("forgot")}
              >
                Forgot Password
              </button>
            </div>

            <Button submit disabled={loginLoading} className="w-full">
              Log In
            </Button>
            {loginLoading && <div>Logging you in...</div>}
            {error && <div className="text-dm-error-500">{error}</div>}
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
