import { Link } from "react-router-dom";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";
import ProfileButton from "../Sidebar/ProfileButton";

export const ParentNav: React.FC = () => (
  <nav className="sticky inset-x-0 top-0 z-20 flex items-center justify-between gap-x-5 border-b border-dm-charcoal-100 bg-white px-4 py-3">
    <Link
      to={`${REACT_APP_LEARNER_LINK}/parent`}
      className="h-9 bg-white focus:outline-none"
    >
      <ResponsiveImage
        className="z-2 transition-width h-9 duration-300 ease-out"
        srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
        alt="DeltaMath Home"
      />
    </Link>
    <ProfileButton link={`${REACT_APP_LEARNER_LINK}/parent/profile`} />
  </nav>
);
