import axios from "axios";
import { FormEventHandler, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDMQuery } from "../../../utils";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import Button from "../../../student/components/generic/button";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { FormattedPrice, LearnerSubscriptionDetails } from "../../types";
import { formatPrice } from "../../utils/formatPrice";

export const SubscriptionQuantityForm: React.FC<{
  currentQuantity: number | undefined;
  refetchSubscriptionData: () => void;
  subscription: LearnerSubscriptionDetails;
}> = ({ currentQuantity, refetchSubscriptionData, subscription }) => {
  const toastContext = useDeltaToastContext();
  const [quantity, setQuantity] = useState<number>(1);

  const { refetch: fetchChangeQuantityChangeProrationData } = useDMQuery<{
    proratedAmount: FormattedPrice;
    fullAmount: FormattedPrice;
  }>({
    path: "/payments/subscriptions/update/quantity/preview",
    params: { quantity: quantity },
    queryOptions: { enabled: false },
  });

  const { mutateAsync: changeQuantityMutation, isLoading: isChangingQuantity } =
    useMutation(() => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/update/quantity`,
        JSON.stringify({ quantity }),
        withJsonHeader()
      );
    });

  const changeQuantityPreview: FormEventHandler = async (e) => {
    e.preventDefault();

    const { data } = await fetchChangeQuantityChangeProrationData();
    if (!data?.proratedAmount) {
      return;
    }

    const formattedProratedAmount = formatPrice(data.proratedAmount);
    const formattedFullAmount = formatPrice(data.fullAmount);
    const formattedBillingDate = Intl.DateTimeFormat("en-us", {
      dateStyle: "medium",
    }).format(new Date(subscription.periodEnd));
    const quantityDiff = quantity - (currentQuantity ?? 0);

    const message =
      quantityDiff > 0
        ? [
            quantityDiff === 1
              ? `Adding 1 learner to your subscription.`
              : `Adding ${quantityDiff} learners to your subscription.`,
            `Prorated amount, due now: ${formattedProratedAmount}.`,
            `Starting on ${formattedBillingDate}, your subscription will be ${formattedFullAmount} per ${subscription.interval}.`,
            "Are you sure you want to continue?",
          ]
        : [
            quantityDiff === -1
              ? "Removing 1 learner from your subscription."
              : `Removing ${-quantityDiff} learners from your subscription.`,
            `Starting on ${formattedBillingDate}, your subscription will be ${formattedFullAmount} per ${subscription.interval}.`,
            "Are you sure you want to continue?",
          ];

    if (window.confirm(message.join(" "))) {
      try {
        await changeQuantityMutation();
        refetchSubscriptionData();
      } catch (e: any) {
        toastContext.addToast({
          message: e.response.data.message || "Failed to update subscription",
          status: "Error",
        });
      }
    }
  };

  useEffect(() => {
    if (currentQuantity) {
      setQuantity(currentQuantity);
    }
  }, [currentQuantity]);

  return (
    <form className="mt-1 flex gap-2" onSubmit={changeQuantityPreview}>
      <input
        className="rounded border border-dm-charcoal-100 px-2 py-1"
        type="number"
        value={quantity}
        onChange={(e) => setQuantity(Number(e.target.value))}
        min={1}
      />
      <Button
        size="small"
        disabled={quantity === currentQuantity}
        isLoading={isChangingQuantity}
      >
        Change quantity
      </Button>
    </form>
  );
};
