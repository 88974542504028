import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { useCourseContext } from "../../contexts/CourseContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import Sidebar from "../Sidebar/unittest";
import ScoreBar from "../ScoreBar";
import SubunitsSolving from "../Subunit/SubunitsSolving";
import { findIndex, clamp } from "lodash";
import { Skill, LearnerAssignmentSkillData } from "../../types";
import TestSummary from "../TestSummary";
import { urlParam, urlParamType, getReadableAssignmentType } from "../../utils";
import MobileQuestionDropdown from "../Sidebar/MobileQuestionDropdown";
import { useTestTimer } from "../../utils/useTestTimer";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import runConfetti from "../../utils/confetti";
import { LearnerPage } from "../Layouts/LearnerPage";
import { SkeletonText } from "../Skeleton";
import { AssignmentSubmissionLoading } from "../AssignmentSubmissionLoading";

const UnitTest = () => {
  const { coursePath, unitPath, indexOfSkill } = useParams();
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery("(max-width : 1023px)");

  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courseData = courseContext.getCourseData(coursePath);
  const unitData = courseContext.getUnitData(unitPath, coursePath);
  const courseProgress = learnerContext.getProgress(courseData?.id || "");
  const unitProgress = courseProgress?.units?.find(
    (p) => p.unitId === unitData?.id
  );
  const unitAssignment = unitProgress?.unitTest?.assignment;

  const queryString = window.location.search;

  const testTimer = useTestTimer(unitAssignment);

  const [isWaitingForAssignmentSubmit, setIsWaitingForAssignmentSubmit] =
    useState(false);

  // This needs to be here for hook rules to utilize useDmQuery and set state in the context to fetch
  // this component needs to be fully mounted before updating that state
  useEffect(() => {
    courseContext.setActiveCourse(courseData?.id);
  }, [courseContext, courseData]);

  const submitCallback = (param?: urlParamType) => {
    navigate(
      `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/unittest${urlParam(
        param
      )}`
    );
  };

  // if unitTest doesn't exist in the learnerContext, redirect to the unit summary page
  useEffect(() => {
    if (!!unitProgress && !unitProgress?.unitTest) {
      navigate(`${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}`, {
        replace: true,
      });
    }
  }, [coursePath, navigate, unitPath, unitProgress, unitProgress?.unitTest]);

  const skillsCount = unitAssignment?.skills?.length || 0;
  const firstUnsolved = clamp(
    findIndex(
      unitAssignment?.skills,
      (skill: LearnerAssignmentSkillData) => !skill.skillComplete
    ),
    0,
    skillsCount - 1
  );

  const firstPostQuizAttempt = !unitProgress?.unitTest?.assignment?.resultHist;
  useEffect(() => {
    if (!firstPostQuizAttempt || !unitProgress?.unitTest) {
      return;
    } else if (
      !indexOfSkill &&
      unitProgress?.unitTest?.assignment &&
      !unitProgress?.unitTest?.assignment?.submitted
    ) {
      navigate(
        `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/unittest/${firstUnsolved}`,
        {
          replace: true,
        }
      );
    }
  }, [
    coursePath,
    firstPostQuizAttempt,
    firstUnsolved,
    indexOfSkill,
    navigate,
    unitPath,
    unitProgress?.unitTest,
    unitProgress?.unitTest?.assignment,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("complete") === "true") {
      runConfetti(learnerContext.learner);
      const params = new URLSearchParams(urlParams.toString());
      params.delete("complete");
      navigate(
        `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/unittest${
          params.toString() !== "" ? `?${params.toString()}` : ""
        }`,
        { replace: true }
      );
    }
  }, [coursePath, navigate, unitPath, queryString]);

  useEffect(() => {
    if (isWaitingForAssignmentSubmit && indexOfSkill === undefined) {
      setIsWaitingForAssignmentSubmit(false);
    }
  }, [indexOfSkill, isWaitingForAssignmentSubmit]);

  if (
    courseContext.state.isLoadingCourse ||
    courseContext.state.isLoadingSubunits ||
    learnerContext.state.isLoadingProgress ||
    learnerContext.state.isLoadingAssignment
  ) {
    return (
      <LearnerPage title="Unit Test" noPadding>
        <div className="dm-scorebar w-full border-b border-dm-charcoal-100 bg-white px-6 py-5">
          <SkeletonText>Loading Unit Test</SkeletonText>
        </div>
      </LearnerPage>
    );
  }

  if (!courseData || !unitData || !unitAssignment) {
    return (
      <LearnerPage title="Unit Test">
        <div className="flex h-full w-full items-center justify-center text-dm-error-500">
          There was an error loading this page
        </div>
      </LearnerPage>
    );
  }

  if (courseData?.unitOrder.length === 0 || !courseData?.units) {
    return (
      <LearnerPage title="Unit Test">
        There are no units for this course or the order is not set.
      </LearnerPage>
    );
  }

  const unitIndex =
    courseData.unitOrder.findIndex((uId) => uId === unitData.id) + 1;

  const scoreBarTitle = `Unit ${unitIndex}: ${unitData.name}`;

  //TODO: Needed for skill names. Refactor skills in <SubunitsSolving>, only used for
  // matching skillcodes to skillnames.
  const allSkillInfo = courseContext.getSkillData(unitData.id);

  const skillNameList: Skill[] = unitAssignment.skills.map(
    (skill: LearnerAssignmentSkillData) => {
      return allSkillInfo[skill.sk];
    }
  );

  return (
    <LearnerPage
      title={`${unitData.name} Test`}
      sidebar={<Sidebar type="unitTest" />}
      noPadding
    >
      <ScoreBar
        pageState={"unitTest"}
        assignment={unitAssignment}
        title={scoreBarTitle}
        preQuizAssignment={undefined}
        postQuizAssignment={undefined}
        skippedPreQuiz={undefined}
        preQuiz100={false}
        callback={submitCallback}
        finishedSubunit={false}
        testTimer={testTimer}
        onSubmitAssignment={() => {
          setIsWaitingForAssignmentSubmit(true);
        }}
      />
      {isSmallDevice && indexOfSkill !== undefined && (
        <>
          <h2 className="bg-white px-4 py-2 text-sm font-bold lg:hidden">{`${scoreBarTitle} ${getReadableAssignmentType(
            "unitTest"
          )}`}</h2>
          <MobileQuestionDropdown type={"unitTest"} />
        </>
      )}
      <div className="flex-grow bg-dm-background-blue-100 pb-8 text-center text-dm-charcoal-500 sm:p-6 lg:p-8">
        {indexOfSkill !== undefined ? (
          isWaitingForAssignmentSubmit ? (
            <AssignmentSubmissionLoading assignmentType="unitTest" />
          ) : (
            <SubunitsSolving
              assignment={unitAssignment}
              skills={skillNameList}
              testTimer={testTimer}
            />
          )
        ) : (
          <TestSummary
            assignment={unitAssignment}
            unitId={unitData.id}
            courseId={courseData.id}
          />
        )}
      </div>
    </LearnerPage>
  );
};

export default UnitTest;
