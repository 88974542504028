import axios from "axios";
import md5 from "md5";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import Cookies from "js-cookie";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useUserContext } from "../../shared/contexts/UserContext";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import DeltaMathToggle from "../../admin/reports/student-performance/DeltaMathToggle";
import { isEmpty } from "lodash";
import { InformationCircleIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";
import {
  PASSWORD_MUST_MATCH,
  PasswordErrorMessages,
  evaluatePassword,
} from "../../utils";
import { useLocation } from "react-router-dom";

const Profile = () => {
  const refresh_token_cookie = Cookies.get("refresh_token_javascript");
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const isCustomerService = !!localStorage.getItem("customer_service_token");
  const toastContext = useDeltaToastContext();
  const { dmSectionsData } = useContext(StudentSectionsContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [highContrastMode, setHighContrastMode] = useState(
    JSON.parse(localStorage.getItem("user") || "{}")?.preferences
      ?.highContrast || false
  );
  const [editMode, setEditMode] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(
    query.get("passwordRedirect") === "true" || false
  );
  const [hideOldPassword] = useState(
    query.get("passwordRedirect") === "true" || false
  );
  const [noPasswordChanging, setNoPasswordChanging] = useState(false);
  const [userName, setUserName] = useState("");
  const [userLogin, setUserLogin] = useState("");
  const [studentIdRequired, setStudentIdRequired] = useState(false);
  const [infoPayload, setInfoPayload] = useState({
    fields: {
      first: "",
      last: "",
      login: "",
      student_id: "",
      googleClassroom: "",
      highContrast: false,
    },
    stats: false,
    student: true,
    noPassword: false,
  });

  const removeNoPassword = () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    if (user.password === "none") {
      // This way we will know that password is now set
      delete user.password;
      setInfoPayload({
        ...infoPayload,
        noPassword: false,
      });

      localStorage.setItem("user", JSON.stringify(user));
    }
  };

  useEffect(() => {
    if (refresh_token_cookie) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      if (!isEmpty(user)) {
        setUserName(`${user.first ?? ""} ${user.last ?? ""}`);
        setInfoPayload({
          ...infoPayload,
          fields: {
            ...infoPayload.fields,
            first: user.first ?? "",
            last: user.last ?? "",
            login: user.login,
            student_id: user.student_id || "",
            googleClassroom: user.google_classroom_id || "",
          },
          noPassword: user.password === "none",
        });
      }
      if (user?.login) {
        setUserLogin(user.login);
        if (/[0-9a-f]{24}_/.test(user.login)) {
          setNoPasswordChanging(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (dmSectionsData) {
      const idRequired = dmSectionsData.some(
        (section: { id_required?: boolean }) => section.id_required === true
      );
      /** show Student ID field if any teacher requires an id  */
      if (idRequired) {
        setStudentIdRequired(true);
      }
    }
  }, [dmSectionsData]);

  const updateStudentInfo = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/update_user_field", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess(data: any) {
        if (data?.data?.success) {
          toastContext.addToast({
            status: "Success",
            message: "Info successfully updated!",
          });
          setUserName(`${infoPayload.fields.first} ${infoPayload.fields.last}`);
          setUserLogin(`${infoPayload.fields.login}`);
          const user = JSON.parse(localStorage.getItem("user") || "{}");
          //update local storage. Don't know how needed this is but it shouldn't happen in the save button callback, which is where it was
          if (!isEmpty(user)) {
            user.first = infoPayload.fields.first;
            user.last = infoPayload.fields.last;
            user.login = infoPayload.fields.login;
            localStorage.setItem("user", JSON.stringify(user));
          }
          const newUser = {
            ...user,
            first: infoPayload.fields.first,
            last: infoPayload.fields.last,
            login: infoPayload.fields.login,
            student_id: infoPayload.fields.student_id,
          };
          updateUser({ ...newUser });
          setEditMode(false);
        } else if (data?.data?.message) {
          toastContext.addToast({
            status: "Error",
            message: data?.data?.message,
          });
        }
      },
    }
  );

  const updateStudentPreferences = useMutation(
    (body: string) => {
      return axios.post(
        deltamathAPI() + "/student/updateUserPreferences",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess(data: any) {
        if (data?.data?.success) {
          toastContext.addToast({
            status: "Success",
            message: "Preferences successfully updated!",
          });
        }
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message:
            "Preferences are updated for this session, but were not saved to your account.",
        });
      },
    }
  );

  return (
    <main id="main-content" className="flex-grow overflow-auto p-8">
      <div className="flex min-w-0 flex-1 flex-col gap-2 pb-2 sm:flex-row sm:items-end sm:gap-6">
        <h3 className="text-2xl font-bold leading-5 text-gray-900 sm:truncate sm:leading-7">
          Profile Information
        </h3>
      </div>
      <div className="flex flex-row border border-x-0 border-y-2 py-2">
        <button
          disabled={editMode}
          onClick={() => {
            setShowChangePassword(false);
            setEditMode(!editMode);
          }}
          className="border-1 rounded-md border bg-gray-300 p-2 hover:bg-gray-500 disabled:cursor-not-allowed disabled:opacity-50"
        >
          Edit Info
        </button>
        {!noPasswordChanging && (
          <button
            disabled={showChangePassword}
            onClick={() => {
              setShowChangePassword(!showChangePassword);
              setEditMode(false);
            }}
            className="border-1 ml-2 rounded-md border bg-dm-dark-blue p-2 text-white hover:bg-dm-lightest-blue disabled:cursor-not-allowed disabled:opacity-50"
          >
            Change Password
          </button>
        )}
      </div>
      <form
        className="my-2"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          type="text"
          autoComplete="username"
          name="username"
          value={infoPayload.fields.login}
          className="hidden"
          readOnly
        />
        {showChangePassword ? (
          <UpdatePasswordInline
            setIsOpen={setShowChangePassword}
            hideOldPassword={hideOldPassword}
            noPassword={infoPayload.noPassword}
            removeNoPassword={removeNoPassword}
          />
        ) : userName.length && userLogin.length && editMode ? (
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            {/* <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  value={infoPayload.fields.first}
                  onChange={(e) => {
                    setInfoPayload({
                      ...infoPayload,
                      fields: {
                        ...infoPayload.fields,
                        first: e.target.value,
                      },
                    });
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  value={infoPayload.fields.last}
                  onChange={(e) => {
                    setInfoPayload({
                      ...infoPayload,
                      fields: {
                        ...infoPayload.fields,
                        last: e.target.value,
                      },
                    });
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}

            {isCustomerService && (
              <>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    First
                  </label>
                  <div className="mt-2">
                    <input
                      id="first"
                      name="firt"
                      type="text"
                      value={
                        !/[0-9a-f]{24}_/.test(userLogin) //this is checking that we don't display an id from an LTI
                          ? infoPayload.fields.first
                          : ""
                      }
                      onChange={(e) => {
                        setInfoPayload({
                          ...infoPayload,
                          fields: {
                            ...infoPayload.fields,
                            first: e.target.value,
                          },
                        });
                      }}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="last"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Last
                  </label>
                  <div className="mt-2">
                    <input
                      id="last"
                      name="last"
                      type="text"
                      value={
                        !/[0-9a-f]{24}_/.test(userLogin)
                          ? infoPayload.fields.last
                          : ""
                      }
                      onChange={(e) => {
                        setInfoPayload({
                          ...infoPayload,
                          fields: {
                            ...infoPayload.fields,
                            last: e.target.value,
                          },
                        });
                      }}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </>
            )}

            {!/[0-9a-f]{24}_/.test(infoPayload.fields.login) && (
              <>
                {!infoPayload.fields.googleClassroom ? (
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="login"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Login
                    </label>
                    <div className="mt-2">
                      <input
                        id="login"
                        name="login"
                        type="email"
                        value={
                          !/[0-9a-f]{24}_/.test(userLogin)
                            ? infoPayload.fields.login
                            : ""
                        }
                        onChange={(e) => {
                          setInfoPayload({
                            ...infoPayload,
                            fields: {
                              ...infoPayload.fields,
                              login: e.target.value,
                            },
                          });
                        }}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="login"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Login: {infoPayload.fields.login}
                    </label>
                    <div className="mt-2">
                      Since you have linked this account to a google classroom
                      course, you cannot change your email address.
                    </div>
                  </div>
                )}
              </>
            )}
            {studentIdRequired && (
              <div className="sm:col-span-2">
                <label
                  htmlFor="student-id"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Student ID
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="student-id"
                    id="student-id"
                    value={infoPayload.fields.student_id}
                    onChange={(e) => {
                      setInfoPayload({
                        ...infoPayload,
                        fields: {
                          ...infoPayload.fields,
                          student_id: e.target.value,
                        },
                      });
                    }}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}
            <div className="flex justify-end sm:col-span-6 sm:justify-start">
              <button
                type="button"
                onClick={() => setEditMode(!editMode)}
                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                // type="submit"
                onClick={() => {
                  if (
                    infoPayload.fields.first.trim().length === 0 ||
                    infoPayload.fields.last.trim().length === 0
                  ) {
                    toastContext.addToast({
                      status: "Error",
                      message: "Please enter a valid first and last name.",
                    });
                    return;
                  }
                  if (infoPayload.fields.login.trim().length === 0) {
                    toastContext.addToast({
                      status: "Error",
                      message: "Please enter a valid login.",
                    });
                    return;
                  }
                  updateStudentInfo.mutate(
                    JSON.stringify({
                      ...infoPayload,
                    })
                  );
                }}
                className="ml-3 inline-flex justify-center rounded-md bg-dm-dark-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-dm-lightest-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="inline-flex">
              <span className="text-dm-dark-blue sm:text-lg">Name:</span>
              <span className="ml-2 text-dm-dark-blue sm:text-lg">
                {userName}
              </span>
            </div>
            {!/[0-9a-f]{24}_/.test(userLogin) && (
              <div>
                <span className="text-dm-dark-blue sm:text-lg">Login:</span>
                <span className="ml-2 text-dm-dark-blue sm:text-lg">
                  {userLogin}
                </span>
              </div>
            )}
            {studentIdRequired && (
              <div className="inline-flex">
                <span className="text-dm-dark-blue sm:text-lg">
                  Student ID:
                </span>
                <span className="ml-2 text-dm-dark-blue sm:text-lg">
                  {infoPayload.fields.student_id}
                </span>
              </div>
            )}
          </div>
        )}
      </form>
      {!editMode && (
        <div className="border border-x-0 border-y-2 py-2 leading-5 sm:leading-7">
          <div className="mt-16 flex items-center justify-start gap-2">
            <p className="text-gray-900 sm:text-lg">Increased Contrast Mode</p>
            <InformationCircleIcon
              className="h-5 w-5 text-gray-400"
              data-tip
              data-for="contrast-tooltip"
              data-place="right"
              aria-hidden={true}
            />
          </div>
          {/* Note: the toggle container div below is a hack to avoid some flex behavior in the DeltaMathToggle component (built for manager)
          TODO: update DeltaMathToggle component for more flexible usage across manager and student apps */}
          <div className="flex">
            <DeltaMathToggle
              optionA={"Disabled"}
              optionB={"Enabled"}
              aSelected={!highContrastMode}
              onChangeFn={() => {
                // update database with user preferences
                updateStudentPreferences.mutate(
                  JSON.stringify({
                    preferences: {
                      highContrast: !highContrastMode,
                    },
                  })
                );
                /* Local storage updates will execute, even if the preferences were not successfully saved to the user's account. */
                // update local storage with user preferences
                const user = JSON.parse(localStorage.getItem("user") || "{}");
                const newUser = {
                  ...user,
                  preferences: {
                    ...user.preferences,
                    highContrast: !highContrastMode,
                  },
                };
                updateUser({ ...newUser });
                // update state
                setHighContrastMode(!highContrastMode);
              }}
            />
          </div>
          <ReactTooltip
            id="contrast-tooltip"
            className="max-w-[80%] text-center text-xs"
          >
            <p className="m-1">
              Increases the color contrast for all DeltaMath questions,
              solutions and examples
            </p>
          </ReactTooltip>
        </div>
      )}
    </main>
  );
};

const UpdatePasswordInline = ({
  setIsOpen,
  noPassword,
  hideOldPassword,
  removeNoPassword,
}: {
  setIsOpen: (value: any) => void;
  noPassword?: boolean;
  hideOldPassword: boolean;
  removeNoPassword: () => void;
}) => {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();

  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordPayload, setPasswordPayload] = useState({
    fields: {
      oldpassword: "",
      password: "",
      meets_criteria: false,
    },
    stats: false,
    student: true,
  });
  const [newPasswordError, setNewPasswordError] = useState("");

  const updateStudentPassword = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/update_user_field", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess(data: any) {
        if (data?.data?.success) {
          toastContext.addToast({
            status: "Success",
            message: "Password successfully updated!",
          });
          setPasswordPayload({
            fields: {
              oldpassword: "",
              password: "",
              meets_criteria: false,
            },
            stats: false,
            student: true,
          });
          removeNoPassword();
          setPasswordConfirmation("");
          setIsOpen(false);
        } else if (data?.data?.message) {
          toastContext.addToast({
            status: "Error",
            message: data?.data?.message,
          });
        }
      },
    }
  );

  useEffect(() => {
    setNewPasswordError("");
    if (passwordPayload.fields.password.length > 0) {
      if (passwordConfirmation.length === 0) {
        setNewPasswordError("Please confirm your new password.");
      } else if (
        passwordConfirmation.length > 0 &&
        passwordConfirmation !== passwordPayload.fields.password
      ) {
        setNewPasswordError(PASSWORD_MUST_MATCH);
      }
    } else {
      setNewPasswordError("");
    }
  }, [passwordConfirmation, passwordPayload]);

  return (
    <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6">
      <div className="sm:col-span-6">
        <PasswordErrorMessages
          passwordValue={passwordPayload.fields.password}
        />
      </div>

      {!noPassword && !hideOldPassword && (
        <div className="sm:col-span-4">
          <label
            htmlFor="old-password"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Old Password
          </label>
          <div className="mt-2">
            <input
              id="old-password"
              name="old-password"
              autoComplete="current-password"
              type="password"
              onChange={(e) => {
                setPasswordPayload({
                  ...passwordPayload,
                  fields: {
                    ...passwordPayload.fields,
                    oldpassword: e.target.value,
                  },
                });
              }}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      )}
      <div className="sm:col-span-3">
        <label
          htmlFor="new-password"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          New Password
        </label>
        <div className="mt-2">
          <input
            type="password"
            name="new-password"
            aria-invalid={newPasswordError ? true : false}
            aria-describedby="password-error"
            id="new-password"
            autoComplete="new-password"
            onChange={(e) => {
              setPasswordPayload({
                ...passwordPayload,
                fields: {
                  ...passwordPayload.fields,
                  password: e.target.value,
                  meets_criteria: evaluatePassword(e.target.value),
                },
              });
            }}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="confirm-password"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Confirm New Password
        </label>
        <div className="mt-2">
          <input
            type="password"
            name="confirm-password"
            id="confirm-password"
            autoComplete="new-password"
            aria-invalid={newPasswordError ? true : false}
            aria-describedby="password-error"
            onChange={(e) => {
              setPasswordConfirmation(e.target.value);
            }}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="sm:col-span-3">
        <div className="text-red-500">
          {newPasswordError && newPasswordError}
        </div>
        <div className="mt-2 flex justify-end sm:justify-start">
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={
              !!newPasswordError ||
              !passwordPayload.fields.password ||
              !passwordConfirmation ||
              (!hideOldPassword && !passwordPayload.fields.oldpassword)
            }
            onClick={() => {
              const oldpass = md5(
                "deltamath" + passwordPayload.fields.oldpassword
              );
              const newpass = md5(
                "deltamath" + passwordPayload.fields.password
              );
              setPasswordPayload({
                ...passwordPayload,
                fields: {
                  oldpassword: oldpass,
                  password: newpass,
                  meets_criteria: passwordPayload?.fields?.meets_criteria,
                },
              });
              updateStudentPassword.mutate(
                JSON.stringify({
                  ...passwordPayload,
                  fields: {
                    oldpassword: oldpass,
                    password: newpass,
                    meets_criteria: passwordPayload?.fields?.meets_criteria,
                  },
                })
              );
            }}
            className="ml-3 inline-flex justify-center rounded-md bg-dm-dark-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-dm-lightest-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export const updateUser = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export default Profile;
