import { useMutation } from "react-query";
import {
  CreateLearnerAssignmentResponse,
  LearnerAssignmentTypes,
  SkipPreQuizResponse,
} from "../types";
import { deltamathAPI } from "../../manager/utils";
import axios from "axios";
import { useLearnerContext } from "../contexts/LearnerContext";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { unreachableCase } from "../../utils";
import { ComponentTimer } from "./useComponentTimer";
import { useLearnerAnalytics } from "../analytics/useLearnerAnalytics";
import { startAssessmentEvent } from "../analytics/events";

function getNounForAssignmentType(type: LearnerAssignmentTypes) {
  switch (type) {
    case "preQuiz":
      return "pre-quiz";
    case "courseTest":
      return "course test";
    case "postQuiz":
      return "post-quiz";
    case "practice":
      return "practice";
    case "unitTest":
      return "unit test";
    default:
      return unreachableCase(type);
  }
}

type SkipPreQuizMutationFunction = (
  courseId: string,
  subunit: string
) => Promise<SkipPreQuizResponse>;

/** Returns an async mutation function to skip a learner's pre-quiz */
export function useSkipPreQuizMutation(
  testTimer?: ComponentTimer
): SkipPreQuizMutationFunction {
  const learnerContext = useLearnerContext();
  const toastContext = useDeltaToastContext();

  const { mutate } = useMutation((body: string) => {
    return axios.post<SkipPreQuizResponse>(
      `${deltamathAPI()}/learner/assignment/skip`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  });

  return (courseId, subunitId) => {
    return new Promise((resolve, reject) => {
      mutate(
        JSON.stringify({
          courseId,
          subunitId,
          ...(testTimer
            ? {
                duration: testTimer.elapsed
                  ? Math.round(testTimer.elapsed / 1000)
                  : undefined,
              }
            : {}),
        }),
        {
          onSuccess(data) {
            if (data.data) {
              learnerContext.skipPreQuiz(data.data);
            }
            testTimer?.clearTimer();
            resolve(data.data);
          },
          onError() {
            toastContext.addToast({
              status: "Error",
              message: "There was an error skipping your pre-quiz",
            });
            reject();
          },
        }
      );
    });
  };
}

type CreateAssignmentMutationFunction = (
  type: LearnerAssignmentTypes,
  courseId: string,
  unitId?: string,
  subunitId?: string
) => Promise<CreateLearnerAssignmentResponse>;

/** Returns an async mutation function to create an assignment for a learner */
export function useCreateAssignmentMutation(): CreateAssignmentMutationFunction {
  const learnerContext = useLearnerContext();
  const toastContext = useDeltaToastContext();
  const { track, getAssignmentData } = useLearnerAnalytics();

  const { mutate } = useMutation((body: string) => {
    return axios.post<CreateLearnerAssignmentResponse>(
      `${deltamathAPI()}/learner/assignment/create`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  });

  return (type, courseId, unitId, subunitId) => {
    return new Promise((resolve, reject) => {
      mutate(
        JSON.stringify({
          type,
          courseId,
          unitId,
          ...(subunitId ? { subunitId } : {}),
        }),
        {
          onSuccess(data) {
            if (data.data.progress) {
              learnerContext.setLearnerProgress(data.data.progress);
            }
            learnerContext.setLearnerAssignment(
              courseId,
              data.data.assignment,
              type,
              unitId,
              subunitId
            );

            track(
              startAssessmentEvent({
                ...getAssignmentData(type),
                attemptNumber: 1, // Attempt number is always 1 when creating a new assignment
              })
            );

            resolve(data.data);
          },
          onError() {
            toastContext.addToast({
              status: "Error",
              message: `There was an issue initializing your ${getNounForAssignmentType(
                type
              )}`,
            });
            reject();
          },
        }
      );
    });
  };
}

export type urlParamType = "refetch" | "complete" | undefined;
export const urlParam = (param: urlParamType): string => {
  if (param === "refetch") {
    return "/0?refetch=true";
  } else if (param === "complete") {
    return "?complete=true";
  }

  return "";
};

export const getReadableAssignmentType = (type: LearnerAssignmentTypes) => {
  switch (type) {
    case "preQuiz":
      return "Pre-Quiz";
    case "courseTest":
      return "Course Test";
    case "postQuiz":
      return "Post-Quiz";
    case "practice":
      return "Practice";
    case "unitTest":
      return "Unit Test";
    default:
      return unreachableCase(type);
  }
};

type SubunitProgresses = {
  id: string;
  progress: number;
}[];

export const getUnitProgressAriaLabel = (
  progresses?: SubunitProgresses,
  unitNumber?: number
) => {
  const summary = {
    complete: 0,
    inProgress: 0,
    notStarted: 0,
  };
  progresses?.forEach((section) => {
    if (section.progress === 1) summary.complete += 1;
    else if (section.progress === 0) summary.notStarted += 1;
    else summary.inProgress += 1;
  });

  return `Unit ${unitNumber === undefined ? "" : unitNumber + " "}has ${
    summary.notStarted
  } section${summary.notStarted === 1 ? "" : "s"} not started, ${
    summary.inProgress
  } section${summary.inProgress === 1 ? "" : "s"} in progress, and ${
    summary.complete
  } section${
    summary.complete === 1 ? "" : "s"
  } complete, including the unit test.`;
};
