import { useState } from "react";
import { useDMQuery } from "../../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import Integrations from "./Integrations";
import LaunchLogs from "./LaunchLogs";

export interface ILtiLog {
  timestamp?: number;
  tool_consumer_info_product_family_code?: string;
  lis_person_name_full?: string;
  lis_person_contact_email_primary?: string;
  context_id?: string;
  context_type?: string;
  context_title?: string;
  resource_link_id?: string;
  resource_link_title?: string;
  oauth_consumer_key?: string;
}

export interface IIntegration {
  key: string;
  secret: string;
  one_roster_key?: string;
  one_roster_secret?: string;
  one_roster_url?: string;
  rostering_domains?: string[];
  nces_id?: string;
  nces_ids?: string[];
  site_type?: string;
  classlink_tenant_id?: number;
  clever_district_id?: string | string[];
  clever_token?: string | string[];

  teachercode?: string;
  opt_out_rostered_launch?: boolean;
  opt_out_rostered_launch_fully?: boolean;
  use_enrollments?: boolean;
}

const LTILogs = () => {
  const [logs, setLogs] = useState<ILtiLog[]>([]);
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const [email, setEmail] = useState<string>("");

  const toastContext = useDeltaToastContext();

  useDMQuery({
    path: `/manager_new/integrations/launch-logs/${email}`,
    queryOptions: {
      enabled: email.length > 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (data: {
        ltiLaunchLogs: Array<ILtiLog>;
        integrations: Array<IIntegration>;
      }) => {
        setLogs(data.ltiLaunchLogs);
        setIntegrations(data.integrations);
      },
      onError: (error: any) => {
        toastContext.addToast({
          status: "Error",
          message:
            error.message ||
            "An error occurred while fetching the LTI logs. Please try again later.",
        });
      },
    },
  });

  return (
    <>
      <div className="group relative bg-white p-6">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <form
              className="mt-2"
              onSubmit={(event) => {
                event.preventDefault();
                const email = new FormData(event.target as HTMLFormElement).get(
                  "email"
                ) as string;
                setEmail(email);
              }}
            >
              <label
                htmlFor="nces"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Search by Email
              </label>
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <button
                  type="submit"
                  className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Search &rarr;
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {integrations?.length > 0 && <Integrations integrations={integrations} />}
      {logs?.length > 0 && <LaunchLogs logs={logs} />}
      {(!integrations || integrations.length === 0) &&
        (!logs || logs.length === 0) && <p>No Results to Display</p>}
    </>
  );
};

export default LTILogs;
