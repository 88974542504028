import { useMediaQuery } from "usehooks-ts";
import { getFilePath } from "../../../utils";
import clsx from "clsx";
import { MAX_CONTAINER_WIDTH } from "./constants";
import { SectionTitle } from "./SectionTitle";
import { TiltedBanner } from "./TiltedBanner";
import Button from "../../../student/components/generic/button";
import { ResponsiveImage } from "../ResponsiveImage";

export const FooterCtaWide: React.FC<{ onCtaClick: () => void }> = ({
  onCtaClick,
}) => {
  const isHighDpi = useMediaQuery("screen and (min-resolution: 73dpi)");
  const texturedBgTile = getFilePath(
    isHighDpi
      ? "/images/learner/textured-blue-tile@2x.png"
      : "/images/learner/textured-blue-tile.png"
  );

  return (
    <section className="flex w-full justify-center px-6">
      <div
        className={clsx(
          "relative flex w-full items-stretch justify-stretch",
          MAX_CONTAINER_WIDTH
        )}
        style={{
          borderImage: `url(${getFilePath(
            isHighDpi
              ? "/images/learner/home/textured-blue-full-border@2x.png"
              : "/images/learner/home/textured-blue-full-border.png"
          )})`,
          borderImageSlice: "60",
          borderWidth: "37px 31px",
        }}
      >
        <div
          className="flex h-full w-full flex-col items-center justify-center bg-[length:200px_200px] px-4 pb-40 pt-16 xl:pb-16"
          style={{ backgroundImage: `url('${texturedBgTile}')` }}
        >
          <SectionTitle
            title="Personalized Learning. Proven Results."
            subtitle="Experience the DeltaMath for Home difference today!"
          />
          <TiltedBanner className="-right-10 -top-4" />
          <Button className="-mt-4 w-96 text-sm" onClick={onCtaClick}>
            Get Unlimited Access
          </Button>
        </div>

        <ResponsiveImage
          className="absolute -bottom-[38px] left-[1%] xl:left-[34px]"
          srcs={[
            getFilePath("/images/learner/home/deltie-thinking.png"),
            getFilePath("/images/learner/home/deltie-thinking@2x.png"),
          ]}
          alt="Deltie thinking"
        />
      </div>
    </section>
  );
};
