import { useEffect, useState } from "react";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { PascalsTriangle } from "./PascalsTriangle";
import { PointsHexagon } from "./PointsHexagon";
import { PascalsOnboarding } from "./onboarding/PascalsOnboarding";
import { MIN_POINTS_TO_UNLOCK } from "../../constants";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";

export const PascalsTriangleUnlocked: React.FC<{
  needsOnboarding: boolean;
  setShowUnlockPrizeModal: (s: boolean) => void;
}> = ({ needsOnboarding, setShowUnlockPrizeModal }) => {
  const { learner, pointsAvailable } = useLearnerContext();
  const [pointsAvailableToDisplay, setPointsAvailableToDisplay] = useState<
    number | null
  >(null);

  const rowNumber = (learner?.pascalsRow?.length ?? 0) - 1;

  const [showOnboarding, setShowOnboarding] = useState(needsOnboarding);

  // Count down when pointsAvailable changes
  useEffect(() => {
    if (showOnboarding) {
      if (pointsAvailableToDisplay === null && pointsAvailable !== null) {
        setPointsAvailableToDisplay(pointsAvailable);
      }
      return;
    }
    if (pointsAvailableToDisplay === null) {
      setPointsAvailableToDisplay(pointsAvailable);
    } else if (
      pointsAvailable !== null &&
      pointsAvailable < pointsAvailableToDisplay
    ) {
      setTimeout(() => {
        setPointsAvailableToDisplay(pointsAvailableToDisplay - 1);
      }, 80);
    }
  }, [
    learner?.pointsEarned,
    pointsAvailable,
    pointsAvailableToDisplay,
    showOnboarding,
  ]);

  return (
    <div className="relative z-0 flex flex-col items-center justify-between gap-6 pb-8 pt-4 md:flex-row md:gap-4">
      <div className="flex flex-col gap-2 self-start md:gap-4 md:self-center">
        <div className="flex items-end gap-2 font-serif">
          <PointsHexagon points={pointsAvailableToDisplay || 0} />
          pts available
        </div>
        {!showOnboarding && (
          <h3 className="font-serif text-lg font-bold">
            {rowNumber} rows unlocked!
          </h3>
        )}
      </div>
      {showOnboarding ? (
        <PascalsOnboarding
          onCellFilled={(points) => {
            if (pointsAvailableToDisplay) {
              setPointsAvailableToDisplay(pointsAvailableToDisplay - points);
            }
          }}
          onDone={() => {
            setShowUnlockPrizeModal(true);
            setShowOnboarding(false);
          }}
        />
      ) : (
        <div className="m-auto mb-4 flex w-full flex-col items-center md:w-auto md:flex-row md:items-end">
          <PascalsTriangle />
          <ResponsiveImage
            className="-md:mb-8 -md:ml-8 absolute -bottom-6 -right-4 -mt-8 w-[110px] flex-none md:relative md:mt-0 lg:w-[166px]"
            srcs={[
              getFilePath(
                "/images/learner/points/pascals-triangle-earned-points-unlocked.png"
              ),
              getFilePath(
                "/images/learner/points/pascals-triangle-earned-points-unlocked@2x.png"
              ),
            ]}
            alt="Deltie with starry eyes"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  );
};
