import React, { useEffect, useState } from "react";
import { REACT_APP_MANAGER_LINK, timeConverter, useDMQuery } from "../../utils";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function LicenseLookup() {
  const navigate = useNavigate();
  const params = useParams();

  const [searchParams, setSearchParams] = useState("");
  const [searchNces, setSearchNces] = useState("");
  const [searchEmail, setSearchEmail] = useState("");

  const { data, refetch } = useDMQuery({
    path: `/manager_new/license/lookup?${searchParams}`,
    customerServiceHeader: true,
    queryOptions: {
      enabled: false,
      select: (data: any) => {
        return {
          District: Array(data.District), // District needs to be a one element array for the table to work
          Schools: data.Schools.filter(
            (s: { numberTeachers: number; dmLicense: any }) =>
              s.numberTeachers > 0 || s.dmLicense.expiration > 0
          ), // Filter out schools with no teachers
        };
      },
    },
  });

  // URL Parameters have changed, update state
  useEffect(() => {
    const urlParams = new URLSearchParams({
      ...(params.admin_email && { email: params.admin_email }),
      ...(params.id && { id: params.id }),
    });
    setSearchNces(params.id ? params.id : "");
    setSearchEmail(params.admin_email ? params.admin_email : "");
    setSearchParams(urlParams.toString());
  }, [params]);

  // Refetch data when search params state changed
  useEffect(() => {
    if (searchParams) {
      refetch();
    }
  }, [searchParams]);

  const districtLicenseColumns: Array<any> = [
    {
      Header: "NCES",
      accessor: "districtID",
    },
    {
      Header: "District Name",
      accessor: "districtName",
      Cell: (props: any) => {
        if (props.row.original.districtID) {
          return (
            <Link
              className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              to={`${REACT_APP_MANAGER_LINK}/district/${props.row.original.districtID}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.row.original.districtName} Quote
            </Link>
          );
        }
      },
    },
    {
      Header: "Admin Name",
      accessor: "super_admin.name",
    },
    {
      Header: "Admin Email",
      accessor: "super_admin.email",
    },
    {
      Header: "License Type",
      accessor: "dmLicense.license_type",
      align: "center",
    },
    {
      Header: "Expiration",
      align: "center",
      Cell: (props: any) =>
        props.row.original.dmLicense.expiration
          ? timeConverter(parseInt(props.row.original.dmLicense.expiration))
          : "",
    },
    {
      Header: "License Tier",
      accessor: "dmLicense.has_integral",
      align: "center",
      Cell: (props: any) => {
        if (props.row.original.dmLicense.has_integral) {
          return "Integral";
        } else if (props.row.original.dmLicense.expiration > 0) {
          return "Plus";
        }
      },
    },
    {
      Header: "Integrations",
      accessor: "dmLicense.integrations",
    },
    {
      Header: "Invoice #",
      accessor: "dmLicense.invoice",
    },
    {
      Header: "Quote #",
      accessor: "quoteNumber",
      Cell: (props: any) => (
        <Link
          className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${props.row.original.quoteNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.row.original.quoteNumber}
        </Link>
      ),
    },
  ];

  const schoolLicenseColumns: Array<any> = [
    {
      Header: "NCES",
      accessor: "schoolid",
    },
    {
      Header: "School Name",
      accessor: "schoolName",
    },
    {
      Header: "# Teachers",
      accessor: "numberTeachers",
      align: "center",
    },
    {
      Header: "Admin Name",
      accessor: "super_admin.name",
    },
    {
      Header: "Admin Email",
      accessor: "super_admin.email",
    },
    {
      Header: "License Type",
      accessor: "dmLicense.license_type",
      align: "center",
    },
    {
      Header: "Expiration",
      align: "center",
      Cell: (props: any) =>
        props?.row?.original?.dmLicense?.expiration
          ? timeConverter(parseInt(props.row.original.dmLicense.expiration))
          : "",
    },
    {
      Header: "License Tier",
      accessor: "dmLicense.has_integral",
      align: "center",
      Cell: (props: any) => {
        if (props?.row?.original?.dmLicense?.has_integral) {
          return "Integral";
        } else if (props?.row?.original?.dmLicense?.expiration > 0) {
          return "Plus";
        } else return "--";
      },
    },
    {
      Header: "Integrations",
      accessor: "dmLicense.integrations",
    },
    {
      Header: "Invoice #",
      accessor: "dmLicense.invoice",
    },
    {
      Header: "Quote #",
      accessor: "quoteNumber",
      Cell: (props: any) => {
        if (props?.row?.original?.quoteNumber) {
          return (
            <Link
              className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${props.row.original.quoteNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.row.original.quoteNumber}
            </Link>
          );
        } else return "--";
      },
    },
  ];

  return (
    <>
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <div className="group relative bg-white p-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <form
                className="mt-2"
                onSubmit={(event) => {
                  event.preventDefault();
                  navigate(
                    `${REACT_APP_MANAGER_LINK}/license-lookup/id/${searchNces.toString()}`
                  );
                }}
              >
                <label
                  htmlFor="nces"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Search by School or District NCES
                </label>
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="nces"
                    id="nces"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="7654321"
                    value={searchNces}
                    onChange={(event) => setSearchNces(event.target.value)}
                  />
                  <button
                    type="submit"
                    className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Search &rarr;
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="group relative bg-white p-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <form
                className="mt-2"
                onSubmit={(event) => {
                  event.preventDefault();
                  navigate(
                    `${REACT_APP_MANAGER_LINK}/license-lookup/admin_email/${searchEmail.toString()}`
                  );
                }}
              >
                <label
                  htmlFor="email_address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Search by Super Admin Email or Domain
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      name="email_address"
                      id="email_address"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="deltamath.com"
                      value={searchEmail}
                      onChange={(event) => setSearchEmail(event.target.value)}
                    />
                    <button
                      type="submit"
                      className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Search &rarr;
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {data && data.District && data.District.length > 0 && (
        <DeltaMathTable columns={districtLicenseColumns} data={data.District} />
      )}

      {data && data.Schools && data.Schools.length > 0 && (
        <DeltaMathTable columns={schoolLicenseColumns} data={data.Schools} />
      )}
    </>
  );
}
