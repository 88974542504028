import { REACT_APP_LEARNER_LINK } from "../../utils";
import { useCourseContext } from "../contexts/CourseContext";
import { Link } from "react-router-dom";
import { LearnerPage } from "./Layouts/LearnerPage";

const Home = () => {
  const courses = useCourseContext();

  if (courses.state.courses.length === 0) {
    return <>Loading...</>;
  }

  return (
    <LearnerPage title="Courses" sidebar={<></>} noFooter>
      <div className="flex flex-col items-start gap-6">
        {courses.state.courses.map((c) => {
          return (
            <Link
              to={`${REACT_APP_LEARNER_LINK}/course/${c.path}`}
              className="text-dm-brand-blue-500 underline"
              key={`course-${c.id}`}
            >
              {c.name}
            </Link>
          );
        })}
      </div>
    </LearnerPage>
  );
};

export default Home;
