import { useSearchParams } from "react-router-dom";
import { useDMQuery } from "../../../utils";
import { SplashPage } from "../Splash/SplashPage";
import { useDocumentTitle } from "usehooks-ts";
import { GoogleAnalyticsTag } from "../GoogleAnalyticsTag";
import { FormattedLearnerSubscriptionPlan } from "../../types";
import { FeatureList } from "./FeatureList";
import { CoursesList } from "./CoursesList";
import { Hero } from "./Hero";
import { Testimonials } from "./Testimonials";
import { Pricing } from "./Pricing";
import { Faq } from "./Faq";
import { FooterCta } from "./FooterCta";
import { Footer } from "./Footer";

export const HomePageSwitcher: React.FC = () => {
  const [params] = useSearchParams();
  const { data, isLoading } = useDMQuery<{ flags: string[] }>({
    path: "/feature-flags/learner",
  });

  const forceSoftLaunch = params.has("softlaunch");

  if (!forceSoftLaunch && isLoading) {
    return null;
  }
  if (forceSoftLaunch || data?.flags.includes("enableSoftLaunch")) {
    return <HomePage />;
  }
  return <SplashPage />;
};

const HomePage: React.FC = () => {
  useDocumentTitle("DeltaMath for Home");

  const { data: plansData, isLoading: isPlansLoading } = useDMQuery<{
    plans: FormattedLearnerSubscriptionPlan[];
  }>({
    path: "/payments/subscriptions/plans/list/published",
    queryOptions: { refetchOnWindowFocus: false },
  });

  const handleClickCta = () => {
    // TODO
    console.log("CTA clicked");
  };

  const startTrialCtaText = isPlansLoading
    ? ""
    : (plansData?.plans[0].trialLength ?? 0) > 0
    ? `Start ${plansData?.plans[0].trialLength}-day free trial`
    : "Get started";

  return (
    <main className="bg-dm-background-blue flex min-h-dvh w-full flex-col items-center gap-20 bg-dm-background-blue-100 md:gap-32 xl:gap-44">
      <GoogleAnalyticsTag />

      <Hero ctaText={startTrialCtaText} onCtaClick={handleClickCta} />
      <FeatureList ctaText={startTrialCtaText} onCtaClick={handleClickCta} />
      <CoursesList />
      <Testimonials />
      <Pricing
        plans={plansData?.plans}
        ctaText={startTrialCtaText}
        onCtaClick={handleClickCta}
      />
      <Faq />
      <FooterCta onCtaClick={handleClickCta} />
      <Footer />
    </main>
  );
};
