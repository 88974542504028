import { useMediaQuery } from "usehooks-ts";
import { SectionTitle } from "./SectionTitle";
import { useEffect, useRef, useState } from "react";
import { Testimonial } from "./types";
import { TestimonialList } from "./TestimonialList";

export const Testimonials = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const testimonials: Testimonial[] = [
    {
      name: "John",
      role: "Parent",
      text: "I love being able to see my son's progress and know where he needs help. It's a great tool for building confidence.",
    },
    {
      name: "Parker",
      role: "10th grader",
      text: "I'd recommend this to friends. It is fast and intuitive. Better than doing practice problems on your own.",
    },
    {
      name: "Maria",
      role: "Parent",
      text: "This platform has been a game-changer for my daughter. She's more engaged and motivated than ever before.",
    },
  ];

  const isSmallDevice = useMediaQuery("(max-width : 767px)");
  const itemWidth = isSmallDevice ? 324 : 486;
  const gap = 24;
  const itemsToDisplay = isSmallDevice
    ? 3
    : 1 +
      Math.ceil((containerRef.current?.clientWidth ?? 0) / (itemWidth + gap));

  useEffect(() => {
    const handleTransitionEnd = () => {
      setIsTransitioning(false);
      if (containerRef.current) {
        setStartIndex(startIndex + 1);
        containerRef.current.style.transition = "";
        containerRef.current.style.transform = `translateX(0)`;
      }
    };
    if (isTransitioning) {
      const container = containerRef.current;
      container?.addEventListener("transitionend", handleTransitionEnd);
      return () =>
        container?.removeEventListener("transitionend", handleTransitionEnd);
    } else if (containerRef.current) {
      setIsTransitioning(true);
      const containerWidth = isSmallDevice
        ? (itemWidth + gap) * itemsToDisplay
        : containerRef.current.clientWidth;
      containerRef.current.style.transition = `transform ${
        containerWidth / 40
      }s linear`;
      containerRef.current.style.transform = `translateX(-${containerWidth}px)`;
    }
  }, [isSmallDevice, isTransitioning, itemWidth, itemsToDisplay, startIndex]);

  return (
    <section className="w-full">
      <div className="px-4 md:px-0">
        <SectionTitle
          title="Hear From Happy Learners & Parents"
          subtitle="See how DeltaMath for Home is making a difference in math education"
        />
      </div>
      <div className="w-full overflow-hidden">
        <div
          className="flex w-full flex-nowrap"
          ref={containerRef}
          style={{ gap: `${gap}px` }}
        >
          {/*
            The idea here is that we have two testimonial lists that are both wider than the
            screen. We shift the whole container toward the left. Once the first list is
            all the way to the left, reset back to 0.
          */}
          <TestimonialList
            testimonials={testimonials}
            itemsToDisplay={itemsToDisplay}
            startIndex={0}
            gap={gap}
            itemWidth={itemWidth}
          />
          <TestimonialList
            testimonials={testimonials}
            itemsToDisplay={itemsToDisplay}
            startIndex={itemsToDisplay}
            gap={gap}
            itemWidth={itemWidth}
          />
        </div>
      </div>
    </section>
  );
};
