import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Transition } from "@headlessui/react";
import axios from "axios";
import ReactPlayer from "react-player";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { SkillDataType } from "../_constants";
import { skillDataDisplay, findNearestUpcoming } from "../utils";
import { useUserContext } from "../../shared/contexts/UserContext";
import jwt_decode from "jwt-decode";
import { REACT_APP_STUDENT_LINK, useDMQuery } from "../../utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import clsx from "clsx";
import { XIcon } from "@heroicons/react/outline";
import PictureInPictureIcon from "../components/icons/PictureInPictureIcon";
import { useLocation, useBeforeUnload } from "react-router-dom";
import { differenceInMilliseconds } from "date-fns";
import { compact, isEmpty } from "lodash";
import FocusTrap from "focus-trap-react";

const BLUE = "#dbeafe";
const GREEN = "#117035";

const HelpVideo = ({
  solveSkill,
  showProgress = true,
  showInModal = false,
  setShowVideo,
  showVideo,
  alreadyWatched,
  setAlreadyWatched,
}: {
  solveSkill: any;
  showProgress?: boolean;
  showInModal?: boolean;
  showVideo: boolean;
  setShowVideo: Dispatch<SetStateAction<boolean>>;
  alreadyWatched: number;
  setAlreadyWatched: Dispatch<SetStateAction<number>>;
}): JSX.Element => {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const currentLocation = useLocation();
  const {
    dmAssignmentData,
    setDmAssignmentData,
    activeSection,
    currentProblemData,
    assignmentsRefresh,
    setLoadingData,
    dmSectionsData,
  } = useContext(StudentSectionsContext);
  const token = userContext.getJWT();
  const videoRef = useRef<ReactPlayer>(null);
  const skillData = skillDataDisplay(solveSkill.ta.skillName, solveSkill);

  const decodedJwt: any = token ? jwt_decode(token) : undefined;

  const [videoUrl, setVideoUrl] = useState<string>("");
  const [subtitleUrl, setSubtitleUrl] = useState<string>("");

  const [restrictVideo, setRestrictVideo] = useState<boolean>(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const saRef = useRef<IStudentAssignment>(solveSkill.sa);
  const [sk, setSk] = useState<string>(solveSkill.ta.skillName);
  const [videoSk, setVideoSk] = useState<string>(solveSkill.ta.skillName);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [seekedTo, setSeekedTo] = useState<number | undefined>(undefined);
  const [played, setPlayed] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [timeStarted, setTimeStarted] = useState<number>();
  const [progressGradient, setProgressGradient] = useState<string>(BLUE);
  const [grade, setGrade] = useState<number>(0);
  const [pipOpen, setPipOpen] = useState(false);
  const [watchedTime, setWatchedTime] = useState<
    | {
        time: number;
        lastUpdated: Date | undefined;
      }
    | undefined
  >(undefined);
  const [alreadyProcessed, setAlreadyProcessed] = useState<
    string | undefined
  >();
  const [isWindowClosing, setIsWindowClosing] = useState<boolean>(false);

  const isLocked = userContext.getIsLocked();
  const isLti = !!localStorage.getItem("lti_assignment_payload");
  const navigate = useNavigate();

  useEffect(() => {
    setPlayed(0);
  }, [videoSk]);

  useEffect(() => {
    // using this check to not continuously update over the grade / gradient if solve skill is defined
    if (alreadyProcessed === videoSk || !solveSkill?.sa) {
      return;
    }

    if (
      solveSkill?.sa?.video_data &&
      solveSkill.sa.video_data[solveSkill.ta.skillName]
    ) {
      setGrade(solveSkill.sa.video_data[solveSkill.ta.skillName]?.grade || 0);
      setProgressGradient(
        getColorSpan(
          solveSkill.sa.video_data[solveSkill.ta.skillName],
          0,
          videoRef && videoRef.current ? videoRef.current.getDuration() : 0
        )
      );
    } else {
      setProgressGradient(BLUE);
      setGrade(0);
    }
    setAlreadyProcessed(videoSk);
  }, [solveSkill, videoSk]);

  const { refetch, error } = useDMQuery({
    path: `/student/checkLock/${solveSkill.ta._id}`,
    method: "post",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      retry: false,
    },
  });

  useEffect(() => {
    const useCurrentProblemData =
      currentProblemData &&
      Object.keys(currentProblemData).length &&
      !skillData?.isVideo;

    let skill =
      skillData.isTimedModule || !useCurrentProblemData
        ? solveSkill?.ta?.skillName
        : currentProblemData.skillcode;

    setVideoSk(skill);

    /** parse out "custom_" from skillName to construct video url */
    if (skill.substring(0, 6) === "custom") {
      skill = skill.substring(7);
    }
    const filteredSkills = Object.keys(solveSkill.ta.skills).filter(
      (skillName: string) => skillName.indexOf(skill) !== -1
    );

    /**
     * if a skill is assigned and its corrosponding video is also assigned, set the skill name
     * to the video skill name to save video_data if it is watched as a help video
     *  */
    if (filteredSkills.length > 1) {
      const videoSkill = filteredSkills.find(
        (sk: string) => sk === `custom_${skill}`
      );
      if (videoSkill) {
        setVideoSk(videoSkill);
      } else {
        // PREVIOUS LOGIC THIS SHOULD NEVER HAPPEN
        const previousLogic = filteredSkills.filter(
          (sk: string) => sk.indexOf("custom") !== -1
        );
        if (previousLogic.length > 0) {
          setVideoSk(previousLogic[0]);
        }
      }
    } else if (filteredSkills.length > 0) {
      if (filteredSkills[0].indexOf("custom") !== -1) {
        setVideoSk(filteredSkills[0]);
      } else {
        setVideoSk(`custom_${filteredSkills[0]}`);
      }
    }

    if (skillData.type.indexOf("youtube") !== -1) {
      setVideoUrl(getVideoUrl({ skillData: skillData, skill: skill }).url);
      setSubtitleUrl(
        getVideoUrl({ skillData: skillData, skill: skill }).subtitleUrl
      );
    } else {
      const root = `https://videos.deltamath.com`;
      setVideoUrl(`${root}/${skill}/Default/HLS/${skill}.m3u8`);
      setSubtitleUrl(`${root}/captions/${skill}.mp4.vtt`);
    }
  }, [solveSkill, sk]);

  const updateVideoData = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/student/updateVideoProgress", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess(data: any) {
        /**
         * The old endpoint, /update_student_video_progress returned the updated assignment object, which was then used
         * to set the client side variable, thus making sure the values were in sync after each update.  They should
         * be the same already, but this seems like a good additional safety measure to include.
         */
        if (data) {
          /** update existing ref of student assignment data to match returned values */
          saRef.current = {
            ...saRef.current,
            grade: data.data.grade,
            actuallyComplete: data.data.actuallyComplete,
            complete: data.data.complete,
            video_data: {
              ...saRef.current.video_data,
              [videoSk]: {
                ...saRef?.current?.video_data?.[videoSk],
                ...data.data.video_data[videoSk],
              },
            },
          };
          if (data.data.newtest) {
            saRef.current = {
              ...saRef.current,
              newtest: data.data.newtest,
            };
          }

          /** update dmAssignment data to reflect new video progress data */
          dmAssignmentData[activeSection].filter(
            (assignment: any, index: number) => {
              if (assignment.ta._id === solveSkill.ta._id) {
                const assignmentObj = { ...dmAssignmentData };
                assignmentObj[activeSection][index].sa = {
                  ...assignmentObj[activeSection][index].sa,
                  ...saRef.current,
                };
                setDmAssignmentData({ ...assignmentObj });
              }
            }
          );
        }
        if (data?.data?.message) {
          console.log({ message: data.data.message });
          toastContext.addToast({
            status: "Error",
            message: "There was an issue saving your video progress.",
          });
        }
      },
      onError: (error: any) => {
        const errorStatus = error?.response?.status;

        if (
          (errorStatus === 409 || errorStatus === 422) &&
          !isLocked &&
          !isLti
        ) {
          assignmentsRefresh();
          navigate(
            `${REACT_APP_STUDENT_LINK}/${
              findNearestUpcoming(dmAssignmentData) ?? dmSectionsData[0]?._id
            }/upcoming`
          );

          setTimeout(() => {
            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: true,
              error: true,
              title: "Error",
              message: `${
                error?.response?.data?.message ||
                error?.message ||
                error?.error ||
                ""
              }`,
            }));
          }, 100);
        }
      },
    }
  );

  const updateWatchedTime = (pause?: boolean) => {
    if (!watchedTime) {
      return;
    }

    if (pause && !watchedTime.lastUpdated) {
      return watchedTime;
    }

    const currentDate = new Date();
    const newTime = watchedTime.lastUpdated
      ? watchedTime.time +
        differenceInMilliseconds(currentDate, watchedTime.lastUpdated)
      : watchedTime.time;
    setWatchedTime({
      time: newTime,
      lastUpdated: pause ? undefined : currentDate,
    });
    return {
      time: newTime,
      lastUpdated: pause ? undefined : currentDate,
    };
  };

  /**
   * update the video tracker with boolean values as the video is watched
   */
  const vidInterval = () => {
    if (
      saRef.current.video_data &&
      saRef.current.video_data[videoSk]?.tracker &&
      videoRef.current
    ) {
      const trackerKeys = Object.keys(
        saRef.current.video_data[videoSk].tracker
      );

      const now = Math.floor(videoRef.current.getCurrentTime() / 5) * 5;
      const realNow = now - 5; //the "5second" bucket we just completed (the one we want to update).

      const endOfVid = now === trackerKeys.length * 5;
      if ((now === 0 || seeking) && saRef.current.video_data[videoSk]) {
        setProgressGradient(
          getColorSpan(
            saRef.current.video_data[videoSk],
            videoRef.current.getCurrentTime(),
            videoRef.current.getDuration()
          )
        );
        return;
      }

      //current timestamp of the tracker bucket we might update in this iteration
      const trackerTime = realNow / (trackerKeys.length * 5);
      //current timestamp of the video
      const currentPercent =
        videoRef.current.getCurrentTime() / videoRef.current.getDuration();

      // This will ensure that a user does not get credit for time that they didn't watch
      if (seekedTo) {
        // we should now start showing the user they are starting to receive credit
        if (now / (trackerKeys.length * 5) >= seekedTo) {
          setProgressGradient(
            getColorSpan(
              saRef.current.video_data[videoSk],
              videoRef.current.getCurrentTime(),
              videoRef.current.getDuration()
            )
          );
        }

        if (trackerTime < seekedTo) {
          return;
        } else {
          setSeekedTo(undefined);
        }
      }

      const currentTracker = saRef.current.video_data[videoSk].tracker;
      // If we are not updating the tracker we do not need to continue through with this function
      if (
        currentPercent < trackerTime ||
        (endOfVid &&
          currentTracker[realNow] === true &&
          currentTracker[now] === true) ||
        (!endOfVid && currentTracker[realNow] === true)
      ) {
        setProgressGradient(
          getColorSpan(
            saRef.current.video_data[videoSk],
            videoRef.current.getCurrentTime(),
            videoRef.current.getDuration()
          )
        );
        return;
      }
      /**
       * only update a value in the tracker if we have reached the maximum edge of that bucket in the video,
       * and if we have reached the end of the video, update the last bucket as well as the one we just passed.
       */
      const newTracker: { [key: number]: boolean } =
        currentPercent >= trackerTime
          ? endOfVid
            ? {
                ...saRef.current.video_data[videoSk].tracker,
                [realNow]: true,
                [now]: true,
              }
            : {
                ...saRef.current.video_data[videoSk].tracker,
                [realNow]: true,
              }
          : { ...saRef.current.video_data[videoSk].tracker };

      saRef.current = {
        ...saRef.current,
        video_data: {
          ...saRef.current.video_data,
          [videoSk]: {
            ...saRef.current.video_data[videoSk],
            tracker: { ...newTracker },
          },
        },
      };
      setGrade(computeGradeFromTracker(newTracker) || 0);
      setProgressGradient(
        getColorSpan(
          {
            ...saRef.current.video_data[videoSk],
            tracker: { ...newTracker },
          },
          videoRef.current.getCurrentTime(),
          videoRef.current.getDuration()
        )
      );

      // if (computeGradeFromTracker(newTracker) === 100) {
      //   updateVideoData.mutate(JSON.stringify(prepareUpdatePayload()));
      // }
    }
  };

  const prepareUpdatePayload = () => {
    if (!timeStarted) {
      return {};
    }
    let denom = 0;
    let num = 0;

    if (!saRef.current.video_data) {
      saRef.current = {
        ...saRef.current,
        video_data: {
          [videoSk]: {
            grade: 0,
          },
        },
      };
    } else if (!saRef.current.video_data[videoSk]) {
      saRef.current = {
        ...saRef.current,
        video_data: {
          ...saRef.current.video_data,
          [videoSk]: {
            grade: 0,
          },
        },
      };
    }

    if (!saRef.current.video_data[videoSk]) {
      saRef.current.video_data[videoSk] = { grade: 0 };
    }
    if (saRef.current.video_data[videoSk].grade != 100) {
      for (const i in saRef.current.video_data[videoSk].tracker) {
        denom++;
        if (saRef.current.video_data[videoSk].tracker[i]) num++;
      }
    } else {
      num = 1;
      denom = 1;
    }

    const timing = updateWatchedTime(true);

    const timeWatched = new Date().getTime() - timeStarted; // duration since video started playing
    const videoDataTemp = saRef.current.video_data[videoSk];
    videoDataTemp.grade = Math.round((num / denom) * 100);
    // this will make the /updateVideoProgress request fail if the grade is 100

    const vd =
      videoDuration ||
      (videoRef.current ? videoRef.current.getDuration() : null);

    const progressPayload = {
      taId: solveSkill.ta._id /** The teacher assignment id. */,
      sk: sk /** The skill code which is indexing the ta.skills[sk] object. */,
      video_sk:
        videoSk /** The skill code of the video which is being watched. */,
      tracker:
        videoDataTemp.tracker /** The tracker object which tracks their progress in 5 second intervals. */,
      seconds:
        timing && timing.time !== null && timing.time !== undefined
          ? timing.time / 1000
          : timeWatched / 1000,
      // seconds:
      //   timeWatched /
      //   1000 /** The number of seconds watched **since the last post was made**. */,
      duration: vd /** The total duration of the video. */,

      last_edit: timeStarted,
    };

    if (timeWatched >= 1000) {
      // just do this where is IS NaN: example: custom_averageRateOfChange
      const duration = vd;
      // const seconds = timeWatched / 1000; /** The number of seconds watched **since the last post was made**. */
      const seconds =
        timing && timing.time !== null && timing.time !== undefined
          ? timing.time / 1000
          : timeWatched / 1000;
      // let original_sk;
      // if (isNaN(parseInt(sk.substring(6)))) {
      //   original_sk = sk.substring(7);
      // } else {
      //   original_sk = sk;
      // }
      // progressPayload.video_sk = original_sk;
      progressPayload.seconds = seconds;
      progressPayload.duration = duration;
    }

    return progressPayload;
  };

  const handlePause = (end?: boolean) => {
    const fullTime = videoRef.current
      ? videoRef.current.getCurrentTime() / videoRef.current.getDuration()
      : 0;

    // This will stop the double calls from happening if it's the end
    // the on pause and on end events are both called
    if (fullTime !== 1 || end) {
      setIsPlaying(false);
    }

    if (!timeStarted) {
      return;
    }
    if (videoRef.current) {
      setPlayed(
        videoRef.current.getCurrentTime() / videoRef.current.getDuration()
      );
    }
    vidInterval();

    if (played > 0) {
      setAlreadyWatched(played * videoDuration);
    }

    /**
     * THE USE EFFECT BELOW WILL UPDATE THE VIDEO
     */
    // updateVideoData.mutate(JSON.stringify(prepareUpdatePayload()));
  };

  useEffect(() => {
    // 👇️ run a function when the component unmounts 👇️
    return () => {
      if (isPlaying) {
        const payload = prepareUpdatePayload();
        if (!isEmpty(payload)) {
          updateVideoData.mutate(JSON.stringify(payload));
          // Get rid of all watched time so we send the correct new value
          setWatchedTime(undefined);
        }
      }
    };
  }, [isPlaying, isWindowClosing]);

  useEffect(() => {
    return () => {
      setAlreadyProcessed(undefined);
    };
  }, []);

  // If the window is closing or refreshing prompt user if the video is currently playing then pause video
  const windowClosing = (event: BeforeUnloadEvent) => {
    setIsWindowClosing(true);
    if (!isPlaying) {
      return;
    }
    event.preventDefault();

    vidInterval();
    const payload = prepareUpdatePayload();
    if (!isEmpty(payload)) {
      updateVideoData.mutate(JSON.stringify(payload));
    }
    event.returnValue = "onbeforeunload";
    setIsWindowClosing(false);
    return "onbeforeunload";
  };

  useBeforeUnload(windowClosing);

  useEffect(() => {
    /** save video progress if we navigate to another problem while the video is playing */
    if (isPlaying) {
      handlePause();
    }
    setWatchedTime(undefined);
  }, [currentLocation]);

  const handleSeekMouseDown = (e: any) => {
    setSeeking(true);
  };

  const handleSeekChange = (e: any) => {
    setSeekedTo(parseFloat(e.target.value));
    setPlayed(parseFloat(e.target.value));
    setProgressGradient(
      getColorSpan(
        saRef.current.video_data[videoSk],
        0,
        videoRef.current ? videoRef.current.getDuration() : 1
      )
    );
  };

  const handleSeekMouseUp = (e: any) => {
    setSeeking(false);
    if (videoRef?.current) {
      videoRef.current.seekTo(parseFloat(e.target.value));
    }
  };

  const handleReady = () => {
    if (videoRef.current) {
      videoRef.current.seekTo(alreadyWatched, "seconds");
    }
  };

  const handleStart = () => {
    setTimeStarted(new Date().getTime());
    if (videoRef.current) {
      setVideoDuration(videoRef.current.getDuration());
    }
  };

  const handleProgress = (state: any) => {
    if (!seeking) {
      setPlayed(
        videoRef.current
          ? videoRef.current.getCurrentTime() / videoRef.current.getDuration()
          : state.played
      );
      vidInterval();
    }
  };

  useEffect(() => {
    if (error) {
      setRestrictVideo(true);
    }
  }, [error]);

  const handlePlay = () => {
    setIsPlaying(true);
    if (!watchedTime) {
      setWatchedTime({
        time: 0,
        lastUpdated: new Date(),
      });
    } else {
      updateWatchedTime();
    }
    // If jwt is locked to different taId then we restrict the video
    // by just setting url to undefined in react player
    if (
      decodedJwt &&
      decodedJwt.data &&
      decodedJwt.data.lock &&
      decodedJwt.data.lock.taId !== solveSkill.ta._id
    ) {
      setRestrictVideo(true);
    } else {
      /** 8/11/23 Zach asked to remove the check locked on ever play to limit backend calls before release */
      // Refetch on play to check if student is locked again
      // refetch();
    }

    setTimeStarted(new Date().getTime());
    const duration = videoRef.current?.getDuration();

    if (!saRef.current || !duration) {
      console.log("no assignment found");
      return;
    }

    if (!saRef.current.video_data) {
      saRef.current = {
        ...saRef.current,
        video_data: {
          [videoSk]: {
            grade: 0,
          },
        },
      };
    } else if (!saRef.current.video_data[videoSk]) {
      saRef.current = {
        ...saRef.current,
        video_data: {
          ...saRef.current.video_data,
          [videoSk]: {
            grade: 0,
          },
        },
      };
    }

    if (!saRef.current.video_data[videoSk]) {
      saRef.current.video_data[videoSk] = { grade: 0 };
    }
    if (!saRef.current.video_data[videoSk].tracker) {
      if (
        solveSkill.sa &&
        solveSkill.sa.video_data &&
        solveSkill.sa.video_data[videoSk]
      ) {
        saRef.current.video_data[videoSk] = solveSkill.sa.video_data[videoSk];
        if (saRef.current.video_data[videoSk].tracker) {
          return;
        }
      }

      // If we already have a grade of 100 we just create the tracker will all true values, otherwise we fill with false if it's not defined
      const trackerDefaultValue =
        saRef.current.video_data[videoSk].grade === 100;
      const newTracker: { [key: number]: boolean } = { 0: trackerDefaultValue };
      for (let sec = 5; sec < duration - 5; sec += 5) {
        newTracker[sec] = trackerDefaultValue;
      }
      saRef.current = {
        ...saRef.current,
        video_data: {
          ...saRef.current.video_data,
          [videoSk]: {
            ...saRef.current.video_data[videoSk],
            tracker: { ...newTracker },
          },
        },
      };
    }
  };

  const handlePipOpen = () => {
    setPipOpen(true);
  };
  const handlePipClose = () => {
    setPipOpen(false);
  };

  document.querySelector("video") &&
    document
      .querySelector("video")
      ?.addEventListener("enterpictureinpicture", () => setPipOpen(true));
  document.querySelector("video") &&
    document
      .querySelector("video")
      ?.addEventListener("leavepictureinpicture", () => setPipOpen(false));

  const [mouseInHouse, setMouseInHouse] = useState(true);
  const [hoverInterval, setHoverInterval] = useState(true);

  const handleMouseInHouse = () => {
    setMouseInHouse(true);

    if (hoverInterval) {
      setHoverInterval(false);
      setTimeout(() => {
        /** this causes a brief thrash when the interval runs out.  Is there a better way of handing this? */
        setMouseInHouse(false);
        setHoverInterval(true);
        /** does not quite align with native timeout because this gets reset onMouseMove */
      }, 3500);
    }
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      {showInModal ? (
        <FocusTrap paused={pipOpen}>
          <div
            id="video-modal-container"
            className={clsx(
              pipOpen ? "hidden" : "block",
              "fixed inset-0 z-50 overflow-y-auto"
            )}
            onMouseMove={() => handleMouseInHouse()}
            onMouseLeave={() => setMouseInHouse(false)}
          >
            {!pipOpen && (
              <div
                className={clsx("fixed inset-0 block bg-black bg-opacity-60")}
              />
            )}
            <div className="flex h-screen max-h-screen flex-col justify-center overflow-hidden p-4 sm:p-8">
              <div className="mx-auto flex w-full max-w-5xl transform flex-col overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div
                  className={clsx(
                    mouseInHouse || !isPlaying
                      ? "block bg-gradient-to-b from-black"
                      : "hidden",
                    "absolute z-50 h-1/4 w-full from-black transition delay-300 ease-in-out sm:h-1/6"
                  )}
                >
                  <button
                    type="button"
                    className="absolute left-2 top-2 rounded-md text-white focus:outline-dm-brand-blue-500 focus:ring-offset-2 sm:left-6 sm:top-6"
                    onClick={() => {
                      handlePause();
                      setShowVideo(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    className="absolute right-2 top-2 rounded-md focus:outline-dm-brand-blue-500 focus:ring-offset-2 sm:right-6 sm:top-6"
                    onClick={() => {
                      setPipOpen(true);
                    }}
                  >
                    <span className="sr-only">Picture in picture</span>
                    <PictureInPictureIcon classes="h-8 w-8" />
                  </button>
                </div>
                <div
                  id="video-modal"
                  className="relative grow overflow-y-auto bg-white"
                  aria-live="polite"
                >
                  <div className="text-sm" id="foo">
                    {videoUrl && userContext.getJWT() && (
                      <ReactPlayer
                        playing={isPlaying}
                        ref={videoRef}
                        url={!restrictVideo ? videoUrl : undefined}
                        controls={true}
                        style={{
                          margin: "0 auto auto",
                          maxWidth: "100%",
                        }}
                        height="100%"
                        width="100%"
                        onReady={() => {
                          if (!isSafari) {
                            handleReady();
                          }
                        }}
                        onPlay={handlePlay}
                        onPause={() => handlePause()}
                        onEnded={() => handlePause(true)}
                        onStart={handleStart}
                        onProgress={handleProgress}
                        onEnablePIP={handlePipOpen}
                        onDisablePIP={handlePipClose}
                        pip={pipOpen}
                        config={{
                          file: {
                            hlsOptions: {
                              forceHLS: true,
                              maxMaxBufferLength: 30,
                            },
                            attributes: { crossOrigin: "anonymous" },
                            tracks: [
                              {
                                label: "English",
                                kind: "captions",
                                src: subtitleUrl,
                                srcLang: "en",
                                default: false,
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FocusTrap>
      ) : (
        <Transition
          as="div"
          show={true}
          appear={true}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div
            id="assigned-video"
            className={clsx(
              showProgress ? "p-9" : "",
              "w-full rounded-lg bg-white shadow-md"
            )}
          >
            {skillData && skillData.isVideo && (
              <div className="mb-2">
                <h1 className="font-serif text-lg">Video Lesson</h1>
                <span className="text-[14px]">
                  Watch this video to complete the section.
                </span>
              </div>
            )}
            {videoUrl && userContext.getJWT() && (
              <ReactPlayer
                ref={videoRef}
                playing={isPlaying}
                url={!restrictVideo ? videoUrl : undefined}
                controls={true}
                style={{
                  margin: "0 auto auto",
                  maxWidth: "100%",
                }}
                height="100%"
                width="100%"
                onPlay={handlePlay}
                onPause={() => handlePause()}
                onEnded={() => handlePause(true)}
                onStart={handleStart}
                onProgress={handleProgress}
                pip={true}
                config={{
                  file: {
                    hlsOptions: {
                      forceHLS: true,
                    },
                    attributes: { crossOrigin: "anonymous" },
                    tracks: [
                      {
                        label: "English",
                        kind: "captions",
                        src: subtitleUrl,
                        srcLang: "en",
                        default: false,
                      },
                    ],
                  },
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      rel: 0,
                    },
                  },
                }}
              />
            )}
            <input
              type="range"
              value={played}
              min={0}
              max={0.999999}
              step="any"
              /** use pointer events to capture both/either "onTouch" & "onMouse" events */
              onPointerDown={handleSeekMouseDown}
              onPointerUp={handleSeekMouseUp}
              onChange={handleSeekChange}
              style={{
                background: progressGradient,
              }}
              className="h-2 w-full"
            />
            {/** s-desktop-h2 lato  */}
            <span className="font-sans text-sm font-bold text-dm-charcoal-800">
              Complete: {grade}%
            </span>
          </div>
        </Transition>
      )}
    </>
  );
};

export default HelpVideo;

/**
 * Compute the grade on the assignment from the provided tracker.
 * @param tracker object indicating which 5 second increments of the video have been watched.
 */
function computeGradeFromTracker(tracker: Record<number, boolean>) {
  let count = 0;
  let countTrue = 0;
  for (const key in tracker) {
    count += 1;
    if (tracker[key]) {
      countTrue += 1;
    }
  }
  return Math.round((countTrue / count) * 100);
}

/**
 * Constructs the background-color value for the video scrub bar.
 * @param data video_data from student assignment object
 * @returns a string color coded to represent the sections of a video that a student has watched
 */
function getColorSpan(
  data: { tracker: any; grade: number },
  currentPosition: number,
  duration: number
) {
  if (data && data.grade === 100) {
    return GREEN;
  } else if (!data || !data.tracker) {
    return BLUE;
  }

  const trackerKeys = Object.keys(data.tracker);
  // Getting the decimal value so we dont get off by due to the last interval not being a full 5 seconds
  const totalTimeKeysDecimal = duration / 5;

  const array: {
    width: number;
    color: boolean;
  }[] = compact(
    trackerKeys.flatMap((key, i) => {
      const keyNumber = Number(key);

      // We dont need to do anything for the first number because we are only updated after the color changes
      if (keyNumber === 0) {
        if (currentPosition === 0) {
          return undefined;
        } else if (
          trackerKeys.length > 1 &&
          1 / totalTimeKeysDecimal > currentPosition / duration &&
          trackerKeys.every((x) => data.tracker[x] === false)
        ) {
          return {
            width: currentPosition / duration,
            color: GREEN,
          };
        }
        return;
      }
      // Last element in the array always gets that the color should be at the end of the video
      else if (i === trackerKeys.length - 1 && data.tracker[key]) {
        return {
          width: 1,
          color: data.tracker[key],
        };
      }

      const keyWidth = i / totalTimeKeysDecimal;
      const nextKeyWidth = (i + 1) / totalTimeKeysDecimal;
      const currentWidth = currentPosition / duration;
      // If we are in the middle of an interval we want to use where the scrubber is to give the student an element of progress

      const widthToUse =
        currentWidth > keyWidth && currentWidth <= nextKeyWidth
          ? currentWidth
          : keyWidth;
      // If the current time interval is different than the previous interval we want to update the color
      if (data.tracker[key] !== data.tracker[(keyNumber - 5).toString()]) {
        return {
          width: widthToUse,
          color: data.tracker[(keyNumber - 5).toString()],
        };
      }
      // if we are currently watching in the middle of the interval and both previous and current intervals are blue then we want to show
      // the user is gaining credit inside this interval
      // THIS SHOULD ONLY HAPPEN AFTER SEEKING we are not calling this function if the user is not getting credit
      else if (
        widthToUse === currentWidth &&
        data.tracker[key] === false &&
        data.tracker[(keyNumber - 5).toString()] === false
      ) {
        return [
          {
            width: keyWidth,
            color: false,
          },
          {
            width: widthToUse,
            color: true,
          },
        ];
      }
    })
  );

  // Weird logic to create the linear gradient
  const results = `linear-gradient(90deg, ${array
    .map((arr) => {
      return `${arr.color ? GREEN : BLUE} ${arr.width * 100}%,  ${
        arr.color ? BLUE : GREEN
      } ${arr.width * 100}%
    `;
    })
    .join(", ")})`;

  return results;
}

// should import this from @backend-types
export interface IStudentAssignment {
  _id: number;
  student_id: number | string;
  teacher_id: number;
  teachercode: number;
  term: string;
  complete: number;
  grade: number;
  actuallyComplete: number;
  grades: number[];
  completes: number[];
  test: number;
  newtest: number;
  notes: boolean;
  first_action: number;
  last_action: number;
  last_improvement: number;
  time_completed: number;
  time_started: number;
  end_time: number;
  ended_early: boolean;
  solvedTestProblems: {
    [key: string]: number;
  };
  data: {
    [key: string]: any;
  };
  testdata: {
    [key: string]: number;
  };
  video_data: {
    [key: string]: any;
  };
  order?: string[];
  skills: {
    [key: string]: {
      required: number;
    };
  };
  extra: number;
  additional_minutes: number;
  additional_minutes_started: number;
  solutions_seen?: boolean;
  student_has_courses?: boolean; // can be attached in check_answer... not part of model
  delayAssignment?: boolean;
  lti_grade_passback?: {
    passback_timestamp?: number;
    lti_auth_req?: any;
    failure_timestamp?: number;
    success_timestamp?: number;
    passback_attempts?: number;
    grade?: number;
  };
  google_classroom_grade_passback?: any;
  resource_link_id?: string;
  solutionsAvailable?: boolean; // tacked on to object before sending to frontend
  sid: any;
}

const getVideoUrl = ({
  skillData,
  skill,
}: {
  skillData: SkillDataType;
  skill: string;
}) => {
  let url = "";
  let subtitleUrl = "";

  if (skill.substring(0, 6) === "custom") {
    skill = skill.substring(7);
  }
  if (skillData.type === "youtube_video") {
    url = `https://www.youtube.com/watch?v=${skillData.video}`;
  } else if (skillData.type === "dm_video") {
    const root = `https://videos.deltamath.com`;
    url = `${root}/${skill}/Default/HLS/${skill}.m3u8`;
    subtitleUrl = `${root}/captions/${skill}.mp4.vtt`;
  }

  return { url, subtitleUrl };
};

const Duration = ({ seconds }: { seconds: number }) => {
  return <time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</time>;
};

function format(seconds: number) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

function pad(val: any) {
  return ("0" + val).slice(-2);
}
