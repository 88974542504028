import { Dispatch, SetStateAction, memo, useContext } from "react";
import { MenuIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { REACT_APP_STUDENT_LINK, deltamathLogo } from "../../utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { skillDataDisplay, skillToSolve } from "../utils";
import { SkillDataType } from "../_constants";

type Props = {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

const NavBar = ({ setSidebarOpen }: Props): JSX.Element => {
  const { activeSection, dmAssignmentData } = useContext(
    StudentSectionsContext
  );

  let remainingSkills = 0;

  const solveSkill: any =
    dmAssignmentData && activeSection && dmAssignmentData[activeSection]
      ? skillToSolve(activeSection, dmAssignmentData)
      : undefined;

  if (solveSkill && solveSkill?.ta) {
    const order = solveSkill?.ta?.order;
    if (order && order.length > 0) {
      remainingSkills = order.length;
      order.map((skill: string) => {
        const skillData: SkillDataType = skillDataDisplay(skill, solveSkill);
        if (skillData.isCompleted || skillData.percentCompleted === 100) {
          remainingSkills -= 1;
        }
      });
    }
  }

  return (
    <nav className="z-[39] border-b border-dm-charcoal-100 bg-white px-3 sm:px-6 lg:hidden">
      <div className="relative flex h-14 items-center justify-between">
        <button
          type="button"
          className="mr-6 text-white focus:outline-none"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" color="#9CA3AF" />
        </button>
        <div className="text-sm font-bold leading-normal text-dm-brand-blue-500">
          {solveSkill?.ta && (
            <>
              {solveSkill?.ta?.is_test ? <>Test</> : <>Assignment</>} Overview
              {remainingSkills > 0 && (
                <div className="ml-2 inline-flex items-center justify-start gap-2 rounded-full border bg-dm-brand-blue-100 px-2 py-0.5">
                  <div className="text-sm font-normal leading-tight text-dm-charcoal-800">
                    {remainingSkills}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex flex-1 justify-end">
          <Link to={`${REACT_APP_STUDENT_LINK}`}>
            <img
              className="block h-6 w-auto"
              src={deltamathLogo}
              alt="DeltaMath Home"
            />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default memo(NavBar);
