import clsx from "clsx";
import ImpersonationButton from "./ImpersonateButton";
import { useParentContext } from "../../contexts/ParentContext";
import { getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";

type Props = {
  className?: string;
  showEmptyState?: boolean;
};

export const LaunchLearnerCard: React.FC<Props> = (props) => {
  const { learners } = useParentContext();

  if (learners.length === 0) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        "relative flex w-full flex-col gap-x-8 gap-y-5 rounded-xl border border-dm-charcoal-100 bg-white p-6 md:flex-row",
        props.className
      )}
    >
      <ResponsiveImage
        className="w-[247px] self-center md:self-start"
        srcs={[
          getFilePath("/images/learner/dashboard/impersonation-card.png"),
          getFilePath("/images/learner/dashboard/impersonation-card@2x.png"),
        ]}
        alt=""
        aria-hidden="true"
      />
      <div className="flex flex-col justify-center">
        <h2 className="mb-1 font-serif text-lg font-bold leading-snug text-dm-gray-800">
          {props.showEmptyState
            ? "Excited to get started?"
            : "Interested in exploring your learner's DeltaMath experience?"}
        </h2>
        <p className="mb-4 text-sm text-dm-gray-800 md:leading-7">
          {props.showEmptyState ? (
            <>
              Encourage your student to start their new course or step into your
              student&apos;s course to uncover the range of questions, quizzes,
              challenges, and prizes they&apos;ll discover.
            </>
          ) : (
            <>
              Get a detailed look at your student&rsquo;s progress—courses,
              practice, quizzes, and achievements. See the platform exactly as
              they do to better understand their learning journey and identify
              areas where they might need extra support.
            </>
          )}
        </p>
        <ImpersonationButton
          childIdToImpersonate={learners[0]._id}
          childName={learners[0].first}
        />
      </div>
    </div>
  );
};
