import { useEffect } from "react";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { Course, Unit } from "../../types";
import Accordion from "../Accordion";
import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { DMPieChart } from "../../../student/components/generic/DMPieChart";
import { getUnitProgressAriaLabel } from "../../utils/index";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";
import { UnitSection } from "./UnitSection";

type Props = {
  course: Course;
  unit: Unit;
  unitNumber: number;
  cardId: string;
  lastClickedId?: string;
};

const UnitSummaryCard = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.lastClickedId === props.cardId) {
      setIsOpen(true);
    }
  }, [props.cardId, props.lastClickedId]);
  const learnerContext = useLearnerContext();

  const courseProgress = learnerContext.getProgress(props.unit.courseId);
  const unitProgress = courseProgress?.units.find(
    (u) => u.unitId === props.unit.id
  );

  const subunitsProgress = props.unit.subunits?.map((s) => {
    const subunitProg = unitProgress?.subunits.find(
      (sub) => s.id === sub.subunitId
    );
    return {
      id: s.id,
      progress:
        subunitProg && subunitProg?.progress === 0 && !!subunitProg.preQuiz
          ? 0.01
          : subunitProg?.progress || 0,
    };
  });

  const isCurrentTestSubmitted =
    unitProgress?.unitTest !== undefined &&
    unitProgress?.unitTest?.assignment?.submitted !== undefined;
  const isFirstAttemptComplete =
    isCurrentTestSubmitted || !!unitProgress?.unitTest?.assignment?.resultHist;

  // Following progress logic from courseContext.getCurrentLearnerCourses
  const progressForUnitTest = isFirstAttemptComplete
    ? 1
    : !!unitProgress?.unitTest && unitProgress?.unitTest?.progress === 0
    ? 0.01
    : unitProgress?.unitTest?.progress || 0;

  const segments = [
    ...props.unit.subunitOrder.map((suo) => {
      const subunitProgress = unitProgress?.subunits?.find(
        (s) => s.subunitId === suo
      );

      const progress =
        subunitProgress &&
        subunitProgress?.progress === 0 &&
        !!subunitProgress.preQuiz
          ? 0.01
          : subunitProgress?.progress || 0;

      return {
        value: 1,
        color:
          progress === 0 ? "#E3E8EF" : progress === 1 ? "#2F52E9" : "#A9CCFA",
      };
    }),
    {
      value: 1,
      color:
        progressForUnitTest === 0
          ? "#E3E8EF"
          : progressForUnitTest === 1
          ? "#2F52E9"
          : "#A9CCFA",
    },
  ];

  const unitProgressValue = Math.round((unitProgress?.progress || 0) * 100);

  return (
    <>
      <Accordion
        id={props.cardId}
        wrapperClasses="!mb-0"
        buttonClasses="!px-4 md:!px-6 !py-4"
        titleButton={
          <div className="flex gap-2">
            <div className="relative flex h-8 w-8 shrink-0 transition-all">
              {unitProgressValue !== 100 ? (
                <DMPieChart
                  data={segments}
                  lineWidth={21}
                  paddingAngle={10}
                  totalValue={segments.length}
                  startAngle={273}
                  className="pointer-events-none absolute"
                  ariaLabel={getUnitProgressAriaLabel(subunitsProgress)}
                />
              ) : (
                <i
                  className="far fa-check-circle text-[32px] leading-none !text-dm-brand-blue-500"
                  aria-hidden="true"
                ></i>
              )}
            </div>
            <div>
              <div className="flex items-center gap-2">
                <Button
                  type="link"
                  className="cursor-pointer !py-0 font-sans text-base font-bold leading-tight text-dm-charcoal-800 md:text-lg"
                  href={`${REACT_APP_LEARNER_LINK}/${props.course.path}/${props.unit.path}`}
                >
                  Unit {props.unitNumber}: {props.unit.name}
                </Button>
                {unitProgressValue > 0 && (
                  <span className="rounded-full bg-dm-brand-blue-500 px-2 py-1 font-sans text-xs font-normal leading-none text-white">
                    {`${unitProgressValue}% complete`}
                  </span>
                )}
              </div>
              <p className="font-sans text-sm font-normal">
                {segments.length - 1} Sections
              </p>
            </div>
          </div>
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        panel={
          <div
            className={`!max-md:grid-cols-1 mb-6 mt-2 grid gap-6 px-6 md:grid-flow-col-dense md:grid-cols-2 md:grid-rows-2`}
            style={{
              gridTemplateRows: `repeat(${Math.ceil(
                (props.unit.subunitOrder.length + 1) / 2
              )}, minmax(0, 1fr))`,
            }}
          >
            {props.unit.subunitOrder.map((suo, i) => {
              const subunit = props.unit.subunits?.find((s) => s.id === suo);

              if (!subunit) {
                return null;
              }

              return (
                <UnitSection
                  key={`sectionSkills-${subunit.id}-${i}`}
                  subunit={subunit}
                  subunitProgresses={unitProgress?.subunits}
                  subunitID={suo}
                  sectionNumber={i + 1}
                  href={`${REACT_APP_LEARNER_LINK}/${props.course.path}/${props.unit.path}/${subunit.path}`}
                  isOpen={isOpen}
                />
              );
            })}
            <div className="flex items-center gap-1">
              <ResponsiveImage
                className={`inline h-auto w-10 ${
                  unitProgress?.unitTest?.submitted === undefined && "pb-3"
                }`}
                srcs={[
                  getFilePath(
                    `/images/learner/unittest/${
                      unitProgress?.unitTest?.submitted === undefined
                        ? "unit-test.png"
                        : "unit-test-complete.png"
                    }`
                  ),
                ]}
                alt=""
                aria-hidden="true"
              />
              <Button
                type="link"
                className={`!py-0 text-sm font-bold text-dm-charcoal-800 ${
                  !unitProgress?.unitTest
                    ? "cursor-default hover:text-dm-charcoal-800"
                    : ""
                }`}
                href={
                  unitProgress?.unitTest
                    ? `${REACT_APP_LEARNER_LINK}/${props.course.path}/${props.unit.path}/unittest`
                    : undefined
                }
              >
                {`Unit ${props.unitNumber} Test`}
              </Button>
              {unitProgress?.unitTest ? (
                unitProgress.unitTest.submitted ? (
                  <div className="text-sm font-bold">
                    {`Score ${Math.round(
                      unitProgress.unitTest.maxGrade * 100
                    )}%`}
                    <i
                      className="far fa-check-circle fa-fw ml-1 leading-none !text-dm-brand-blue-500"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : (
                  <>
                    <p className="text-xs">In Progress</p>
                    {unitProgress.unitTest.progress > 0 && (
                      <div>
                        <span className="rounded-full bg-dm-brand-blue-500 px-2 py-1 font-sans text-xs font-medium leading-none text-white">
                          {`${Math.round(
                            unitProgress.unitTest.progress * 100
                          )}% complete`}
                        </span>
                      </div>
                    )}
                  </>
                )
              ) : (
                <p className="text-xs">Not Started</p>
              )}
            </div>
          </div>
        }
        isSummaryPage
      />
    </>
  );
};

export default UnitSummaryCard;
