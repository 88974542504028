import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import md5 from "md5";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import { getAdminId } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useUserContext } from "../../../shared/contexts/UserContext";
import {
  PASSWORD_MUST_MATCH,
  PasswordErrorMessages,
  evaluatePassword,
} from "../../../utils";

export default function ChangePassword({
  setShowChangePassword,
}: {
  setShowChangePassword: any;
}) {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();

  const token = userContext.getJWT();
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordPayload, setPasswordPayload] = useState({
    pass: "",
    newPass: "",
    _id: getAdminId(),
  });
  const [newPasswordError, setNewPasswordError] = useState("");
  const cancelButtonRef = useRef(null);

  const updateAdminPassword = useMutation(
    (body: string) => {
      return axios.post(
        deltamathAPI() + "/admin_new/manage/update_admin_password",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess(data: any) {
        if (data?.data?.success) {
          toastContext.addToast({
            status: "Success",
            message: "Password successfully updated!",
          });
          setShowChangePassword(false);
        } else if (data?.data?.message) {
          toastContext.addToast({
            status: "Error",
            message: data?.data?.message,
          });
        }
      },
    }
  );

  useEffect(() => {
    setNewPasswordError("");
    if (passwordPayload.newPass.length > 0 || passwordConfirmation.length > 0) {
      if (passwordConfirmation !== passwordPayload.newPass) {
        setNewPasswordError(PASSWORD_MUST_MATCH);
      }
    } else {
      setNewPasswordError("");
    }
  }, [passwordConfirmation, passwordPayload]);

  return (
    <>
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Change Password
                      </Dialog.Title>
                      <div className="mt-2">
                        <form className="space-y-6">
                          <div>
                            <label
                              htmlFor="old-password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Current Password
                            </label>
                            <div className="mt-1">
                              <input
                                id="old-password"
                                name="old-password"
                                type="password"
                                autoComplete="current-password"
                                required
                                onChange={(e) => {
                                  setPasswordPayload({
                                    ...passwordPayload,
                                    pass: e.target.value,
                                  });
                                }}
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div>
                            <PasswordErrorMessages
                              passwordValue={passwordPayload.newPass}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="new-password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              New Password
                            </label>
                            <div className="mt-1">
                              <input
                                id="new-password"
                                name="new-password"
                                type="password"
                                onChange={(e) => {
                                  setPasswordPayload({
                                    ...passwordPayload,
                                    newPass: e.target.value,
                                  });
                                }}
                                required
                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="confirm-password"
                                className="block pt-2 text-sm font-medium text-gray-700"
                              >
                                Confirm Password
                              </label>
                              <div className="mt-1">
                                <input
                                  id="confirm-password"
                                  name="confirm-password"
                                  type="password"
                                  autoComplete="current-password"
                                  required
                                  onChange={(e) => {
                                    setPasswordConfirmation(e.target.value);
                                  }}
                                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div className="text-red-500">
                              {newPasswordError && newPasswordError}
                              {newPasswordError.includes(
                                "Your password must contain 3 of the following: "
                              ) && (
                                <ul className="ml-2">
                                  <li>- Uppercase</li>
                                  <li>- Lowercase</li>
                                  <li>- Number</li>
                                  <li>- Special character</li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      disabled={
                        !!newPasswordError ||
                        !passwordPayload.pass ||
                        !passwordPayload.newPass
                      }
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        updateAdminPassword.mutate(
                          JSON.stringify({
                            ...passwordPayload,
                            pass: md5("deltamath" + passwordPayload.pass),
                            newPass: md5("deltamath" + passwordPayload.newPass),
                            meets_criteria: evaluatePassword(
                              passwordPayload.newPass
                            ),
                          })
                        );
                      }}
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setShowChangePassword(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
