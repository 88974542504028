import { useNavigate } from "react-router-dom";
import Button from "../../../../student/components/generic/button";
import SkipPreQuiz from "./SkipPreQuiz";
import { useCreateAssignmentMutation } from "../../../utils";
import { ResponsiveImage } from "../../ResponsiveImage";
import { REACT_APP_LEARNER_LINK, getFilePath } from "../../../../utils";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { skipPreQuizEvent } from "../../../analytics/events";

type Props = {
  courseId: string;
  unitId: string;
  subunitId: string;
  coursePath?: string;
  unitPath?: string;
  subunitPath?: string;
  skillsCount: number | undefined;
  estimatedTime: number;
};

const PreQuizLanding = (props: Props) => {
  const navigate = useNavigate();
  const createAssignmentMutation = useCreateAssignmentMutation();
  const { track, getAssignmentData } = useLearnerAnalytics();

  const initializeProgressForCourse = () => {
    createAssignmentMutation(
      "preQuiz",
      props.courseId,
      props.unitId,
      props.subunitId
    );
  };

  const submitCallback = () => {
    track(
      skipPreQuizEvent({
        ...getAssignmentData("preQuiz"),
        numCompleted: 0,
      })
    );
    navigate(
      `${REACT_APP_LEARNER_LINK}/${props.coursePath}/${props.unitPath}/${props.subunitPath}/prequiz`
    );
  };

  return (
    <div className="flex flex-grow flex-col items-center justify-center rounded-lg border border-dm-charcoal-100 bg-white px-4 py-6 max-sm:m-4">
      <ResponsiveImage
        className="inline h-auto w-52"
        srcs={[
          getFilePath("/images/learner/prequiz/start-prequiz.png"),
          getFilePath("/images/learner/prequiz/start-prequiz@2x.png"),
        ]}
        alt="Take the Pre-Quiz"
        aria-hidden="true"
      />
      <h4 className="mx-3 mt-8 font-serif text-2xl font-bold">
        Ready to start your pre-quiz?
      </h4>
      <p className="mx-3 mt-2">
        This quiz will help personalize your practice questions and get you
        ready to complete the section.
      </p>
      {!!props.skillsCount && (
        <p className="mt-2 font-bold">
          {props.skillsCount} Questions | {props.estimatedTime} Minutes
          (estimated)
        </p>
      )}
      <div className="flex flex-col items-center justify-center gap-y-4 pt-6">
        <Button onClick={initializeProgressForCourse}>Start Pre-Quiz</Button>
        <SkipPreQuiz
          courseId={props.courseId}
          unitId={props.unitId}
          subunitId={props.subunitId}
          callback={() => submitCallback()}
        />
      </div>
    </div>
  );
};

export default PreQuizLanding;
