import React, { useContext, useEffect, useState, ReactElement } from "react";
import { REACT_APP_LEARNER_LINK, useDMQuery } from "../../utils";
import {
  ActiveLearnerResponse,
  CheckAnswerResponse,
  Learner,
  LearnerAssignment,
  LearnerAssignmentTypes,
  Progress,
  SkipPreQuizResponse,
  SubmitAssignmentResponse,
  SubunitAssignmentTypes,
  SubunitProgress,
  PrizeKey,
} from "../types";
import { useMatch, useNavigate } from "react-router-dom";
import { compact } from "lodash";
import { useCourseContext } from "./CourseContext";
import { addPointsToLearner } from "../utils/addPointsToLearner";
import { useUserContext } from "../../shared/contexts/UserContext";
import { ACTIVE_LEARNER_STALE_TIME } from "../constants";

const PROGRESS_LOADING_DELAY = 500;

interface LearnerApi {
  getProgress(courseId: string): Progress | undefined;
  setProgress(data: Progress[]): void;
  logoutLearner(): void;
  isPrizeUnlocked(prizeId: PrizeKey): boolean;
  setLearnerAssignment(
    courseId: string,
    assignmentId: LearnerAssignment,
    assignmentType: SubunitAssignmentTypes,
    unitId?: string,
    subunitId?: string
  ): Progress | undefined;
  skipPreQuiz(response: SkipPreQuizResponse): void;
  updateAssignmentAndProgress(response: SubmitAssignmentResponse): void;
  setIsSidebarMinimized(isSidebarMinimized: boolean): void;
  isStaff(): boolean;
}

type LearnerState = {
  progresses: Progress[];
  currentProblemSolved: boolean;
  isSidebarMinimized: boolean;
  learner: Learner | undefined;
  isLoadingProgress: boolean;
  isLoadingAssignment: boolean;
  currentLearner: string | undefined;
};

const SIDEBAR_MINIMIZED_KEY = "dm_sidebar_min";

export class LearnerHolder implements LearnerApi {
  readonly state: LearnerState;
  private setState: (state: Partial<LearnerState>) => void;
  private logout: () => void;
  private clearAndRefetchLearner: () => void;
  private setAssignmentIdsToLoad: (ids: string[]) => void;

  setParentCurrentLearner(learnerId: string | undefined) {
    this.setState({
      currentLearner: learnerId,
    });
  }

  loadAssignmentsById(assignmentIds: string[]) {
    if (assignmentIds.length === 0) {
      return;
    }

    this.setAssignmentIdsToLoad(assignmentIds);
  }

  getProgress(courseId: string): Progress | undefined {
    return this.state.progresses.find((p) => p.courseId === courseId);
  }

  setProgress(data: Progress[]) {
    this.setState({
      progresses: data,
    });
  }

  logoutLearner() {
    this.logout();
  }

  getLearnerLevel() {
    return this.state.learner?.level || "easy";
  }

  isPrizeUnlocked(prizeId: PrizeKey): boolean {
    return this.state.learner?.prizes?.includes(prizeId) || false;
  }

  isStaff() {
    return (this.state.learner?.email || "").includes("@deltamath.com");
  }

  skipPreQuiz({ assignment, progress, pointsEarned }: SkipPreQuizResponse) {
    if (assignment) {
      const unit = progress.units.find((u) => u.unitId === assignment.unitId);
      if (unit) {
        const subunit = unit.subunits.find(
          (s) => s.subunitId === assignment.subunitId
        );
        if (subunit && subunit.preQuiz) {
          subunit.preQuiz.assignment = assignment;
        }
      }
    }

    const newProgress = [
      ...this.state.progresses.filter((p) => p._id !== progress._id),
      progress,
    ];
    this.setState({
      progresses: newProgress,
      learner: addPointsToLearner(this.state.learner, pointsEarned),
    });
  }

  updateAssignmentAndProgress(response: SubmitAssignmentResponse) {
    const courseProgress = this.state.progresses.find(
      (p) => p.courseId === response.assignment.courseId
    );

    if (!courseProgress) {
      return;
    }

    if (
      response.assignment.type === "courseTest" &&
      courseProgress.courseTest
    ) {
      courseProgress.courseTest.assignment = response.assignment;
      courseProgress.courseTest.maxGrade = Math.max(
        courseProgress.courseTest.maxGrade,
        response.assignment.grade
      );
      courseProgress.courseTest.progress = response.assignment.progress;
      courseProgress.courseTest.submitted = response.assignment.submitted;
      if (response.progress) {
        courseProgress.progress = response.progress.progress;
      }
    }

    const unit = courseProgress.units.find(
      (u) => u.unitId === response.assignment.unitId
    );
    if (unit) {
      if (response.assignment.type === "unitTest" && unit.unitTest) {
        unit.unitTest.maxGrade = Math.max(
          unit.unitTest.maxGrade,
          response.assignment.grade
        );
        unit.unitTest.assignment = response.assignment;
        unit.unitTest.progress = response.assignment.progress;
        unit.unitTest.submitted = response.assignment.submitted;
        if (response.progress) {
          courseProgress.progress = response.progress.progress;
          const unitProgress = response.progress.units.find(
            (u) => u.unitId === unit.unitTest?.assignment?.unitId
          );
          unit.progress = unitProgress?.progress || unit.progress;
        }
      }

      const subunit = unit
        ? unit.subunits.find(
            (s) => s.subunitId === response.assignment.subunitId
          )
        : undefined;
      if (subunit) {
        if (response.assignment.type === "preQuiz" && subunit.preQuiz) {
          subunit.preQuiz.assignment = response.assignment;
          subunit.preQuiz.maxGrade = Math.max(
            subunit.preQuiz.maxGrade,
            response.assignment.grade
          );
          subunit.preQuiz.progress = response.assignment.progress;
          subunit.preQuiz.submitted = response.assignment.submitted;
        } else if (
          response.assignment.type === "postQuiz" &&
          subunit.postQuiz
        ) {
          subunit.postQuiz.assignment = response.assignment;
          subunit.postQuiz.maxGrade = Math.max(
            subunit.postQuiz.maxGrade,
            response.assignment.grade
          );
          subunit.postQuiz.progress = response.assignment.progress;
          subunit.postQuiz.submitted = response.assignment.submitted;
        }

        if (response.progress) {
          const unitProgress = response.progress.units.find(
            (u) => u.unitId === unit.unitId
          );
          const subunitProgress = unitProgress?.subunits.find(
            (su) => su.subunitId === subunit.subunitId
          );
          subunit.progress = subunitProgress?.progress || subunit.progress;
          unit.progress = unitProgress?.progress || unit.progress;
          courseProgress.progress = response.progress.progress;
          if (
            response.assignment.type === "preQuiz" &&
            subunitProgress?.practice
          ) {
            subunit.practice = subunitProgress.practice;
          }
        }
      }
    }

    this.setState({
      progresses: [
        ...this.state.progresses.filter(
          (x) => x.courseId !== response.assignment.courseId
        ),
        courseProgress,
      ],
      learner: addPointsToLearner(this.state.learner, response.pointsEarned),
    });
  }

  updateProgressWithCheckAnswerResponse(
    response: CheckAnswerResponse,
    courseId: string,
    type: "practice" | "preQuiz" | "postQuiz" | "unitTest" | "courseTest",
    sk: string,
    skillId: string,
    unitId?: string,
    subunitId?: string
  ): void {
    if (response.responseType !== "full") {
      return;
    }

    const progress = this.state.progresses.find((p) => p.courseId === courseId);
    if (!progress) {
      return undefined;
    }

    const updatedLearner = addPointsToLearner(
      this.state.learner,
      response.pointsEarned
    );

    if (type === "courseTest" && progress.courseTest) {
      if (response.grade) {
        progress.courseTest.maxGrade =
          response.maxGrade || progress.courseTest.maxGrade;
      }
      if (progress.courseTest.assignment) {
        progress.courseTest.assignment.grade =
          response.grade || progress.courseTest.assignment.grade;
        progress.courseTest.assignment.maxGrade =
          response.maxGrade || progress.courseTest.maxGrade;
        progress.courseTest.assignment.progress =
          response.progress?.assignmentProgress ||
          progress.courseTest.assignment.progress;
        progress.courseTest.progress = progress.courseTest.assignment.progress;
        const skill = progress.courseTest.assignment.skills.find(
          (skill) => skill._id === skillId
        );
        if (skill) {
          skill.skillComplete = response.skillComplete;
          skill.score = response.skillScore || skill.score;
          skill.record = response.skillRecord || skill.record;
        }
      }
      progress.progress = response.progress?.courseProgress || 0;

      this.setState({
        progresses: [
          ...this.state.progresses.filter((x) => x.courseId !== courseId),
          progress,
        ],
        currentProblemSolved: true,
        learner: updatedLearner,
      });
      return;
    }

    const unit = progress.units.find((u) => u.unitId === unitId);
    if (!unit) {
      return undefined;
    }

    if (type === "unitTest" && unit.unitTest) {
      if (response.grade) {
        unit.unitTest.maxGrade = response.maxGrade || unit.unitTest.maxGrade;
      }
      if (unit.unitTest.assignment) {
        unit.unitTest.assignment.grade =
          response.grade || unit.unitTest.assignment.grade;
        unit.unitTest.assignment.maxGrade =
          response.maxGrade || unit.unitTest.maxGrade;
        unit.unitTest.assignment.progress =
          response.progress?.assignmentProgress ||
          unit.unitTest.assignment.progress;
        unit.unitTest.progress = unit.unitTest.assignment.progress;
        const skill = unit.unitTest.assignment.skills.find(
          (skill) => skill._id === skillId
        );
        if (skill) {
          skill.skillComplete = response.skillComplete;
          skill.score = response.skillScore || skill.score;
          skill.record = response.skillRecord || skill.record;
        }
      }
      progress.progress = response.progress?.courseProgress || 0;
      unit.progress = response.progress?.unitProgress || 0;

      this.setState({
        progresses: [
          ...this.state.progresses.filter((x) => x.courseId !== courseId),
          progress,
        ],
        currentProblemSolved: true,
        learner: updatedLearner,
      });

      return;
    }

    const subunit = unit
      ? unit.subunits.find((s) => s.subunitId === subunitId)
      : undefined;
    if (!subunit) {
      return undefined;
    }

    if (type === "preQuiz" && subunit.preQuiz) {
      if (response.grade) {
        subunit.preQuiz.maxGrade =
          response.maxGrade || subunit.preQuiz.maxGrade;
      }
      if (subunit.preQuiz.assignment) {
        subunit.preQuiz.assignment.grade =
          response.grade || subunit.preQuiz.assignment.grade;
        subunit.preQuiz.assignment.maxGrade =
          response.maxGrade || subunit.preQuiz.maxGrade;
        subunit.preQuiz.assignment.progress =
          response.progress?.assignmentProgress ||
          subunit.preQuiz.assignment.progress;
        subunit.preQuiz.progress = subunit.preQuiz.assignment.progress;

        const skill = subunit.preQuiz.assignment.skills.find(
          (skill) => skill._id === skillId
        );
        if (skill) {
          skill.skillComplete = response.skillComplete;
          skill.score = response.skillScore || skill.score;
          skill.record = response.skillRecord || skill.record;
        }
      }
    } else if (type === "practice" && subunit.practice) {
      if (response.grade) {
        subunit.practice.maxGrade =
          response.maxGrade || subunit.practice.maxGrade;
      }
      if (subunit.practice.assignment) {
        subunit.practice.assignment.grade =
          response.grade || subunit.practice.assignment.grade;
        subunit.practice.assignment.maxGrade =
          response.maxGrade || subunit.practice.maxGrade;
        subunit.practice.assignment.progress =
          response.progress?.assignmentProgress ||
          subunit.practice.assignment.progress;
        subunit.practice.progress = subunit.practice.assignment.progress;
        const skill = subunit.practice.assignment.skills.find(
          (skill) => skill._id === skillId
        );
        if (skill) {
          skill.skillComplete = response.skillComplete;
          skill.score = response.skillScore || skill.score;
          skill.record = response.skillRecord || skill.record;
        }
      }
    } else if (type === "postQuiz" && subunit.postQuiz) {
      if (response.grade) {
        subunit.postQuiz.maxGrade =
          response.maxGrade || subunit.postQuiz.maxGrade;
      }
      if (subunit.postQuiz.assignment) {
        subunit.postQuiz.assignment.grade =
          response.grade || subunit.postQuiz.assignment.grade;
        subunit.postQuiz.assignment.maxGrade =
          response.maxGrade || subunit.postQuiz.maxGrade;
        subunit.postQuiz.assignment.progress =
          response.progress?.assignmentProgress ||
          subunit.postQuiz.assignment.progress;
        subunit.postQuiz.progress = subunit.postQuiz.assignment.progress;
        const skill = subunit.postQuiz.assignment.skills.find(
          (skill) => skill._id === skillId
        );
        if (skill) {
          skill.skillComplete = response.skillComplete;
          skill.score = response.skillScore || skill.score;
          skill.record = response.skillRecord || skill.record;
        }
      }
    }

    progress.progress = response.progress?.courseProgress || 0;
    unit.progress = response.progress?.unitProgress || 0;
    subunit.progress = response.progress?.subunitProgress || 0;

    this.setState({
      progresses: [
        ...this.state.progresses.filter((x) => x.courseId !== courseId),
        progress,
      ],
      currentProblemSolved: true,
      learner: updatedLearner,
    });
  }

  navigateProblem() {
    this.setState({
      ...this.state,
      currentProblemSolved: false,
    });
  }

  setLearnerAssignment(
    courseId: string,
    assignment: LearnerAssignment,
    assignmentType: LearnerAssignmentTypes = "preQuiz",
    unitId?: string,
    subunitId?: string
  ): Progress | undefined {
    const progress = this.state.progresses.find((p) => p.courseId === courseId);
    if (!progress) {
      return undefined;
    }

    if (assignmentType === "courseTest") {
      progress.courseTest = {
        ref: assignment._id,
        assignment: assignment,
        maxGrade: assignment.maxGrade,
        progress: assignment.progress,
      };
    }

    const unit = progress.units.find((u) => u.unitId === unitId);
    if (assignmentType === "unitTest" && unit) {
      unit.unitTest = {
        ref: assignment._id,
        assignment: assignment,
        maxGrade: assignment.maxGrade,
        progress: assignment.progress,
      };
    }

    const subunit = unit
      ? unit.subunits.find((s) => s.subunitId === subunitId)
      : undefined;
    if (
      subunit &&
      assignmentType !== "courseTest" &&
      assignmentType !== "unitTest"
    ) {
      subunit[assignmentType] = {
        ref: assignment._id,
        assignment: assignment,
        maxGrade: assignment.maxGrade,
        progress: assignment.progress,
      };
    } else if (
      unit &&
      assignmentType !== "courseTest" &&
      assignmentType !== "unitTest" &&
      assignment.subunitId
    ) {
      const newSubunit: SubunitProgress = {
        subunitId: assignment.subunitId,
        progress: 0,
        updatedAt: new Date().toISOString(),
      };
      newSubunit[assignmentType] = {
        ref: assignment._id,
        assignment: assignment,
        maxGrade: assignment.maxGrade,
        progress: assignment.progress,
      };
      unit.subunits = [...(unit?.subunits || []), newSubunit];
    }

    this.setState({
      progresses: [
        ...this.state.progresses.filter((p) => p._id !== progress._id),
        progress,
      ],
    });

    return progress;
  }

  setLearnerProgress(progress: Progress) {
    this.setState({
      progresses: [
        ...this.state.progresses.filter((p) => p._id !== progress._id),
        progress,
      ],
    });
  }

  setIsSidebarMinimized(isSidebarMinimized: boolean) {
    this.setState({ isSidebarMinimized });
    try {
      localStorage.setItem(
        SIDEBAR_MINIMIZED_KEY,
        isSidebarMinimized ? "true" : "false"
      );
    } catch (e) {
      // ignore
    }
  }

  clearIsSidebarMinimized() {
    this.setState({ isSidebarMinimized: false });
    try {
      localStorage.removeItem(SIDEBAR_MINIMIZED_KEY);
    } catch (e) {
      // ignore
    }
  }

  updateLearner(partialLearner: Partial<Learner>) {
    if (!this.state.learner) {
      throw new Error("Cannot update undefined learner");
    }
    this.setState({
      learner: {
        ...this.state.learner,
        ...partialLearner,
      },
    });
  }

  refetchLearner() {
    this.clearAndRefetchLearner();
  }

  get isSidebarMinimized() {
    return this.state.isSidebarMinimized;
  }

  get learner() {
    return this.state.learner;
  }

  get pointsAvailable(): number | null {
    return this.state.learner
      ? this.state.learner.pointsEarned - this.state.learner.pointsUsed
      : null;
  }

  constructor(
    state: LearnerState,
    setState: (state: Partial<LearnerState>) => void,
    refetchLearner: () => void,
    logout: () => void,
    setAssignmentIdsToLoad: (ids: string[]) => void
  ) {
    this.state = state;
    this.setState = setState;
    this.clearAndRefetchLearner = refetchLearner;
    this.logout = logout;
    this.setAssignmentIdsToLoad = setAssignmentIdsToLoad;
  }
}

const LearnerContext = React.createContext<LearnerHolder>({} as LearnerHolder);

export default LearnerContext;

export function LearnerContextProvider({
  children,
}: {
  children: ReactElement;
}) {
  const courseContext = useCourseContext();
  const userContext = useUserContext();
  const navigate = useNavigate();

  let isSidebarMinimized = false;
  try {
    isSidebarMinimized = localStorage.getItem(SIDEBAR_MINIMIZED_KEY) === "true";
  } catch (e) {
    // ignore
  }

  const [assignmentIdsToLoad, setAssignmentIdsToLoad] = useState<string[]>([]);
  const [state, setState] = useState<LearnerState>({
    progresses: [],
    currentProblemSolved: false,
    isSidebarMinimized,
    learner: undefined,
    isLoadingProgress: true,
    isLoadingAssignment: true,
    currentLearner: undefined,
  });

  const enableEntitlements =
    state.learner?.features?.includes("enableEntitlements");
  const enableSubscriptions = state.learner?.features?.includes(
    "enableSubscriptions"
  );

  const courseMatch = useMatch(`${REACT_APP_LEARNER_LINK}/:coursePath/*`);
  const courseLandingMatch = useMatch(
    `${REACT_APP_LEARNER_LINK}/course/:coursePath/*`
  );
  const unitMatch = useMatch(
    `${REACT_APP_LEARNER_LINK}/:coursePath/:unitPath/*`
  );
  const subunitMatch = useMatch(
    `${REACT_APP_LEARNER_LINK}/:coursePath/:unitPath/:subunitPath/*`
  );
  const { coursePath } = courseLandingMatch
    ? courseLandingMatch.params
    : courseMatch
    ? courseMatch.params
    : { coursePath: undefined };
  const { unitPath } = unitMatch ? unitMatch.params : { unitPath: undefined };
  const { subunitPath } = subunitMatch
    ? subunitMatch.params
    : { subunitPath: undefined };

  const courseData = courseContext.getCourseData(coursePath);
  const unitData = courseContext.getUnitData(unitPath, coursePath);
  const subunitData = courseContext.getSubunitData(
    subunitPath,
    unitPath,
    coursePath
  );

  const saveState = (partialState: Partial<LearnerState>) => {
    setState({ ...state, ...partialState });
  };

  const logout = () => {
    userContext.logoutUser();
    setState({
      progresses: [],
      currentProblemSolved: false,
      isSidebarMinimized: false,
      learner: undefined,
      isLoadingProgress: true,
      isLoadingAssignment: true,
      currentLearner: undefined,
    });
  };

  // ****************
  // * Progress Data
  // ****************
  const { refetch: progressRefetch } = useDMQuery<Progress[]>({
    path: `/learner/data/progress`,
    queryOptions: {
      enabled: false,
      onSuccess: (data: Progress[]) => {
        setTimeout(() => {
          saveState({
            progresses: data,
            isLoadingProgress: false,
          });
        }, PROGRESS_LOADING_DELAY);
      },
    },
  });

  // *****************
  // * Assignment Data
  // *****************
  const { refetch: assignmentRefetch } = useDMQuery<LearnerAssignment[]>({
    path: `/learner/${
      state.learner?.entitlements.includes("parent") ? "parent" : "data"
    }/assignment_by_ids?ids=${assignmentIdsToLoad.join(",")}${
      state.learner?.entitlements.includes("parent") && state.currentLearner
        ? `&learnerId=${state.currentLearner?.toString()}`
        : ""
    }`,
    queryOptions: {
      enabled: false,
      onSuccess: (data: LearnerAssignment[] | undefined) => {
        if (data && data.length > 0) {
          const allProgresses = state.progresses;

          data.map((assignment) => {
            const progress = allProgresses.find(
              (p) => p.courseId === assignment.courseId
            );
            const unit =
              progress && assignment.unitId
                ? progress.units.find((u) => u.unitId === assignment.unitId)
                : undefined;
            const subunit =
              unit && assignment.subunitId
                ? unit.subunits.find(
                    (s) => s.subunitId === assignment.subunitId
                  )
                : undefined;

            if (!progress) {
              return;
            }
            if (assignment.type === "courseTest" && progress.courseTest) {
              progress.courseTest.assignment = assignment;
              return;
            }

            if (!unit) {
              return;
            }
            if (assignment.type === "unitTest" && unit.unitTest) {
              unit.unitTest.assignment = assignment;
              return;
            }

            if (!subunit) {
              return;
            }
            if (assignment.type === "postQuiz" && subunit.postQuiz) {
              subunit.postQuiz.assignment = assignment;
            } else if (assignment.type === "practice" && subunit.practice) {
              subunit.practice.assignment = assignment;
            } else if (assignment.type === "preQuiz" && subunit.preQuiz) {
              subunit.preQuiz.assignment = assignment;
            }
          });
          setState({
            progresses: allProgresses,
            currentProblemSolved: false,
            isSidebarMinimized: state.isSidebarMinimized,
            learner: state.learner,
            isLoadingProgress: state.isLoadingProgress,
            isLoadingAssignment: false,
            currentLearner: state.currentLearner,
          });
        }
      },
    },
  });

  useEffect(() => {
    if (assignmentIdsToLoad.length > 0) {
      saveState({ isLoadingAssignment: true });
      assignmentRefetch();
    }
    // Adding saveState here causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentIdsToLoad, assignmentRefetch]);

  // *****************
  // * Learner data
  // *****************
  const { refetch: fetchActiveLearner, isStale } = useDMQuery({
    path: "/learner/shared/active-learner",
    cacheKey: "active-learner",
    queryOptions: {
      enabled: false,
      onSuccess({ learner }: ActiveLearnerResponse) {
        setState({ ...state, learner });
        if (learner.entitlements.includes("learner")) {
          // we only want to load progress objects if we are in a learner account not a parent account
          progressRefetch();
        }
      },
      // Refresh every 30 minutes. This is mostly to ensure that when the
      // subscription is updated, we get the new expiration
      staleTime: ACTIVE_LEARNER_STALE_TIME,
    },
  });

  useEffect(() => {
    fetchActiveLearner();
  }, [
    fetchActiveLearner,
    userContext.state?.decodedJwt?.data?.decampTarget,
    isStale,
  ]);

  // Redirect to expired page if the learner loses their learner entitlement
  useEffect(() => {
    if (
      state.learner &&
      enableEntitlements &&
      enableSubscriptions &&
      !state.learner.entitlements.includes("learner") &&
      !state.learner.entitlements.includes("parent")
    ) {
      navigate(`${REACT_APP_LEARNER_LINK}/expired`);
    }
  }, [enableEntitlements, enableSubscriptions, navigate, state.learner]);

  // Redirect to expired page when the learner's subscription has expired
  useEffect(() => {
    if (
      state.learner &&
      enableSubscriptions &&
      state.learner.subscriptionExpiration &&
      state.learner.entitlements.includes("learner")
    ) {
      const now = Date.now();
      const expires = new Date(state.learner.subscriptionExpiration).getTime();
      const diff = expires - now;
      let timeout: number | undefined;
      if (diff < ACTIVE_LEARNER_STALE_TIME) {
        timeout = window.setTimeout(() => {
          navigate(`${REACT_APP_LEARNER_LINK}/expired`);
        }, Math.max(diff, 1));
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [enableSubscriptions, navigate, state.learner]);

  // TODO: We are currently only loading assignments on the subunit level
  // This will need to be expanded on improved to include unit and course tests
  useEffect(() => {
    const progress = state.progresses.find(
      (p) => p.courseId === courseData?.id
    );
    const courseAssignmentId =
      progress && !progress.courseTest?.assignment
        ? compact([progress.courseTest?.ref])
        : [];
    const unit = progress
      ? progress.units.find((u) => u.unitId === unitData?.id)
      : undefined;
    const unitAssignmentId =
      unit && !unit.unitTest?.assignment ? compact([unit.unitTest?.ref]) : [];
    const subunit = unit
      ? unit.subunits.find((s) => s.subunitId === subunitData?.id)
      : undefined;
    const subunitAssignmentIds = subunit
      ? [
          ...(subunit.preQuiz && !subunit.preQuiz.assignment
            ? [subunit.preQuiz.ref]
            : []),
          ...(subunit.practice && !subunit.practice.assignment
            ? [subunit.practice.ref]
            : []),
          ...(subunit.postQuiz && !subunit.postQuiz.assignment
            ? [subunit.postQuiz.ref]
            : []),
        ]
      : [];
    if (
      subunitAssignmentIds.length > 0 ||
      unitAssignmentId.length > 0 ||
      courseAssignmentId.length > 0
    ) {
      setAssignmentIdsToLoad(
        compact([
          ...subunitAssignmentIds,
          ...unitAssignmentId,
          ...courseAssignmentId,
        ])
      );
    }
  }, [state.progresses, courseData, unitData, subunitData]);

  return (
    <LearnerContext.Provider
      value={
        new LearnerHolder(
          state,
          saveState,
          fetchActiveLearner,
          logout,
          setAssignmentIdsToLoad
        )
      }
    >
      {children}
    </LearnerContext.Provider>
  );
}

export function useLearnerContext(): LearnerHolder {
  return useContext(LearnerContext);
}
