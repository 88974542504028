import { HTMLAttributes } from "react";

interface PlaceholderImageProps extends HTMLAttributes<HTMLImageElement> {
  width: number;
  height: number;
}

/** Renders a placeholder image with the provided dimensions */
export const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  width,
  height,
  ...props
}) => (
  <img
    src={`https://placehold.co/${width}x${height}`}
    srcSet={`https://placehold.co/${width * 2}x${height * 2} 2x`}
    alt="This is a placeholder image"
    aria-hidden="true"
    {...props}
  />
);
