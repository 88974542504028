import Sidebar from "../Sidebar/unit";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { PageFooter } from "./PageFooter";
import clsx from "clsx";
import { AppTimers } from "./AppTimers";
import ImpersonationHeader from "../Dashboard/ImpersonationHeader";
import { PointsToast } from "./PointsToast";
import { useDocumentTitle, useReadLocalStorage } from "usehooks-ts";
import { compact } from "lodash";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useLocation, useParams } from "react-router-dom";
import { Learner } from "../../types";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { useUserContext } from "../../../shared/contexts/UserContext";
import {
  initialDataLoadEvent,
  parentInitialDataLoadEvent,
} from "../../analytics/events";

// This is outside of the component because we want to happen exactly once when
// the page is loaded--never again until the page is refreshed
let trackedInitialLoad = false;

export const LearnerPage: React.FC<
  PropsWithChildren<{
    sidebar?: ReactNode;
    noFooter?: boolean;
    noPadding?: boolean;
    dontTrackTime?: boolean;
    title: string | undefined;
  }>
> = ({
  sidebar,
  noFooter = false,
  noPadding = false,
  children,
  dontTrackTime,
  title,
}) => {
  useDocumentTitle(compact([title, "DeltaMath for Home"]).join(" | "));

  const { learner } = useLearnerContext();
  const userContext = useUserContext();
  const location = useLocation();
  const { assignmentType } = useParams();
  const user = useReadLocalStorage<Learner>("user");
  const { pageView, identify, track, getAssignmentData } =
    useLearnerAnalytics();
  const [previousPath, setPreviousPath] = useState<string>("unknown");

  // Identify the learner when we have learner data
  useEffect(() => {
    if (learner) {
      identify(learner);
    } else if (user) {
      identify(user);
    }
  }, [identify, learner, user]);

  // Track page views
  useEffect(() => {
    pageView({
      ...getAssignmentData(assignmentType),
      previousPath,
    });
    setPreviousPath(location.pathname);
    // Only track when the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Track initial data load
  useEffect(() => {
    if (!trackedInitialLoad && learner) {
      trackedInitialLoad = true;
      track(
        learner.entitlements.includes("parent")
          ? parentInitialDataLoadEvent()
          : initialDataLoadEvent()
      );
    }
    // Only want this to run once, not when any dependency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learner]);

  return (
    <div className="h-full w-full">
      {/*
        Note: if you _aren't_ using the LearnerPage component to lay out a
        page, you'll need to call useAppTimers() or include the AppTimers
        component manually for that page. This is necessary because we're
        using the Router component within the App component instead of an
        App component with an Outlet.
      */}
      {!dontTrackTime && !userContext.isTeacherImpersonating && <AppTimers />}

      <ImpersonationHeader />
      <div className="flex flex-col lg:flex-row">
        {sidebar ? <>{sidebar}</> : <Sidebar />}
        <main
          className={clsx(
            "relative flex min-h-dvh w-full flex-col gap-12",
            noPadding ? "pb-6" : "py-6"
          )}
        >
          <div className={clsx("flex-grow", !noPadding && "px-6")}>
            {children}
          </div>
          {!noFooter && <PageFooter />}
        </main>
      </div>

      <PointsToast />
    </div>
  );
};
