import Modal from "../../../student/components/generic/Modal";
import { getFilePath } from "../../../utils";
import { ResponsiveImage } from "../ResponsiveImage";

type Props = {
  visible: boolean;
  toggleVisible: (visible: boolean) => void;
  onConfirm: () => void;
  confirmButtonText: string;
  closeButtonText: string;
  type: "unitTest" | "courseTest";
};

const TestConfirmationModal = (props: Props) => {
  const imageFile =
    props.type === "unitTest"
      ? "unittest/start-unit-test-early"
      : "coursetest/course-test-you-sure";

  return (
    <Modal
      visible={props.visible}
      onClose={() => props.toggleVisible(false)}
      onConfirm={props.onConfirm}
      confirmButtonText={props.confirmButtonText}
      secondaryButtonText={props.closeButtonText}
      secondaryOnClick={() => props.toggleVisible(false)}
      title={
        <>
          <ResponsiveImage
            className="mx-auto h-auto w-56"
            srcs={[
              getFilePath(`/images/learner/${imageFile}.png`),
              getFilePath(`/images/learner/${imageFile}@2x.png`),
            ]}
            alt=""
            aria-hidden="true"
          />
          <span className="mt-4 block font-serif text-2xl font-bold">
            Ready to start your{" "}
            {props.type === "unitTest" ? "unit test" : "course test"}?
          </span>
        </>
      }
      body={
        <>
          <p className="text-sm">Time to show off your new skills!</p>
        </>
      }
    />
  );
};

export default TestConfirmationModal;
