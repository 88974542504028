import { getFilePath } from "../../../utils";
import { SteppedModal } from "../SteppedModal";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useShowOnboarding } from "../../utils/useShowOnboarding";

export const LearnerOnboardingModal: React.FC = () => {
  const learnerContext = useLearnerContext();
  const showOnboarding = useShowOnboarding();

  if (!showOnboarding) {
    return null;
  }

  return (
    <SteppedModal
      lastButtonText="Let's Start!"
      onClose={() => learnerContext.updateLearner({ viewedOnboarding: true })}
      steps={[
        {
          title: "Dashboard",
          imageSrcs: [
            getFilePath("/images/learner/onboarding/dashboard.png"),
            getFilePath("/images/learner/onboarding/dashboard@2x.png"),
            getFilePath("/images/learner/onboarding/dashboard@3x.png"),
          ],
          imageAlt: "Screenshot of the dashboard",
          text: (
            <p>
              <strong>
                Your personalized dashboard is the command center for your math
                journey.
              </strong>{" "}
              Easily track your progress, review performance stats, and jump
              back into learning right where you left off. It&apos;s your go-to
              resource for staying on track and achieving your goals.
            </p>
          ),
        },
        {
          title: "Course Summary and Units",
          imageSrcs: [
            getFilePath("/images/learner/onboarding/course-summary.png"),
            getFilePath("/images/learner/onboarding/course-summary@2x.png"),
            getFilePath("/images/learner/onboarding/course-summary@3x.png"),
          ],
          imageAlt: "Screenshot of the course summary",
          text: (
            <p>
              <strong>
                Explore your course and discover the building blocks of math.
              </strong>{" "}
              Each course is divided into focused units, further broken down
              into manageable sections to guide your learning. Check your course
              summary to monitor overall progress, see your unit test scores,
              and find specific skills you want to practice.
            </p>
          ),
        },
        {
          title: "Section Details",
          imageSrcs: [
            getFilePath("/images/learner/onboarding/section-details.png"),
            getFilePath("/images/learner/onboarding/section-details@2x.png"),
            getFilePath("/images/learner/onboarding/section-details@3x.png"),
          ],
          imageAlt: "Screenshot of section details",
          text: (
            <p>
              <strong>
                Each section is designed to build your skills step by step.
              </strong>{" "}
              Start with a pre-quiz to identify your current understanding, then
              practice on the topics you need the most help with. End with a
              post-quiz to show how far you&apos;ve come and what you&apos;ve
              learned.
            </p>
          ),
        },
        {
          title: "Points and Weekly Goals",
          imageSrcs: [
            getFilePath("/images/learner/onboarding/points.png"),
            getFilePath("/images/learner/onboarding/points@2x.png"),
            getFilePath("/images/learner/onboarding/points@3x.png"),
          ],
          imageAlt: "Screenshot of points and weekly goals",
          text: (
            <p>
              <strong>
                Earn points, stay motivated, and level up your math skills!
              </strong>{" "}
              You&apos;ll get points for answering questions correctly, watching
              videos, and even playing games. Reach your weekly goal of 50
              points to hit your first learning streak. Make math a habit, and
              the points will keep coming!
            </p>
          ),
        },
        {
          title: "Pascal's Triangle & Exciting Prizes",
          imageSrcs: [
            getFilePath("/images/learner/onboarding/pascal.png"),
            getFilePath("/images/learner/onboarding/pascal@2x.png"),
            getFilePath("/images/learner/onboarding/pascal@3x.png"),
          ],
          imageAlt: "Screenshot of Pascal's Triangle",
          text: (
            <p>
              <strong>Ready for an extra challenge?</strong> Earn 31 points to
              access Pascal&apos;s Triangle, a unique game filled with
              surprises. Unlock new rows to reveal valuable prizes like
              animations, games, test hints, and even question swaps for those
              tricky tests and quizzes. The more you learn with DeltaMath, the
              more rewards you&apos;ll uncover.
            </p>
          ),
        },
      ]}
    />
  );
};
