import { useEffect, useState } from "react";
import PasswordBox from "../BetaSignUp/PasswordBox";
import Button from "../../../student/components/generic/button";
import {
  PasswordErrorMessages,
  useDMQuery,
  evaluatePassword,
} from "../../../utils";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import md5 from "md5";

type Props = {
  token: string;
  backToLogin: (state: "login") => void;
};

const ResetPassword = (props: Props) => {
  const toastContext = useDeltaToastContext();

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [resetComplete, setResetComplete] = useState<boolean>(false);

  const { data, status, refetch } = useDMQuery<{
    email: string;
    learnerId: string;
  }>({
    path: `/learner/default/resetQuery?token=${props.token}`,
    queryOptions: {
      staleTime: 1000,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  });

  const resetPassword = useMutation(
    (body: any) => {
      return axios.post(
        `${deltamathAPI()}/learner/default/password_reset`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess() {
        setResetComplete(true);
      },
      onError(err: any) {
        toastContext.addToast({
          status: "Error",
          message:
            err?.response?.data?.message ||
            "There was an error resetting your password, please try requesting another password reset email.",
        });
        setResetComplete(true);
      },
    }
  );

  const submit = () => {
    if (!evaluatePassword(password)) {
      setShowPasswordError(true);
      return;
    }
    resetPassword.mutate({
      password: md5("deltamath" + password),
      token: props.token,
    });
  };

  useEffect(() => {
    if (props.token) {
      refetch();
    }
  }, [props.token]);

  return (
    <>
      <div className="md:mt-16">
        <h2 className="mt-6 text-center font-[Lora] text-2xl font-bold text-dm-brand-blue-600">
          Reset Password
        </h2>
      </div>

      <div className="mt-2 flex-grow sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 sm:rounded-lg sm:px-10">
          {status === "success" && !resetComplete && (
            <form onClick={(e) => e.preventDefault()}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-bold text-dm-charcoal-800"
                >
                  Email or Username
                </label>
                <p id="email" className="mt-3">
                  {data.email}
                </p>
              </div>
              <div className="mt-6">
                {showPasswordError && (
                  <PasswordErrorMessages passwordValue={password} />
                )}
                <PasswordBox
                  label="New Password"
                  value={password}
                  error={undefined}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mt-6">
                <PasswordBox
                  label="Confirm New Password"
                  value={confirmPassword}
                  error={
                    showPasswordError && password !== confirmPassword
                      ? "Passwords do not match"
                      : undefined
                  }
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <Button className="mt-6 w-full" onClick={submit} submit>
                Reset Password
              </Button>
            </form>
          )}
          {(status === "error" || resetComplete) && (
            <>
              <p className="mt-10 text-center text-dm-brand-blue-800">
                {status === "error"
                  ? "This link is either expired or not valid. Please try again"
                  : "Your password has been reset successfully!"}
              </p>
              <Button
                className="mt-6 w-full"
                onClick={() => props.backToLogin("login")}
              >
                Back to Login
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
