import React, { useEffect } from "react";
import clsx from "clsx";
import { formatNumber, useDMQuery } from "../../utils";
import {
  isFuture,
  nextSunday,
  previousSunday,
  startOfDay,
  startOfYesterday,
  sub,
} from "date-fns";
import { nextSaturday } from "date-fns/esm";
import DeltaMathTable, {
  customGradeSort,
  MultiSelectFilter,
} from "../../shared/DeltaMathTable";
import { NoFilter, TextFilter } from "../../shared/table/filters";
// import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { getAdminParams } from "../utils";
import { Column } from "react-table";
import { DemoMode, demoString } from "../../utils/demo";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import { Transition } from "@headlessui/react";

const HOUR = 3600;
export const DAY = 86400; // number of seconds in a day
const WEEK = DAY * 7;
const MONTH = DAY * 30;

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export interface UsageDataInterface {
  time: number;
  stats: {
    n: number;
    t: number;
  };
}

export interface UsageParamsInterface {
  studentIds?: string[] | string;
  sectionIds?: string[] | string;
  teacherIds?: string[] | string;
  schoolIds?: string[] | string;
  district?: string; // so we don't accidentally compute the district data unless strictly specified..
  start?: string;
  end?: string;
  series?: string[] | string;
}

export interface SeriesDataInterface {
  id: string;
  data: {
    time: number;
    stats: {
      t: number;
      n: number;
    };
  }[];
}
export interface DateAccumulatorInterface {
  date: Date;
  time: number;
  problems: number;
}

export interface GraphDataInterface {
  labels: any[];
  datasets: [
    {
      label: string;
      data: any[];
      backgroundColor: string;
    }
  ];
}

export interface SchoolsWithIntegralInterface {
  schoolid: string;
  licenseTier: string;
  schoolName: string;
}

const user = JSON.parse(localStorage.getItem("user") || "{}");

export const correctTime = (theDate: Date) =>
  new Date(
    theDate.toLocaleString("en-US", {
      timeZone: user.timezone || "America/New_York",
    })
  );

export type YearOptions = "2023-2024" | "2022-2023";

export const getPerformanceDateRange = (year: YearOptions) => {
  const [start, end] = year
    .split("-")
    .map((year: string) => parseInt(year.trim()));

  const beginning = new Date(start, 7, 1, 0, 0);
  let ending = new Date(end, 6, 31, 23, 59, 59);
  const currentYear = new Date().getFullYear();

  // end is in current year, don't go further than today
  if (end === currentYear) {
    ending = new Date();
  }
  return { beginning, ending };
};

export const getStartEndDate = (): {
  yesterday: Date;
  lastWeek: Date;
  thirtyDays: Date;
  yearToDate: Date;
  lastYearToDate: Date;
  lastYearEnd: Date;
  currentkey: string;
  lastKey: string;
  today: Date;
} => {
  const today = correctTime(new Date());
  const yesterday = correctTime(startOfYesterday());
  const lastWeek = correctTime(sub(yesterday, { days: 6 }));
  const thirtyDays = correctTime(sub(yesterday, { days: 30 }));

  let yearToDate = new Date();
  const augustOfCurrentYear = correctTime(new Date(today.getFullYear(), 7, 1));
  yearToDate = augustOfCurrentYear;
  if (isFuture(augustOfCurrentYear)) {
    yearToDate = correctTime(new Date(today.getFullYear() - 1, 7, 1));
  }
  const lastYearToDate = correctTime(
    new Date(yearToDate.getFullYear() - 1, 7, 1)
  );
  const lastYearEnd = correctTime(
    new Date(yearToDate.getFullYear(), 6, 31, 23, 59, 59)
  );
  const currentkey = `${yearToDate.getFullYear().toString(10).slice(2)}-${(
    yearToDate.getFullYear() + 1
  )
    .toString(10)
    .slice(2)}`;
  const lastKey = `${lastYearToDate.getFullYear().toString(10).slice(2)}-${(
    lastYearToDate.getFullYear() + 1
  )
    .toString(10)
    .slice(2)}`;
  return {
    yesterday: yesterday,
    today: today,
    lastWeek: lastWeek,
    thirtyDays: thirtyDays,
    yearToDate: yearToDate,
    lastYearToDate: lastYearToDate,
    lastYearEnd: lastYearEnd,
    currentkey: currentkey,
    lastKey: lastKey,
  };
};

export const getDateInterval = ({
  start,
  end,
}: {
  start: number;
  end: number;
}): string => {
  // determine what size time interval by which to accumulate data
  let interval = "";
  if (end - start < DAY) {
    interval = "hour";
  } else if (end - start < DAY * 14) {
    // less than 2 weeks
    interval = "day";
  }
  if (end - start >= DAY * 14) {
    // 2 weeks or more
    interval = "week";
  }
  if (end - start > DAY * 90) {
    // more than 3ish months
    interval = "month";
  }
  return interval;
};

export const getAggregateDateLabels = ({
  interval,
  acc,
}: {
  interval: string;
  // acc: DateAccumulatorInterface[];
  acc: Date[];
}) => {
  const labels: string[] = [];
  interval === "hour"
    ? acc.map((entry) =>
        labels.push(
          // "en-GB" for military time, "en-US" (doesn't quite fit in-line) for 12 hour with AM/PM
          `${entry.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
          })}`
        )
      )
    : interval === "day"
    ? acc.map((entry) =>
        labels.push(
          `${entry.getMonth() + 1}/${entry.getDate()}/${entry.getFullYear()}`
        )
      )
    : interval === "week"
    ? acc.map((entry) =>
        // week = Sunday -- Saturday
        labels.push(
          `${entry.getMonth() + 1}/${entry.getDate()} - ${
            nextSaturday(entry).getMonth() + 1 // getMonth() returns 0 indexed month -> add 1
          }/${nextSaturday(entry).getDate()}`
        )
      )
    : acc.map((entry) =>
        labels.push(`${MONTHS[entry.getMonth()]} ${entry.getFullYear()}`)
      );

  return labels;
};

export const accumulateUsageData = ({
  data,
  dateRange,
}: {
  data: UsageDataInterface[];
  dateRange: { start: number; end: number };
}): {
  acc: Array<DateAccumulatorInterface>;
  aggregateDateLabels: any;
  aggregateTimeAccrued: any;
  aggregateProblemsSolved: any;
} => {
  const interval = getDateInterval({
    start: dateRange.start,
    end: dateRange.end,
  });

  if (data.length < 1) {
    const acc: never[] = [];
    const aggregateDateLabels: never[] = [];
    const aggregateTimeAccrued: never[] = [];
    const aggregateProblemsSolved: never[] = [];
    return {
      acc,
      aggregateDateLabels,
      aggregateTimeAccrued,
      aggregateProblemsSolved,
    };
  }

  // construct interval buckets
  const dateIntervals = [correctTime(new Date(dateRange.start * 1000))];
  if (interval === "hour") {
    // use start of the day so that labels go from 12:00AM - 11:00PM
    dateIntervals[0] = startOfDay(dateIntervals[0]);
  }
  if (interval === "hour") {
    let current = dateIntervals[0];
    for (let i = 0; i < 23; i++) {
      const next = new Date((current.getTime() / 1000 + HOUR) * 1000);
      dateIntervals.push(next);
      current = next;
    }
  } else if (interval === "day") {
    let current = correctTime(new Date(dateRange.start * 1000));
    while (current.getTime() / 1000 < dateRange.end) {
      const nextDay = correctTime(new Date(current));
      nextDay.setDate(current.getDate() + 1);
      dateIntervals.push(nextDay);
      current = nextDay;
    }
  } else if (interval === "week") {
    if (dateIntervals[0].getDay() !== 0) {
      dateIntervals[0] = previousSunday(dateIntervals[0]);
    }
    const startDay = new Date(dateRange.start * 1000);
    let current = startDay.getDay() !== 0 ? previousSunday(startDay) : startDay; //
    while (current.getTime() / 1000 < dateRange.end) {
      const nextWeek = nextSunday(current);
      nextWeek.getTime() / 1000 < dateRange.end && dateIntervals.push(nextWeek);
      current = nextWeek;
    }
  } else if (interval === "month") {
    let current = new Date(dateRange.start * 1000);
    while (current.getTime() / 1000 < dateRange.end) {
      const nextMonth = new Date(current);
      nextMonth.setMonth(current.getMonth() + 1);
      nextMonth.getTime() / 1000 < dateRange.end &&
        dateIntervals.push(nextMonth);
      current = nextMonth;
    }
  }

  /** create an array of objects (representing each time "bucket") to represent each date interval in a graph */
  const dateAccumulator = new Array<DateAccumulatorInterface>();
  dateIntervals.map((interval) =>
    dateAccumulator.push({
      date: interval,
      time: 0,
      problems: 0,
    })
  );

  const intervalSpan =
    interval === "hour"
      ? HOUR
      : interval === "day"
      ? DAY
      : interval === "week"
      ? WEEK
      : MONTH;

  let index = 0;
  data
    .sort((a, b) => a.time - b.time) // sort data (ascending) by timestamp
    .forEach((entry: UsageDataInterface) => {
      if (dateAccumulator[index] && dateAccumulator[index + 1]) {
        if (
          entry.time * 1000 >= dateAccumulator[index].date.getTime() &&
          entry.time * 1000 < dateAccumulator[index + 1].date.getTime()
        ) {
          dateAccumulator[index].time += entry.stats.t;
          dateAccumulator[index].problems += entry.stats.n;
        } else {
          if (index < dateAccumulator.length - 1) {
            index++;
          }
        }
      } else if (
        /**
         * after we get to the last index of the dateAccumulator array, create the final interval
         * by checking between the final index (dateAccumulator[index].time), and whatever would be the subsequent
         * start date i.e. (April 1 + one month) or (Monday + one day).
         *  */
        entry.time * 1000 >= dateAccumulator[index].date.getTime() &&
        entry.time * 1000 <
          dateAccumulator[index].date.getTime() + intervalSpan * 1000
      ) {
        dateAccumulator[index].time += entry.stats.t;
        dateAccumulator[index].problems += entry.stats.n;
      }
    });

  const aggregateTimeAccrued = dateAccumulator.map((entry) => entry.time); //NOTE: times converted to minutes above
  const aggregateProblemsSolved = dateAccumulator.map(
    (entry) => entry.problems
  );
  const aggregateDateLabels = getAggregateDateLabels({
    interval: interval,
    acc: dateAccumulator.map((entry) => entry.date),
  });

  return {
    acc: dateAccumulator,
    aggregateDateLabels,
    aggregateTimeAccrued,
    aggregateProblemsSolved,
  };
};

export const formatBasicData = ({ data }: { data: { data: any } }) =>
  /**
   * @param data nested object returned from /usage endpoint, without series? query param
   * @returns a cleaned array of data that can be passed to accumulateUsageData()
   */
  Object.entries(data.data).map((entry: any) => ({
    time: parseInt(entry[0]),
    stats: {
      t: entry[1].t,
      n: entry[1].n,
    },
  }));

export const formattedLabelValue = (tooltipItem: any) => {
  return (
    tooltipItem.dataset.label + ": " + formatNumber(Math.floor(tooltipItem.raw))
  );
};

export const dataTabs = [
  {
    name: "Time Spent",
    slug: "time-spent",
  },
  {
    name: "Problems Solved",
    slug: "problems-solved",
  },
  {
    name: "Compare Time Spent",
    slug: "compare-time-spent",
  },
  {
    name: "Compare Problems Solved",
    slug: "compare-problems-solved",
  },
];

const tabs = [
  {
    name: "Schools",
    roles: ["district", "super_district", "school", "super_school"],
  },
  {
    name: "Teachers",
    roles: ["district", "super_district", "school", "super_school"],
  },
  {
    name: "Sections",
    roles: ["district", "super_district", "school", "super_school"],
  },
];

export const SelectDataScope = ({
  schoolsWithIntegral,
  selectedDataScope,
  setSelectedDataScope,
  selectedSchools,
  setSelectedSchools,
  selectedSections,
  setSelectedSections,
  selectedTeachers,
  setSelectedTeachers,
}: {
  schoolsWithIntegral: any;
  selectedDataScope: string;
  setSelectedDataScope: any;
  selectedSchools?: any;
  setSelectedSchools?: any;
  selectedSections?: any;
  setSelectedSections?: any;
  selectedTeachers?: any;
  setSelectedTeachers?: any;
}) => {
  const adminParams = getAdminParams();
  const [preSelectedSchools, setPreselectedSchools] =
    React.useState<Record<string, any>>(selectedSchools);
  const [preSelectedTeachers, setPreselectedTeachers] =
    React.useState<Record<string, any>>(selectedTeachers);
  const [preSelectedSections, setPreselectedSections] =
    React.useState<Record<string, any>>(selectedSections);
  const [teacherTableData, setTeacherTableData] = React.useState();
  const [sectionsTableData, setSectionsTableData] = React.useState();
  const [schoolTableData, setSchoolTableData] = React.useState<any>();
  const [schoolsCount, setSchoolsCount] = React.useState<number>(0);
  const [teacherColumns, setTeacherColumns] = React.useState<Column[]>();
  const [sectionColumns, setSectionColumns] = React.useState<Column[]>();

  function getAssignmentsCount(teacher_id: number) {
    return teacherCountsData &&
      typeof teacherCountsData[teacher_id]?.assignments != "undefined"
      ? formatNumber(parseInt(teacherCountsData[teacher_id]?.assignments))
      : 0;
  }

  useEffect(() => {
    if (Object.keys(selectedSchools).length > 0) {
      setPreselectedSchools(selectedSchools);
    } else if (schoolTableData) {
      const allSchools = Object.create({});
      for (let i = 0; i < schoolTableData.length; i++) {
        allSchools[i] = true;
      }
      setPreselectedSchools(allSchools);
    }
  }, [schoolTableData, selectedSchools]);

  const { data: teacherData, isLoading: teacherDataLoading } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });
  // if there is only one teacher -> pre-select teacher in table
  useEffect(() => {
    if (Object.keys(selectedTeachers).length > 0) {
      setPreselectedTeachers(selectedTeachers);
    }
    if (teacherData && teacherData?.length === 1) {
      setPreselectedTeachers({ 0: true });
    }
  }, [teacherData, selectedTeachers]);

  const { data: teacherCountsData, isLoading: teacherCountsDataLoading } =
    useDMQuery({
      path: "/admin_new/data/teacherCounts",
      queryOptions: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    });

  useEffect(() => {
    const newTeachData: any = [];
    if (Array.isArray(teacherData) && Array.isArray(schoolsWithIntegral)) {
      teacherData.forEach((teacher: any) => {
        const school = schoolsWithIntegral.find(
          (school) =>
            // not all schoolids are in the 0th index of schoolPlus[], so get the first id that is not a district id
            teacher.schoolPlus.find((id: string) => id.length > 7) ===
            school.schoolid
        );
        // only show integral
        if (school) {
          newTeachData.push({
            first: demoString({ value: teacher.first, type: "person_first" }),
            last: demoString({ value: teacher.last, type: "person_first" }),
            school:
              school &&
              demoString({ value: school.schoolName, type: "school_name" }),
            assignments: getAssignmentsCount(teacher._id),
          });
        }
      });
    }
    setTeacherTableData(newTeachData);
  }, [teacherData, schoolsWithIntegral, teacherCountsData]);

  useEffect(() => {
    if (
      schoolsWithIntegral &&
      Array.isArray(schoolsWithIntegral) &&
      teacherCountsData
    ) {
      const theSchoolCounts:
        | ((prevState: never[]) => never[])
        | {
            schoolid: string;
            schoolName: string;
            lowGrade: string;
            highGrade: string;
            theSchoolStudentsCount: number;
          }[] = [];
      schoolsWithIntegral.map(
        (school: {
          schoolid: string;
          schoolName: string;
          lowGrade: string;
          highGrade: string;
          dmLicense: { has_integral: any };
        }) => {
          let theSchoolStudentsCount = 0;
          if (Array.isArray(teacherData) && teacherData.length > 0) {
            teacherData.map(
              (teacher: {
                schoolid: string;
                schoolPlus: string[];
                _id: string;
                admin: string;
                annual_logs: number;
              }) => {
                if (teacher.schoolPlus.includes(school.schoolid)) {
                  const studentCount = getStudentCount(parseInt(teacher._id));
                  theSchoolStudentsCount += studentCount;
                }
              }
            );
          }
          theSchoolCounts.push({
            schoolid: school.schoolid,
            schoolName: demoString({
              value: school.schoolName,
              type: "school_name",
            }),
            lowGrade: school.lowGrade,
            highGrade: school.highGrade,
            theSchoolStudentsCount,
          });
        }
      );
      setSchoolsCount(theSchoolCounts.length);
      setSchoolTableData(theSchoolCounts);
    }
  }, [schoolsWithIntegral, teacherData, teacherCountsData]);

  function getStudentCount(teacher_id: number) {
    return teacherCountsData &&
      teacherCountsData[teacher_id] &&
      typeof teacherCountsData[teacher_id].students != "undefined"
      ? parseInt(teacherCountsData[teacher_id].students)
      : 0;
  }

  const { data: sectionsData, isLoading: sectionsDataLoading } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  // if there is only one section -> pre-select section in table
  useEffect(() => {
    if (Object.keys(selectedSections).length > 0) {
      setPreselectedSections(selectedSections);
    } else if (sectionsData) {
      if (sectionsData.length === 1) {
        setPreselectedSections({ 0: true });
      } else setPreselectedSections({});
    }
  }, [sectionsData, selectedSections]);

  useEffect(() => {
    const newSectionsData: any = [];
    if (Array.isArray(sectionsData) && Array.isArray(schoolsWithIntegral)) {
      sectionsData.map((section: any) => {
        const school = schoolsWithIntegral.find(
          (school) =>
            // not all schoolids are in the 0th index of schoolPlus[], so get the first id that is not a district id
            section.schools.find((id: string) => id.length > 7) ===
            school.schoolid
        );

        const teacherArray: string[] = [];
        section.teachers.length &&
          section.teachers.forEach((teacher: { _id: number; name: string }) => {
            if (!teacherArray.includes(teacher.name)) {
              teacherArray.push(teacher.name);
            }
          });

        // mostly a check in case there is no "logsPerYear" value, which would throw an error when we access it
        const yearsWeHaveLogs =
          section["logsPerYear"] && Object.keys(section["logsPerYear"]);
        const totalLogs = 0;
        if (school) {
          newSectionsData.push({
            sectionName: demoString({
              value: section.sectionName,
              type: "section_name",
            }),
            teachers:
              teacherArray.length > 0
                ? teacherArray
                    .map((teacher: string) =>
                      demoString({ value: teacher, type: "person_full" })
                    )
                    .join(", ")
                : "-",
            school:
              school &&
              demoString({ value: school.schoolName, type: "school_name" }),
            // aggregate all logs from all years
            logs: Array.isArray(yearsWeHaveLogs)
              ? yearsWeHaveLogs.reduce(
                  (acc: number, year: any) => acc + section.logsPerYear[year],
                  totalLogs
                )
              : 0,
          });
        }
        setSectionsTableData(newSectionsData);
      });
      setSectionsTableData(newSectionsData);
    }
  }, [sectionsData, schoolsWithIntegral]);

  useEffect(() => {
    if (Array.isArray(schoolTableData)) {
      for (let i = 0; i < schoolTableData?.length; i++) {
        schoolTableData[i].schoolName = demoString({
          value: schoolTableData[i].schoolName,
          type: "school_name",
        });
      }
      setSchoolTableData(schoolTableData);
    }
  }, [schoolTableData]);

  /** initialize user preference*/
  const temp = {
    usagePreferences: {
      usageReportDataScope: {
        Schools: {},
        Teachers: {},
        Sections: {},
      },
    },
  };
  let tempPreferences = JSON.parse(
    localStorage.getItem("usagePreferences") || "{}"
  );
  Object.hasOwn(tempPreferences, "fetchPushed") === false
    ? (tempPreferences = temp)
    : null;
  useEffect(() => {
    if (selectedDataScope === "Schools") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {
          ...selectedSchools,
        },
        Teachers: {},
        Sections: {},
      };
    } else if (selectedDataScope === "Teachers") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {},
        Teachers: { ...selectedTeachers },
        Sections: {},
      };
    } else if (selectedDataScope === "Sections") {
      temp.usagePreferences.usageReportDataScope = {
        Schools: {},
        Teachers: {},
        Sections: { ...selectedSections },
      };
    }
    tempPreferences = temp;

    /** update localStorage.usagePreferences.usageReportDataScope when a new selection is made */
    localStorage.setItem(
      "usagePreferences",
      JSON.stringify({
        ...tempPreferences,
      })
    );
  }, [selectedSchools, selectedSections, selectedTeachers]);

  const schoolColumns = React.useMemo(
    () => [
      {
        Header: "School Name",
        accessor: "schoolName",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Grade Range",
        accessor: "lowGrade",
        Cell: (props: any) => {
          return `${props.row.original.lowGrade} - ${props.row.original.highGrade}`;
        },
        align: "center",
        sortType: customGradeSort,
      },
      {
        Header: "",
        accessor: "highGrade",
      },
      {
        Header: "# of Students",
        accessor: "theSchoolStudentsCount",
        align: "center",
      },
    ],
    []
  );

  useEffect(() => {
    const teacherColumnsDef: Array<any> = [
      {
        Header: "First Name",
        accessor: "first",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Last Name",
        accessor: "last",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "# of Assignments",
        accessor: "assignments",
        Filter: NoFilter,
        align: "left",
      },
    ];

    const sectionColumnsDef: Array<any> = [
      {
        Header: "Section Name",
        accessor: "sectionName",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Teachers",
        accessor: "teachers",
        Filter: TextFilter,
        align: "left",
      },
      {
        Header: "Problems Solved",
        Cell: (props: any) => {
          return <div>{formatNumber(props.value)}</div>;
        },
        accessor: "logs",
        Filter: NoFilter,
        align: "left",
      },
    ];
    if (schoolsCount > 1) {
      // only show the column if there's more than 1 school
      teacherColumnsDef.splice(2, 0, {
        Header: "School",
        accessor: "school",
        Filter: MultiSelectFilter,
        filter: "includesSome",
        align: "left",
        Cell: (props: any) => {
          return (
            <DemoMode
              value={props.value}
              type="school_name"
              district_size={schoolsCount}
            />
          );
        },
      });
      sectionColumnsDef.splice(2, 0, {
        Header: "School",
        accessor: "school",
        Filter: MultiSelectFilter,
        filter: "includesSome",
        align: "left",
      });
    }
    setTeacherColumns(teacherColumnsDef);
    setSectionColumns(sectionColumnsDef);
  }, [schoolsCount]);

  return (
    <div>
      <nav className={"grid grid-cols-3 space-x-4 pb-2"} aria-label="Tabs">
        {tabs.map(
          (tab) =>
            tab.roles &&
            tab.roles.length > 0 &&
            tab?.roles.includes(adminParams.account_type) && (
              <button
                onClick={() => setSelectedDataScope(tab.name)}
                key={tab.name}
                className={clsx(
                  tab.name === selectedDataScope
                    ? "border-dm-light-blue bg-dm-blue text-white"
                    : "border text-gray-500 hover:border-gray-300 hover:text-dm-blue",
                  "col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium"
                )}
              >
                {tab.name}
              </button>
            )
        )}
      </nav>

      {(teacherDataLoading ||
        teacherCountsDataLoading ||
        sectionsDataLoading) && <DmLoadingSpinner message={"Loading..."} />}

      {selectedDataScope === "Schools" && schoolColumns && (
        <>
          {schoolsWithIntegral &&
            teacherData &&
            teacherCountsData &&
            sectionsData &&
            Array.isArray(schoolTableData) &&
            schoolTableData.length > 0 && (
              <div>
                <Transition
                  as="div"
                  show={true}
                  appear={true}
                  enter="transition-opacity duration-300"
                  enterFrom="transform opacity-0"
                  enterTo="transform opacity-100"
                  leave="transition-opacity duration-300"
                  leaveFrom="transform opacity-100"
                  leaveTo="transform opacity-0"
                >
                  <DeltaMathTable
                    columns={schoolColumns}
                    data={schoolTableData}
                    options={{
                      selectable: true,
                      showSelectAll: true,
                      preSelectedRows: preSelectedSchools,
                      initialState: {
                        hiddenColumns: ["highGrade"],
                        sortBy: [
                          {
                            id: "schoolName",
                            desc: false,
                          },
                        ],
                      },
                    }}
                    setSelectedRows={setSelectedSchools}
                  />
                </Transition>
              </div>
            )}
        </>
      )}
      {selectedDataScope === "Teachers" &&
        teacherColumns &&
        teacherCountsData &&
        selectedTeachers &&
        preSelectedTeachers && (
          <>
            {teacherTableData && (
              <Transition
                as="div"
                show={true}
                appear={true}
                enter="transition-opacity duration-300"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <DeltaMathTable
                  columns={teacherColumns}
                  data={teacherTableData}
                  options={{
                    selectable: true,
                    showSelectAll: true,
                    preSelectedRows: preSelectedTeachers,
                  }}
                  setSelectedRows={setSelectedTeachers}
                />
              </Transition>
            )}
          </>
        )}
      {selectedDataScope === "Sections" && (
        <>
          {sectionsTableData && sectionColumns && (
            <Transition
              as="div"
              show={true}
              appear={true}
              enter="transition-opacity duration-300"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <DeltaMathTable
                columns={sectionColumns}
                data={sectionsTableData}
                options={{
                  selectable: true,
                  showSelectAll: true,
                  preSelectedRows: preSelectedSections,
                }}
                setSelectedRows={setSelectedSections}
              />
            </Transition>
          )}
        </>
      )}
    </div>
  );
};

// Take Received IDs and Table Data, return an array of the object properties specified
export const setIdsArray = (
  selectedItems: any,
  tableData: any,
  objectProperties: string[]
) => {
  const selectedDataIds = Object.keys(selectedItems);
  const selectedIds = [];
  if (Array.isArray(objectProperties)) {
    for (let j = 0; j < selectedDataIds.length; j++) {
      const arrayId = parseInt(selectedDataIds[j]);
      // initialize an empty object to push into final array
      const element = JSON.parse("{}");
      for (let i = 0; i < objectProperties.length; i++) {
        element[objectProperties[i].toString()] =
          tableData[arrayId][objectProperties[i]];
      }
      selectedIds.push(element);
    }
  }

  return selectedIds;
};
