import React, { useState, useEffect } from "react";
import md5 from "md5";
import { deltamathAPI } from "../utils";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../shared/contexts/UserContext";

declare global {
  interface Window {
    google: any;
  }
}

export type preLoginResponseType = {
  number: number;
  token: string;
};

export type loginRequestType = {
  email: string;
  password: string;
  prelogin_factor: number;
  prelogin_token: string;
  account_type?: "teacher" | "student" | "admin" | "learner";
};

const GoogleLogin = ({
  processLoginResponse,
}: {
  processLoginResponse: (loginData: any) => void;
}) => {
  useEffect(() => {
    const initializeGoogleSignIn = () => {
      const handleCredentialResponse = async (response: {
        credential: string;
      }) => {
        const loginRequest = await fetch(
          deltamathAPI() + "/login_with_google",
          {
            method: "POST",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              googleToken: response.credential,
            }),
          }
        );
        const loginData = await loginRequest.json();
        processLoginResponse(loginData);
      };

      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        { theme: "outline", size: "large", width: "368" }
      );
    };
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = () => initializeGoogleSignIn();
    document.body.appendChild(script);
  }, []);
  return <div id="signInDiv"></div>;
};

export default function Login({
  setLoggedIn,
  account_type,
}: {
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  account_type?: "teacher" | "student" | "admin" | "learner";
}) {
  const queryClient = useQueryClient();
  const userContext = useUserContext();

  const [loginLoading, setLoginLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState<string | null>(null);

  const navigate = useNavigate();

  function processPreloginFactor(
    res: preLoginResponseType,
    data: loginRequestType
  ): loginRequestType {
    const num = res.number;
    let factor = 0;
    for (let i = 2; i < num; i++) {
      if (num % i == 0) {
        factor = i;
        break;
      }
    }
    data.prelogin_factor = factor;
    data.prelogin_token = res.token;
    if (account_type) {
      data.account_type = account_type;
    }
    return data;
  }

  const processLoginResponse = (loginData: any) => {
    if (loginData?.user?.timeOffset !== undefined)
      loginData.user.timeOffset =
        loginData.user.timeOffset * 1000 - +Date.now();
    let bad_login = true;
    if (loginData.admin) {
      localStorage.setItem("admin", JSON.stringify(loginData.admin));
      bad_login = false;
    }
    if (loginData.user) {
      localStorage.setItem("user", JSON.stringify(loginData.user));
      bad_login = false;
    }
    if (loginData.customer_service_token) {
      bad_login = false;
      localStorage.setItem(
        "customer_service_token",
        loginData.customer_service_token
      );
    }
    if (loginData.jwt) {
      userContext.setJWT(loginData.jwt);
      navigate(account_type === "learner" ? window.location.search : "");
      setLoggedIn(true);
    }
    if (bad_login) {
      setLoggedIn(false);
      localStorage.clear();
      window.location.reload();
    }
  };

  const login = (preLoginData: preLoginResponseType) => {
    const passwordHash = md5("deltamath" + password);
    const data = {
      prelogin_factor: 0,
      prelogin_token: "",
      email: email,
      password: passwordHash,
    };
    const theData = processPreloginFactor(preLoginData, data);
    (async () => {
      try {
        const loginRequest = await fetch(deltamathAPI() + "/login", {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(theData),
        });
        const loginData = await loginRequest.json();
        processLoginResponse(loginData);
      } catch (e) {
        if (String(e).toLowerCase().includes("unauthorized")) {
          setFormError(
            "Unable to login with that username and password. Please try again."
          );
        }
        setLoginLoading(false);
      }
    })();
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    setLoginLoading(true);
    setFormError(null);
    (async () => {
      const preLoginRequest = await fetch(deltamathAPI() + "/prelogin", {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      const preLoginData = await preLoginRequest.json();
      login(preLoginData);
    })();
  };

  return (
    <div className="flex min-h-full w-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Login
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={submitForm}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                {account_type === "learner" ? "Username" : "Email address"}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type={account_type === "learner" ? "text" : "email"}
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loginLoading}
                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
              >
                Sign in
              </button>
              {loginLoading && <div className="mt-4">Logging you in...</div>}
              {formError && (
                <p className="mt-4 text-dm-error-500">{formError}</p>
              )}
            </div>
          </form>

          <div className="mt-6 text-center">
            OR
            <GoogleLogin processLoginResponse={processLoginResponse} />
          </div>
        </div>
      </div>
    </div>
  );
}
