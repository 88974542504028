import { useState, useLayoutEffect, Fragment } from "react";
import { NavLink, useParams } from "react-router-dom";
import clsx from "clsx";
import IconAssignmentProgress from "../../IconAssignmentProgress";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import {
  LearnerAssignmentSkillData,
  LearnerAssignmentTypes,
} from "../../../types";
import PastAttemptsNav from "./PastAttemptsNav";
import SubunitSkill from "./SubunitSkill";
import Accordion from "../../Accordion";
import { useCourseContext } from "../../../contexts/CourseContext";
import { SkeletonCircle, SkeletonText } from "../../Skeleton";
import { REACT_APP_LEARNER_LINK } from "../../../../utils";
import ReactTooltip from "react-tooltip";

type Props = {
  title: string;
  type: LearnerAssignmentTypes;
  isQuickDropdown?: boolean; // if this is the question dropdown in the problem solving area on mobile
};

export default function SubunitSkillsNav({
  title,
  type,
  isQuickDropdown,
}: Props): JSX.Element {
  // changed from useState, which caused a lot of rerenders
  // 12/21/23: changed back to setState, will keep an eye on rerenders
  // const hoverRef = useRef(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const courseContext = useCourseContext();

  const isTest = ["unitTest", "courseTest"].includes(type);

  const { coursePath, unitPath, subunitPath, assignmentType, indexOfSkill } =
    useParams();
  const defaultOpen: boolean = isQuickDropdown
    ? false
    : isTest || assignmentType?.toLowerCase() === type.toLowerCase();
  const courseData = courseContext.getCourseData(coursePath);
  const unitData = courseContext.getUnitData(unitPath, coursePath);
  const subunitData = courseContext.getSubunitData(
    subunitPath,
    unitPath,
    coursePath
  );

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  useLayoutEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen, indexOfSkill]);

  const learnerContext = useLearnerContext();

  if (!courseData?.id || (!isTest && unitData?.id && !subunitData?.id)) {
    return (
      <div className="mb-1 flex gap-2">
        <div className="h-6 w-6">
          <SkeletonCircle />
        </div>
        <SkeletonText>Do velit ad officia</SkeletonText>
      </div>
    );
  }

  const progresses = learnerContext.getProgress(courseData.id);

  const units = progresses?.units?.find(
    (p) => unitData && p.unitId === unitData.id
  );
  const subunits = units?.subunits?.find(
    (p) => p.subunitId === subunitData?.id
  );

  const practiceComplete =
    subunits?.practice?.assignment?.skills &&
    subunits.practice.assignment.skills.every((s) => s.skillComplete === true);
  const isPreQuiz = type === "preQuiz";
  const isPractice = type === "practice";
  const isPostQuiz = type === "postQuiz";

  const assignmentRef =
    type === "unitTest"
      ? units?.unitTest
      : type === "courseTest"
      ? progresses?.courseTest
      : subunits?.[type];
  const assignment = assignmentRef?.assignment;

  const isSubmitted =
    !!assignment?.submitted || !!(isPractice && practiceComplete);

  const preQuizSkipped =
    isPreQuiz && !!subunits?.skippedPreQuiz && !isSubmitted;
  const preQuiz100 = subunits?.preQuiz?.assignment?.grade === 1;
  const firstPostQuizAttempt =
    isPostQuiz && !subunits?.postQuiz?.assignment?.resultHist;

  if (
    type !== "unitTest" &&
    type !== "courseTest" &&
    subunits?.[type] === undefined &&
    subunitData
  ) {
    if ((isPreQuiz && !preQuizSkipped) || (isPostQuiz && practiceComplete)) {
      return (
        <NavLink
          key={`assignment-${subunitData.id}-${type}`}
          to={`${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/${type.toLowerCase()}`}
          className={({ isActive }) =>
            clsx(
              isActive ? "bg-dm-brand-blue-100" : "",
              "group relative mb-1 flex w-full items-center justify-start gap-x-2 rounded-md p-2 px-3 text-sm leading-5 last:mb-0 hover:bg-dm-brand-blue-100"
            )
          }
          onPointerEnter={() => {
            if (!isHover) setIsHover(true);
          }}
          onPointerLeave={() => {
            if (isHover) setIsHover(false);
          }}
        >
          {({ isActive }) => (
            <>
              <IconAssignmentProgress
                type={type}
                progress={isPostQuiz && preQuiz100 ? 100 : 0}
                submitted={isPostQuiz && preQuiz100 ? true : false}
                isActive={isActive || isHover}
                isStarted={true}
              />
              <span className="text-base font-bold">
                {title}
                {isPostQuiz && preQuiz100 && `: Not Required`}
              </span>
            </>
          )}
        </NavLink>
      );
    } else {
      return (
        <div
          className={clsx(
            "group relative mb-1 flex w-full items-center justify-start gap-x-2 rounded-md p-2 px-3 text-sm leading-5 last:mb-0",
            assignmentType === type.toLowerCase() && "bg-dm-brand-blue-100"
          )}
        >
          <IconAssignmentProgress
            type={type}
            progress={0}
            submitted={false}
            isStarted={false}
          />
          <span className="text-base font-bold text-dm-gray-800 opacity-60">
            {title}
            {isPreQuiz && preQuizSkipped && ": Skipped"}
          </span>
          {!(isPreQuiz && preQuizSkipped) && (
            <i
              className="far fa-lock text-dm-gray-200"
              aria-label="Locked"
              role="img"
            ></i>
          )}
        </div>
      );
    }
  }

  ReactTooltip.rebuild();

  const skills: LearnerAssignmentSkillData[] | undefined = assignment?.skills;
  const questionSwap = assignment?.questionSwap;
  //TODO: fix for `Error: Rendered more hooks than during the previous render.`
  if (skills === undefined) {
    return <></>;
  }

  const score = Math.round((assignment?.maxGrade || 0) * 100);
  const progress = Math.round((assignment?.progress || 0) * 100);
  const maxGrade = Math.round((assignmentRef?.maxGrade || 0) * 100);

  const showPastAttemptsMenu =
    (isPostQuiz &&
      practiceComplete &&
      !firstPostQuizAttempt &&
      !!subunits?.postQuiz?.assignment) ||
    (isTest && !!assignment?.resultHist);

  const baseUrl =
    type === "unitTest"
      ? `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/unittest`
      : type === "courseTest"
      ? `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest`
      : `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}/postquiz`;

  return (
    <div
      className={clsx(
        "flex flex-col justify-start bg-white",
        !isQuickDropdown && "mb-1 overflow-hidden"
      )}
    >
      <div className={clsx(!isQuickDropdown && "overflow-y-auto")}>
        <Accordion
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isPanelFloating={isQuickDropdown}
          onPointerEnter={() => {
            if (!isHover) setIsHover(true);
          }}
          onPointerLeave={() => {
            if (isHover) setIsHover(false);
          }}
          titleButton={
            <>
              <IconAssignmentProgress
                type={type}
                progress={progress}
                submitted={isSubmitted}
                isActive={isHover}
                maxGrade={maxGrade}
                isStarted={true}
              />
              <span className="flex-grow text-left text-base font-bold">
                {title}
                {!isSubmitted && type === "practice" && progress > 0 && (
                  <span className="ml-2 inline-flex items-center rounded-full bg-dm-brand-blue-500 px-1.5 py-0.5 text-xs font-medium text-white">
                    {`${progress}% Complete`}
                  </span>
                )}
                {isPostQuiz &&
                  preQuiz100 &&
                  !subunits?.postQuiz?.assignment &&
                  `: Not Required`}
                {isSubmitted && !preQuizSkipped && ` Score: ${score}%`}
              </span>
            </>
          }
          panel={
            <>
              {!showPastAttemptsMenu &&
                skills?.map(
                  (skill: LearnerAssignmentSkillData, index: number) => {
                    const unitName = courseContext.getUnitData(
                      skill.unitId,
                      undefined
                    )?.name;

                    return (
                      <Fragment key={`${type}-skill-${skill.sk}-${index}`}>
                        {type === "unitTest" &&
                          assignment?.solutionSeen === true &&
                          skill?.subunitId !== skills[index - 1]?.subunitId && (
                            <h3 className="mb-1 mt-4 flex items-center gap-x-4 px-3 text-xs uppercase text-dm-gray-500">
                              Section{" "}
                              {(unitData?.subunitOrder.findIndex(
                                (id) => id === skill.subunitId
                              ) || 0) + 1}
                              <hr className="flex-grow" />
                            </h3>
                          )}
                        {type === "courseTest" &&
                          assignment?.solutionSeen === true &&
                          skill?.unitId !== skills[index - 1]?.unitId && (
                            <h3
                              className="mb-1 mt-4 flex cursor-help items-center gap-x-4 px-3 text-xs uppercase text-dm-gray-500"
                              data-tip={unitName}
                              data-for={"sidebar-tooltip"}
                              data-delay-show="200"
                              data-delay-hide="40"
                              data-place="top"
                              data-class="py-1"
                            >
                              Unit{" "}
                              {courseData.unitOrder.findIndex(
                                (id) => id === skill.unitId
                              ) + 1}
                              <hr className="flex-grow" />
                            </h3>
                          )}
                        <SubunitSkill
                          type={type}
                          sk={skill.sk}
                          index={index}
                          score={skill.score}
                          required={skill.required}
                          record={skill.record}
                          skillComplete={skill.skillComplete}
                          isSubmitted={isSubmitted}
                          courseId={courseData.id}
                          subunitId={subunitData?.id}
                          coursePath={coursePath}
                          unitPath={unitPath}
                          subunitPath={subunitPath}
                          wasSwapped={
                            !!questionSwap && questionSwap === skill._id
                          }
                        />
                      </Fragment>
                    );
                  }
                )}
              {showPastAttemptsMenu && !!assignment && (
                <PastAttemptsNav assignment={assignment} baseUrl={baseUrl} />
              )}
              {(showPastAttemptsMenu || (isPostQuiz && isSubmitted)) && (
                <NavLink
                  to={baseUrl}
                  end
                  className={({ isActive }) =>
                    clsx(
                      isActive
                        ? "bg-dm-brand-blue-100 font-bold text-dm-gray-800"
                        : "",
                      "flex flex-row items-center gap-x-2 rounded-md px-3 py-2 text-sm text-dm-gray-500 hover:bg-dm-brand-blue-100 hover:text-dm-gray-800"
                    )
                  }
                >
                  {type === "unitTest" ? "Unit Test" : "Post-Quiz"} Summary
                </NavLink>
              )}
            </>
          }
        />
        <ReactTooltip
          id="icontip"
          className="max-w-[14rem] font-sans text-sm"
          delayShow={250}
          delayHide={50}
          place="top"
          // effect="solid"
        />
      </div>
    </div>
  );
}
