import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useCourseContext } from "../contexts/CourseContext";
import { useLearnerContext } from "../contexts/LearnerContext";
import { cloneDeep } from "lodash";
import { Course, myCourseDataType } from "../types";
import CourseSummaryHeader from "./Course/CourseSummaryHeader";
import UnitSummaryCard from "./Unit/UnitSummaryCard";
import { CourseSearch } from "./Search/CourseSearch";
import CourseTestCard from "./Course/CourseTestCard";
import { CourseLandingWrapper } from "./CourseLandingWrapper";
import { CourseLandingLoading } from "./CourseLandingLoading";
import { LandingPageError } from "./LandingPageError";
import { LearnerPage } from "./Layouts/LearnerPage";

const CourseLanding = () => {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courseData = courseContext.getCourseData(coursePath);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [coursePath]);

  useEffect(() => {
    courseContext.setActiveCourse(courseData?.id);
  }, [courseContext, courseData]);

  return (
    <LearnerPage title={courseData?.name}>
      {courseContext.state.isLoadingCourse ||
      courseContext.state.isLoadingSubunits ||
      learnerContext.state.isLoadingProgress ? (
        <CourseLandingLoading />
      ) : courseData ? (
        <CourseLandingWithData courseData={courseData} />
      ) : (
        <LandingPageError>Unable to load this course</LandingPageError>
      )}
    </LearnerPage>
  );
};

const CourseLandingWithData: React.FC<{ courseData: Course }> = ({
  courseData,
}) => {
  const { coursePath } = useParams();
  const courseContext = useCourseContext();
  const learnerContext = useLearnerContext();
  const courseProgress = learnerContext.getProgress(courseData?.id || "");

  const [lastClickedId, setLastClickedId] = useState("");

  const currentCourses = courseContext.getCurrentLearnerCourses(
    courseData.id,
    learnerContext
  );

  const isCurrentTestSubmitted =
    courseProgress?.courseTest !== undefined &&
    courseProgress?.courseTest?.assignment?.submitted !== undefined;
  const isFirstAttemptComplete =
    isCurrentTestSubmitted ||
    !!courseProgress?.courseTest?.assignment?.resultHist;
  const isInProgress =
    courseProgress?.courseTest !== undefined &&
    !isCurrentTestSubmitted &&
    !courseProgress?.courseTest?.assignment?.solutionSeen;

  // If progress is 0 but course test has been started, set progress
  // to a small amount so progress pie chart shows it in progress
  const courseTestProgress =
    courseProgress?.courseTest && courseProgress?.courseTest?.progress === 0
      ? 0.01
      : courseProgress?.courseTest?.progress;

  const currentCourseData: myCourseDataType | undefined = cloneDeep(
    currentCourses.find(
      (course: myCourseDataType) => course.id === courseData?.id
    )
  );

  currentCourseData?.units?.push({
    id: `course-test-${courseData?.id}`,
    path: "coursetest",
    name: "course-test",
    progress: isFirstAttemptComplete ? 1 : courseTestProgress || 0,
  });

  ReactTooltip.rebuild();

  const estimatedTime = Math.max(
    5,
    Math.ceil((courseData?.avgQuizTime || 0) / 60 / 5) * 5
  );

  return (
    <CourseLandingWrapper
      mobileCourseSearch={<CourseSearch />}
      header={
        <CourseSummaryHeader
          courseProgress={currentCourseData}
          iconUrl={courseData.iconUrl}
          description={courseData.description}
          setLastClickedId={setLastClickedId}
        />
      }
    >
      {courseData.unitOrder.map((u, i) => {
        const unit = (courseData.units ?? []).find((unit) => unit.id === u);
        if (!unit || unit?.subunitOrder.length <= 0) {
          return null;
        }

        return (
          <UnitSummaryCard
            unit={unit}
            course={courseData}
            key={`unitcard-${unit.id}`}
            cardId={`panel-${unit.id}`}
            unitNumber={i + 1}
            lastClickedId={lastClickedId}
          />
        );
      })}

      <h3 className="mb-2 mt-6 font-serif text-lg font-bold">Course Test</h3>
      <CourseTestCard
        courseData={courseData}
        coursePath={coursePath}
        progress={courseProgress}
        estimatedTime={estimatedTime}
        isFirstAttemptComplete={isFirstAttemptComplete}
        isInProgress={isInProgress}
      />
      <ReactTooltip
        id="sidebar-tooltip"
        className="font-sans text-xs"
        effect="solid"
        delayShow={800}
        delayHide={50}
        place="right"
      />
    </CourseLandingWrapper>
  );
};

export default CourseLanding;
