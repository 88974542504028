import { DashboardCard } from "../Dashboard/DashboardCard";
import { LearnerPage } from "../Layouts/LearnerPage";
import { CourseProgressCard } from "./CourseProgressCard";
import { LearnerStats } from "./LearnerStats";
import { RecentActivity } from "./RecentActivity";
import { LaunchLearnerCard } from "./LaunchLearnerCard";
import { QuestionMarkCircleIcon, ClockIcon } from "@heroicons/react/outline";
import { useParentContext } from "../../contexts/ParentContext";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { SkeletonText } from "../Skeleton";
import { TotalPointsCard } from "./TotalPointsCard";
import { LearningStreakCard } from "../Dashboard/LearningStreakCard";
import { format } from "date-fns/esm";
import clsx from "clsx";
import { secondsToDuration } from "../../utils/secondsToDuration";
import { ParentOnboardingModal } from "./ParentOnboardingModal";
import { ParentNav } from "./ParentNav";

export const ParentDashboard: React.FC = () => {
  const { currentLearner } = useParentContext();
  const { state: learnerState } = useLearnerContext();

  const hasNoProgress = learnerState.progresses.length === 0;

  const pageTitle = currentLearner
    ? `${currentLearner.first}'s Insights`
    : "Learner's Insights";

  const welcomeMessage = !hasNoProgress
    ? pageTitle
    : `Welcome, ${learnerState.learner?.first}`;

  const updatedAtDate =
    currentLearner?.createdAt &&
    format(new Date(currentLearner.createdAt), "MMM d, yyyy");
  const lastLoginDate =
    currentLearner?.last_login &&
    format(new Date(currentLearner.last_login), "MMM d, h:mm aaa");

  return (
    <div className="flex w-full flex-col">
      <ParentNav />
      <LearnerPage title={pageTitle} sidebar={<></>} dontTrackTime>
        <header className="relative w-full pb-6">
          <h1 className="font-serif text-lg font-bold">
            <SkeletonText loaded={!!currentLearner}>
              {welcomeMessage}
            </SkeletonText>
          </h1>
        </header>
        {!hasNoProgress && (
          <div className="z-[7] grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <CourseProgressCard />
            <TotalPointsCard />
            <LearningStreakCard
              streaks={currentLearner?.streaks}
              longestStreak={currentLearner?.longest_streak}
              isParent
            />
            <DashboardCard
              size="flex"
              icon={<QuestionMarkCircleIcon />}
              title="Questions Answered"
            >
              <h3 className="mt-4 font-serif text-2xl font-bold">
                {currentLearner?.solved} questions
              </h3>
              <p className="text-sm text-dm-gray-500">Since {updatedAtDate}</p>
            </DashboardCard>
            <DashboardCard
              size="flex"
              icon={<QuestionMarkCircleIcon />}
              title="Questions Answered Correctly"
            >
              <h3 className="mt-4 font-serif text-2xl font-bold">
                {currentLearner?.solved_correct} questions
              </h3>
              <p className="text-sm text-dm-gray-500">Since {updatedAtDate}</p>
            </DashboardCard>
            <DashboardCard
              size="flex"
              icon={<ClockIcon />}
              title="Total Time Spent"
            >
              <h3 className="mt-4 font-serif text-2xl font-bold">
                {currentLearner?.total_app_time
                  ? secondsToDuration(currentLearner.total_app_time)
                  : "0 mins"}
              </h3>
              <p className="text-sm text-dm-gray-500">
                Last logged in: {lastLoginDate}
              </p>
            </DashboardCard>
          </div>
        )}
        <div
          className={clsx(
            "z-[7] mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2",
            hasNoProgress && "order-1"
          )}
        >
          <LearnerStats showEmptyState={hasNoProgress} />
          <RecentActivity currentLearnerId={currentLearner?._id} />
        </div>
        <LaunchLearnerCard className="mt-4" showEmptyState={hasNoProgress} />
        <ParentOnboardingModal />
      </LearnerPage>
    </div>
  );
};
