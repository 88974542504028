import * as React from "react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import VideosApp from "./videos/App";
import ManagerApp from "./manager/App";
import AdminApp from "./admin/App";
import NotesApp from "./notes/App";
import StudentApp from "./student/App";
import TeacherApp from "./teacher/App";
import LearnerApp from "./learner/App";
import Upcoming from "./student/components/sections/Upcoming";
import PastDue from "./student/components/sections/PastDue";
import Completed from "./student/components/sections/Completed";
import Preview from "./student/components/sections/Preview";
import GraphingCalc from "./student/components/calculator/GraphingCalc";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToasterContextProvider } from "./shared/contexts/ToasterContext";
import Profile from "./student/components/Profile";
import LtiLogin from "./student/components/lti/LtiLogin";
import { UserContextProvider } from "./shared/contexts/UserContext";
import ErrorBoundary from "./shared/ErrorBoundary";
import QRCodeSolutions from "./shared/QRCodeSolutions";
import { DMScriptsContextProvider } from "./shared/contexts/DMScriptsContext";
import UpgradeLicenseQuote from "./manager/components/renewal-landing/UpgradeLicenseQuote";
import {
  REACT_APP_ADMIN_LINK,
  REACT_APP_LEARNER_LINK,
  REACT_APP_MANAGER_LINK,
  REACT_APP_NEWTEACHER_LINK,
  REACT_APP_NOTES_LINK,
  REACT_APP_STUDENT_LINK,
  REACT_APP_URL_PREFIX,
} from "./utils";
import { HomePageSwitcher } from "./learner/components/Home/HomePage";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    tracesSampleRate: 0.01,
    ignoreErrors: [
      "Network Error",
      "Request aborted",
      "Request failed with status code",
      "top.GLOBALS",
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      "fb_xd_fragment",
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      "conduitPage",
    ],
    denyUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^https:\/\/www.deltamath.com\/app\/manager\/assets/i,
      /^https:\/\/www.deltamath.com\/app\/manager\/global/i,
    ],
  });
}

const queryClient = new QueryClient();

function NoMatch() {
  const location = useLocation();
  const newLocation = location.pathname.replace(/\/\//g, "/");
  if (newLocation !== location.pathname) {
    return <Navigate to={newLocation} />;
  } else {
    reportError({ message: `URL-NOT-FOUND ${newLocation}` });
    return (
      <div>
        <h2>Sorry, this URL can&apos;t be found!</h2>
      </div>
    );
  }
}

const root = createRoot(document.getElementById("root") as Element);
root.render(
  <QueryClientProvider client={queryClient}>
    <UserContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <ToasterContextProvider>
          <DMScriptsContextProvider baseURL={process.env.PUBLIC_URL}>
            <Routes>
              <Route
                index
                element={<Navigate replace to={`${REACT_APP_ADMIN_LINK}`} />}
              />
              <Route path={`home`} element={<HomePageSwitcher />} />
              <Route path={`help-video/:video_slug`} element={<VideosApp />} />
              <Route
                path={`${REACT_APP_MANAGER_LINK}/*`}
                element={<ManagerApp />}
              />
              <Route
                path={`${REACT_APP_ADMIN_LINK}/*`}
                element={<AdminApp />}
              />
              <Route
                path={`${REACT_APP_NOTES_LINK}/*`}
                element={<NotesApp />}
              />
              <Route
                path={`${REACT_APP_NEWTEACHER_LINK}/*`}
                element={<TeacherApp />}
              />
              <Route
                path={`${REACT_APP_STUDENT_LINK}/qr/:code`}
                element={
                  <ErrorBoundary>
                    <QRCodeSolutions />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${REACT_APP_STUDENT_LINK}/link/:teacherId`}
                element={
                  <ErrorBoundary>
                    <StudentApp section="link" />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${REACT_APP_LEARNER_LINK}/*`}
                element={
                  <ErrorBoundary>
                    <LearnerApp />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${REACT_APP_STUDENT_LINK}/lti/:ltiId`}
                element={
                  <ErrorBoundary>
                    <LtiLogin />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${REACT_APP_STUDENT_LINK}/:sectionId/:teacherId/:skillCode`}
                element={
                  <ErrorBoundary>
                    <StudentApp section="solve" />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${REACT_APP_STUDENT_LINK}/*`}
                element={
                  <ErrorBoundary>
                    <StudentApp section="main" />
                  </ErrorBoundary>
                }
              >
                <Route
                  path="profile"
                  element={
                    <ErrorBoundary>
                      <Profile />
                    </ErrorBoundary>
                  }
                />

                <Route
                  path=":sectionId/"
                  element={<Navigate replace to="upcoming" />}
                />
                <Route
                  path=":sectionId/upcoming/*"
                  element={
                    <ErrorBoundary>
                      <Upcoming />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path=":sectionId/past-due/*"
                  element={
                    <ErrorBoundary>
                      <PastDue />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path=":sectionId/completed/*"
                  element={
                    <ErrorBoundary>
                      <Completed />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path=":sectionId/preview/*"
                  element={
                    <ErrorBoundary>
                      <Preview />
                    </ErrorBoundary>
                  }
                />
              </Route>
              <Route
                path={`${REACT_APP_STUDENT_LINK}/graphingCalculator`}
                element={
                  <ErrorBoundary>
                    <GraphingCalc />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${REACT_APP_URL_PREFIX}/renew/:renewalId`}
                element={
                  <ErrorBoundary>
                    <UpgradeLicenseQuote />
                  </ErrorBoundary>
                }
              ></Route>
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </DMScriptsContextProvider>
        </ToasterContextProvider>
      </BrowserRouter>
    </UserContextProvider>
  </QueryClientProvider>
);
