import { getFilePath } from "../../../utils";
import { SteppedModal } from "../SteppedModal";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useShowOnboarding } from "../../utils/useShowOnboarding";

export const ParentOnboardingModal: React.FC = () => {
  const learnerContext = useLearnerContext();
  const showOnboarding = useShowOnboarding();

  if (!showOnboarding) {
    return null;
  }

  return (
    <SteppedModal
      lastButtonText="Let's Go!"
      onClose={() => learnerContext.updateLearner({ viewedOnboarding: true })}
      steps={[
        {
          title: "Parent Dashboard",
          imageSrcs: [
            getFilePath("/images/learner/parent/onboarding/dashboard.png"),
            getFilePath("/images/learner/parent/onboarding/dashboard@2x.png"),
            getFilePath("/images/learner/parent/onboarding/dashboard@3x.png"),
          ],
          imageAlt: "Screenshot of the dashboard",
          text: (
            <p>
              <strong>
                The parent dashboard is your one-stop shop for tracking your
                learner&apos;s math journey!
              </strong>{" "}
              Easily monitor their progress with detailed insights into their
              points earned, learning streaks, and time spent on DeltaMath. A
              comprehensive graph visually breaks down their weekly activity,
              giving you a clear picture of their engagement, performance, and
              overall progress.
            </p>
          ),
        },
        {
          title: "Course Progress Details",
          imageSrcs: [
            getFilePath("/images/learner/parent/onboarding/progress.png"),
            getFilePath("/images/learner/parent/onboarding/progress@2x.png"),
            getFilePath("/images/learner/parent/onboarding/progress@3x.png"),
          ],
          imageAlt: "Screenshot of the course progress details",
          text: (
            <p>
              <strong>
                Dive deeper into your learner&apos;s specific course progress.
              </strong>{" "}
              See which unit they&apos;re currently working on, track their
              progress within each unit, and view their unit test scores. This
              detailed information gives you visibility into their growth,
              allowing you to celebrate their accomplishments and identify areas
              for additional support.
            </p>
          ),
        },
        {
          title: "Recent Activity",
          imageSrcs: [
            getFilePath("/images/learner/parent/onboarding/activity.png"),
            getFilePath("/images/learner/parent/onboarding/activity@2x.png"),
            getFilePath("/images/learner/parent/onboarding/activity@3x.png"),
          ],
          imageAlt: "Screenshot of the recent activity",
          text: (
            <p>
              <strong>
                Stay connected with your learner&apos;s latest achievements and
                activities.
              </strong>{" "}
              Get an up-to-date view of their test and quiz scores, track
              progress on sections, and see what topics they&apos;re currently
              focused on. It&apos;s a fantastic way to stay involved and provide
              encouragement throughout their learning journey.
            </p>
          ),
        },
        {
          title: "Log In as Learner",
          imageSrcs: [
            getFilePath("/images/learner/parent/onboarding/login.png"),
            getFilePath("/images/learner/parent/onboarding/login@2x.png"),
            getFilePath("/images/learner/parent/onboarding/login@3x.png"),
          ],
          imageAlt: "Screenshot of the learner's interface",
          text: (
            <p>
              <strong>
                Experience DeltaMath from your learner&apos;s perspective.
              </strong>{" "}
              Log in as them to see the exact course material they&apos;re
              working on, practice problems they&apos;re solving, and skills
              they&apos;re mastering. This feature is designed to help you
              better understand their learning experience so you can offer
              support and collaborate on challenges together.
            </p>
          ),
        },
      ]}
    />
  );
};
