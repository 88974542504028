import { useMediaQuery } from "usehooks-ts";
import { FooterCtaNarrow } from "./FooterCtaNarrow";
import { FooterCtaWide } from "./FooterCtaWide";

export const FooterCta: React.FC<{ onCtaClick: () => void }> = ({
  onCtaClick,
}) => {
  const isSmallDevice = useMediaQuery("(max-width : 550px)");
  if (isSmallDevice) {
    return <FooterCtaNarrow onCtaClick={onCtaClick} />;
  }
  return <FooterCtaWide onCtaClick={onCtaClick} />;
};
