import { LearnerAssignment } from "../../../types";
import { ResponsiveImage } from "../../ResponsiveImage";
import { getFilePath } from "../../../../utils";

type Props = {
  assignment: LearnerAssignment | undefined;
  subunitCopy: string;
};

export const PreQuizSummary = (props: Props) => {
  const questionsCorrect = props.assignment?.skills.filter(
    ({ score }) => score > 0
  ).length;
  const durationMins = Math.round((props.assignment?.duration || 0) / 60);

  return (
    <div className="flex flex-grow flex-col justify-center rounded-lg border border-dm-charcoal-100 bg-white px-4 py-6 max-sm:m-4">
      <ResponsiveImage
        className="mx-auto h-auto w-56"
        srcs={[
          getFilePath("/images/learner/prequiz/prequiz-summary-complete.png"),
          getFilePath(
            "/images/learner/prequiz/prequiz-summary-complete@2x.png"
          ),
        ]}
        alt=""
        aria-hidden="true"
      />
      <h4 className="mx-3 mt-8 font-serif text-2xl font-bold">
        {props.assignment?.grade === 1
          ? "Aced it!"
          : "Pre-quiz complete! Let's get practicing."}
      </h4>
      <p className="mx-3 mt-2">
        {props.assignment?.grade === 1 ? (
          <>
            You scored 100% on the pre-quiz! Since you already have a solid
            grasp of this material, the practice and post-quiz are optional.
            Take some extra practice for fun, or move on to the next section -
            it&rsquo;s up to you!
          </>
        ) : (
          <>You finished the pre-quiz for {props.subunitCopy}.</>
        )}
      </p>
      <div className="mx-3 mt-2 flex flex-col justify-center gap-2 font-bold sm:flex-row">
        <div>
          {questionsCorrect || 0} Question{questionsCorrect === 1 ? "" : "s"}{" "}
          Answered Correctly
        </div>
        <div className="hidden text-dm-charcoal-100 sm:block">|</div>
        <div>
          {durationMins + " Minute" + (durationMins === 1 ? "" : "s")} Spent
        </div>
      </div>
    </div>
  );
};
