import { useParams } from "react-router-dom";
import { LearnerAssignment, Skill } from "../../types";
import ProblemSolving from "../Problem/ProblemSolving";
import { useCourseContext } from "../../contexts/CourseContext";
import { ProblemSolvingContextProvider } from "../../contexts/ProblemSolvingContext";
import { ComponentTimer } from "../../utils/useComponentTimer";
import { REACT_APP_LEARNER_LINK } from "../../../utils";

type Props = {
  assignment: LearnerAssignment;
  preQuizAssignment?: LearnerAssignment;
  skills?: Skill[];
  testTimer?: ComponentTimer;
};

const SubunitsSolving = (props: Props) => {
  const courseContext = useCourseContext();
  const { indexOfSkill, submittedTime } = useParams();
  const questionIndex = isNaN(Number(indexOfSkill)) ? 0 : Number(indexOfSkill);

  const pastAssignment = submittedTime
    ? (props.assignment.resultHist || []).find(
        (rh) => Number(new Date(rh.submitted)) === Number(submittedTime)
      )
    : undefined;

  const versionIndex =
    pastAssignment && props.assignment.resultHist && submittedTime
      ? props.assignment.resultHist.indexOf(pastAssignment)
      : undefined;

  const assignmentSkills = pastAssignment
    ? pastAssignment.skills
    : props.assignment.skills;

  const questionSwap = pastAssignment
    ? pastAssignment?.questionSwap
    : props.assignment?.questionSwap;
  if (
    !assignmentSkills ||
    assignmentSkills.length <= 0 ||
    !assignmentSkills[questionIndex].sk
  ) {
    return (
      <div>{props.assignment.type}: We cant find a skill with that index</div>
    );
  }

  const skillName = props.skills ? props.skills[questionIndex]?.skillName : "";
  const skippedProblem =
    assignmentSkills[questionIndex].record === -1 ? true : undefined;
  // For optional tag, basically if it's undefined we will not show the tag,
  // if it is defined we display required if optional is false otherwise show optional tag
  const optional =
    props.assignment.type !== "practice"
      ? undefined
      : props.preQuizAssignment !== undefined &&
        props.preQuizAssignment.skills[questionIndex].score === 1;

  const courseData = courseContext.getCourseData(props.assignment.courseId);
  const unitData = courseContext.getUnitData(
    props.assignment.unitId,
    undefined
  );
  const subunitData = courseContext.getSubunitData(
    props.assignment.subunitId,
    undefined,
    undefined
  );

  const coursePath = courseData?.path || props.assignment.courseId;
  const unitPath = unitData?.path || props.assignment.unitId;
  const subunitPath = subunitData?.path || props.assignment.subunitId;

  const baseUrl =
    props.assignment.type === "unitTest"
      ? `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/unittest`
      : props.assignment.type === "courseTest"
      ? `${REACT_APP_LEARNER_LINK}/${coursePath}/coursetest`
      : `${REACT_APP_LEARNER_LINK}/${coursePath}/${unitPath}/${subunitPath}`;

  const skillCourseData = courseContext.getCourseData(
    props.skills?.[questionIndex].courseId
  );
  const skillUnitData = courseContext.getUnitData(
    props.skills?.[questionIndex].unitId,
    undefined
  );
  const skillSubunitData = courseContext.getSubunitData(
    props.skills?.[questionIndex].subunitId,
    undefined,
    undefined
  );

  const skillCoursePath =
    skillCourseData?.path || props.skills?.[questionIndex].courseId;
  const skillUnitPath =
    skillUnitData?.path || props.skills?.[questionIndex].unitId;
  const skillSubunitPath =
    skillSubunitData?.path || props.skills?.[questionIndex].subunitId;

  const skillPracticeUrl =
    props.assignment.type === "unitTest" ||
    props.assignment.type === "courseTest"
      ? `${REACT_APP_LEARNER_LINK}/${skillCoursePath}/${skillUnitPath}/${skillSubunitPath}/practice/${props.skills?.[questionIndex].position}`
      : undefined;
  return (
    <ProblemSolvingContextProvider
      skillId={assignmentSkills[questionIndex]._id}
      assignment={props.assignment}
      versionIndex={versionIndex}
      questionSwap={questionSwap}
    >
      <ProblemSolving
        sk={assignmentSkills[questionIndex].sk}
        skippedProblem={skippedProblem}
        skillIndex={questionIndex}
        optional={optional}
        skillName={skillName}
        postQuizOrTest={
          props.assignment.type !== "practice" &&
          props.assignment.type !== "preQuiz"
        }
        baseUrl={baseUrl}
        skillPracticeUrl={skillPracticeUrl}
        testTimer={props.testTimer}
      />
    </ProblemSolvingContextProvider>
  );
};

export default SubunitsSolving;
