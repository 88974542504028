import React, { useState } from "react";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import "../../shared/DatePicker/index.css";
import { useDMQuery } from "../../utils";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { compact, round } from "lodash";

const months: Record<number, string> = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const years = [
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
  2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
];

const states: { [key: string]: string }[] = [
  { key: "ALL", name: "All States" },
  { key: "AL", name: "Alabama" },
  { key: "AK", name: "Alaska" },
  { key: "AZ", name: "Arizona" },
  { key: "AR", name: "Arkansas" },
  { key: "CA", name: "California" },
  { key: "CO", name: "Colorado" },
  { key: "CT", name: "Connecticut" },
  { key: "DE", name: "Delaware" },
  { key: "FL", name: "Florida" },
  { key: "GA", name: "Georgia" },
  { key: "HI", name: "Hawaii" },
  { key: "ID", name: "Idaho" },
  { key: "IL", name: "Illinois" },
  { key: "IN", name: "Indiana" },
  { key: "IA", name: "Iowa" },
  { key: "KS", name: "Kansas" },
  { key: "KY", name: "Kentucky" },
  { key: "LA", name: "Louisiana" },
  { key: "ME", name: "Maine" },
  { key: "MD", name: "Maryland" },
  { key: "MA", name: "Massachusetts" },
  { key: "MI", name: "Michigan" },
  { key: "MN", name: "Minnesota" },
  { key: "MS", name: "Mississippi" },
  { key: "MO", name: "Missouri" },
  { key: "MT", name: "Montana" },
  { key: "NE", name: "Nebraska" },
  { key: "NV", name: "Nevada" },
  { key: "NH", name: "New Hampshire" },
  { key: "NJ", name: "New Jersey" },
  { key: "NM", name: "New Mexico" },
  { key: "NY", name: "New York" },
  { key: "NC", name: "North Carolina" },
  { key: "ND", name: "North Dakota" },
  { key: "OH", name: "Ohio" },
  { key: "OK", name: "Oklahoma" },
  { key: "OR", name: "Oregon" },
  { key: "PA", name: "Pennsylvania" },
  { key: "RI", name: "Rhode Island" },
  { key: "SC", name: "South Carolina" },
  { key: "SD", name: "South Dakota" },
  { key: "TN", name: "Tennessee" },
  { key: "TX", name: "Texas" },
  { key: "UT", name: "Utah" },
  { key: "VT", name: "Vermont" },
  { key: "VA", name: "Virginia" },
  { key: "WA", name: "Washington" },
  { key: "WV", name: "West Virginia" },
  { key: "WI", name: "Wisconsin" },
  { key: "WY", name: "Wyoming" },
];

const StateTaxReport = () => {
  const todaysDate = new Date();
  const lastMonth = addMonths(new Date(), -1);

  const [startMonth, setStartMonth] = useState<number>(lastMonth.getMonth());
  const [startYear, setStartYear] = useState<number>(lastMonth.getFullYear());
  const [endMonth, setEndMonth] = useState<number>(todaysDate.getMonth());
  const [endYear, setEndYear] = useState<number>(todaysDate.getFullYear());
  const [state, setFilterState] = useState<string>("NY");
  const [includeCity, setIncludeCity] = useState<boolean>(false);
  const [includeZip, setIncludeZip] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);

  const { data, refetch } = useDMQuery({
    path: "/manager_new/invoices/state_tax_info",
    customerServiceHeader: true,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
    params: {
      state: state,
      startDate: startOfMonth(new Date().setFullYear(startYear, startMonth, 1)),
      endDate: endOfMonth(new Date().setFullYear(endYear, endMonth)),
      includeCity,
      includeZip,
    },
  });

  const activeYears = years.filter((year) => year <= todaysDate.getFullYear());

  const monthKeys = Object.keys(months);

  const onClick = () => {
    refetch();
    setDirty(false);
  };

  const columns = compact([
    {
      Header: "County",
      accessor: "county",
    },
    {
      Header: "Tax Code",
      accessor: "taxCode",
    },
    includeCity
      ? {
          Header: "City",
          accessor: "city",
        }
      : undefined,
    includeZip
      ? {
          Header: "Zip",
          accessor: "zip",
        }
      : undefined,
    {
      Header: "Sale",
      accessor: "sale",
    },
  ]);

  const allStateColumns = [
    {
      Header: "State",
      accessor: "state",
      Cell: (props: any) => {
        const state = states.find((st) => st.key === props.cell.value);
        return <>{!state ? props.cell.value : state.name}</>;
      },
    },
    {
      Header: "Total Sales",
      accessor: "totalSales",
      Cell: (props: any) => {
        return <>{round(props.cell.value, 2)}</>;
      },
    },
    {
      Header: "Total Collected",
      accessor: "total",
      Cell: (props: any) => {
        console.log("TOTAL", props.cell.value);
        return <>{round(props.cell.value, 2)}</>;
      },
    },
    {
      Header: "# of Transactions",
      accessor: "number",
    },
  ];

  return (
    <div className="px-4 pt-4">
      <h2 className="text-xl font-bold">Tax Report</h2>{" "}
      <div className="mt-4 flex">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Start Date:
          </label>
          <select
            className="border-gray-300 md:rounded-md"
            id="start_month"
            name="Start Month"
            value={startMonth}
            onChange={(e) => {
              setStartMonth(Number(e.target.value));
              setDirty(true);
            }}
          >
            {monthKeys.map((m) => {
              return (
                <option key={m} value={`${m}`}>
                  {months[Number(m)]}
                </option>
              );
            })}
          </select>
          <select
            className="border-gray-300 md:rounded-md"
            id="start_year"
            name="Start Year"
            value={startYear}
            onChange={(e) => {
              setStartYear(Number(e.target.value));
              setDirty(true);
            }}
          >
            {activeYears.map((year) => {
              return (
                <option key={year} value={`${year}`}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            End Date:
          </label>
          <select
            className="border-gray-300 md:rounded-md"
            id="end_month"
            name="End Month"
            value={endMonth}
            onChange={(e) => {
              setEndMonth(Number(e.target.value));
              setDirty(true);
            }}
          >
            {monthKeys.map((m) => {
              return (
                <option key={m} value={`${m}`}>
                  {months[Number(m)]}
                </option>
              );
            })}
          </select>
          <select
            className="border-gray-300 md:rounded-md"
            id="end_year"
            name="End Year"
            value={endYear}
            onChange={(e) => {
              setEndYear(Number(e.target.value));
              setDirty(true);
            }}
          >
            {activeYears.map((year) => {
              return (
                <option key={year} value={`${year}`}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            State:
          </label>
          <select
            className="border-gray-300 md:rounded-md"
            id="state"
            name="State"
            value={state}
            onChange={(e) => {
              setFilterState(e.target.value);
              setDirty(true);
            }}
          >
            {states.map((st) => {
              return (
                <option key={st.key} value={st.key}>
                  {st.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="ml-2 flex items-center gap-1">
          <label className="block text-sm font-medium text-gray-700">
            Include City:
          </label>
          <input
            type="checkbox"
            title="Include City"
            checked={includeCity}
            onChange={(e) => {
              setIncludeCity(e.target.checked);
              setDirty(true);
            }}
          />
        </div>
        <div className="ml-2 flex items-center gap-1">
          <label className="block text-sm font-medium text-gray-700">
            Include Zip:
          </label>
          <input
            type="checkbox"
            title="Include Zip"
            checked={includeZip}
            onChange={(e) => {
              setIncludeZip(e.target.checked);
              setDirty(true);
            }}
          />
        </div>
        <div className="flex align-bottom">
          <button
            className={
              "ml-16 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            }
            onClick={onClick}
          >
            Run Report
          </button>
        </div>
      </div>
      {data && (
        <>
          {state !== "ALL" && (
            <div>
              <h4>Totals</h4>
              <div className="flex gap-4">
                <h5>Total collected: {round(data.total, 2)}</h5>
                <h5>Total sales: {round(data.totalSales, 2)}</h5>
              </div>
            </div>
          )}

          <div>
            <DeltaMathTable
              columns={state === "ALL" ? allStateColumns : columns}
              data={
                dirty
                  ? []
                  : state === "ALL" && !data?.districtSales
                  ? data
                  : data.districtSales
                  ? data.districtSales
                  : []
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default StateTaxReport;
