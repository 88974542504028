import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  EXPIRATION_OCTOBER_2024,
  EXPIRATION_OCTOBER_2024_EXTENDED,
  deltamathAPI,
} from "../manager/utils";
import { useDeltaToastContext } from "../shared/contexts/ToasterContext";
import { useUserContext } from "../shared/contexts/UserContext";
import { getAdminParams } from "./utils";
import { useDMQuery } from "../utils";
import { EXCLUSION_LIST } from "../shared/constants";

export function RenewalBanner({
  schoolData,
  districtData,
}: {
  schoolData: any;
  districtData: any;
}) {
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const adminParams = getAdminParams();

  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [showRenewalLink, setShowRenewalLink] = useState<boolean>(false);
  const [expires, setExpires] = useState<any>(false);
  const [showExtendOption, setShowExtendOption] = useState(false);
  const [extending, setExtending] = useState(false);

  const now = new Date().getTime() / 1000;

  // TODO: include the extendLicense logic on Sept 23 (needs to be tested/possibly updated!)
  const extendLicense = useMutation(
    () => {
      setExtending(true);
      const customer_service_token = localStorage.getItem(
        "customer_service_token"
      );
      const token = userContext.getJWT();
      return axios.post(
        deltamathAPI() + "/admin_new/license/extend",
        customer_service_token,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        // Look in here for query invalidation
        queryClient.invalidateQueries("/admin_new/data/school");
        queryClient.invalidateQueries("/admin_new/data/district");
        queryClient.invalidateQueries("/admin_new/data/ltiCredentials");

        toastContext.addToast({
          status: "Success",
          message: "Your license has been extended.",
        });
        setShowBanner(false);
        setShowExtendOption(false);
      },
      onError() {
        setExtending(false);
        toastContext.addToast({
          status: "Error",
          message: "Please contact DeltaMath for assistance.",
        });
      },
    }
  );

  const { data: renewalLinkData, isSuccess: renewalLinkIsSuccess } = useDMQuery(
    {
      path: "/admin_new/data/renewal_link",
      queryOptions: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
        enabled: showRenewalLink, // only attempt to get the data under certain conditions
      },
    }
  );

  const onExclusionList = (
    isDistrictAdmin: boolean,
    isSchoolAdmin: boolean
  ) => {
    if (isDistrictAdmin && districtData) {
      if (Object.hasOwn(EXCLUSION_LIST, districtData.districtID)) return true;
    } else if (isSchoolAdmin && schoolData) {
      const exclusionSchool = schoolData.find((school: any) =>
        Object.hasOwn(EXCLUSION_LIST, school.schoolid)
      );
      if (exclusionSchool) return true;
    }
    return false;
  };

  useEffect(() => {
    const isDistrictAdmin = adminParams.account_type?.includes("district");
    const isSchoolAdmin = adminParams.account_type?.includes("school");

    if (!isDistrictAdmin && !isSchoolAdmin) return;

    let expiration: undefined | number;

    if (isDistrictAdmin && districtData) {
      // Edge case 1: There is a district admin where there is only one school in the district covered by the license.
      // It is possible that they renewed on a school license, in which case they should not see the banner.
      const schoolsCoveredByDistrictLicense = schoolData?.filter(
        (school: any) => school.coveredByDistrictLicense
      );
      if (schoolsCoveredByDistrictLicense.length === 1) {
        const schoolExp =
          schoolsCoveredByDistrictLicense[0].dmLicense?.expiration;
        if (schoolExp && schoolExp > EXPIRATION_OCTOBER_2024) return;
      }

      expiration = districtData.dmLicense?.expiration
        ? parseInt(districtData.dmLicense.expiration)
        : undefined;
    } else if (isSchoolAdmin && schoolData) {
      // Edge case 2: Don't show a banner to a school admin for where the school has a district license
      const schoolCoveredByDistrictLicense = schoolData.find(
        (school: any) => school.coveredByDistrictLicense
      );
      if (schoolCoveredByDistrictLicense) return;

      const allExpirations = schoolData
        .filter((s: any) => s.dmLicense?.expiration !== undefined)
        .map((s: any) => parseInt(s.dmLicense.expiration));

      expiration = allExpirations.length
        ? Math.max(...allExpirations)
        : undefined;
    }

    if (expiration && expiration <= EXPIRATION_OCTOBER_2024) {
      setShowBanner(true);
      const expirationToSet = new Date(expiration * 1000).toLocaleDateString(
        "en-US"
      );
      setExpires(expirationToSet);
      // Edge case 3: Only attempt to retrieve renewal link if the NCES is NOT on the exclusion list
      if (!onExclusionList(isDistrictAdmin, isSchoolAdmin))
        setShowRenewalLink(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtData, schoolData, adminParams]);

  if (!showBanner || (showRenewalLink && !renewalLinkIsSuccess)) return <></>;

  return (
    <>
      <div className="relative bg-sky-400">
        <div className="px-3 py-3 sm:ml-0 sm:px-6 md:ml-64 lg:px-8">
          <div className="text-center font-medium text-white sm:px-16">
            <p>
              Your license {expires > now ? "expired " : "expires "}
              on {expires}.
              {/* TODO: Extend should be viewable 9/23 (code has not been updated since last year, needs review and testing) */}
              {/* {showExtendOption && ( 
                <span className="block sm:ml-2 sm:inline-block">
                  <button
                    className="font-bold underline hover:cursor-pointer"
                    onClick={() => {
                      // Stopping users from being able to spam click the banner
                      if (!extending) {
                        extendLicense.mutate();
                      }
                    }}
                  >
                    Click here to extend
                  </button>{" "}
                  your license through October 15.
                </span>
              )} */}
            </p>
            {showRenewalLink &&
            renewalLinkData?.success &&
            renewalLinkData?.link ? (
              <p>
                {"Click "}
                <a
                  href={renewalLinkData.link}
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold underline"
                >
                  here
                </a>{" "}
                to see your renewal options. Email{" "}
                <a
                  className="font-bold underline"
                  href="mailto:orders@deltamath.com"
                >
                  orders@deltamath.com
                </a>{" "}
                with any questions.
              </p>
            ) : (
              <p>
                Email{" "}
                <a
                  className="font-bold underline"
                  href="mailto:orders@deltamath.com"
                >
                  orders@deltamath.com
                </a>{" "}
                for a renewal quote or if you have any questions.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
