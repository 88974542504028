import { intersection } from "lodash";

// const FIRST_PRORATED_DISCOUNT_23_24 = 0.4;
// const SECOND_PRORATED_DISCOUNT = 0.65; // update on 3/15/24 ?

export const currentFullYear = "24-25 Full Year";

/**
 * Describes the type of renewal actions which exist.
 */
export type IRenewalType =
  | "school"
  | "individual"
  | "9-12"
  | "6-12"
  | "6-8"
  | "7-12"
  | "7-8"
  | "8-12"
  | "school_custom";

// const PRICE_SCHEME: {
//   [key: string]: {
//     [key: string]: {
//       [key: string]: number;
//     };
//   };
// } = {
//   "23-24 Prorated": {
//     Individual: {
//       Plus: 55,
//       Integral: 85,
//     },
//     per_student: {
//       Plus: 1,
//       Integral: 2,
//     },
//     minimum: {
//       Plus: 300,
//       Integral: 500,
//     },
//   },
//   "24-25 Full Year": {
//     Individual: {
//       Plus: 110,
//       Integral: 170,
//     },
//     per_student: {
//       Plus: 1.15,
//       Integral: 2.3,
//     },
//     minimum: {
//       Plus: 350,
//       Integral: 600,
//     },
//   },
// };

export type PriceSchemeYear = "23-24 Prorated" | "24-25 Full Year";

export type TLicenseType = //reflects quoted products, not necessarily every license option possible

    | "District License (Grades 6-12)"
    | "District License (Grades 7-12)"
    | "District License (Grades 9-12)"
    | "District License (Grades 6-8)"
    | "District License (Grades 7-8)"
    | "School License"
    | "Individual License";

export type TGradeRange = "6-12" | "7-12" | "9-12" | "6-8" | "7-8"; //for quoted district products

export const districtLicenseQuoteTypes = ["6-12", "7-12", "9-12", "6-8", "7-8"]; //reflects quoted products, not necessarily every license option possible

export interface IDistrictData {
  districtID: string;
  dmLicense: {
    emailDomains: string[];
    license_type: "6-8" | "6-12" | "9-12" | "8-12" | "7-12" | "7-8"; // but we make exceptions
    num_students: number;
    num_teachers?: number;
    invoice: number;
    expiration: number;
    revenue: number;
    integrations: string;
    note?: string;
    trial_6_8?: boolean;
    has_integral?: boolean;
    hide_admin_portal?: boolean;
    force_show_admin_portal?: boolean;
    is_trial?: boolean;
  };
  districtName: string;
  match: {
    name: string;
    email: string;
  };
  super_admin: {
    email: string;
    name: string;
  };
  nces_flag?: boolean;
  nces_notes?: string;
  invoiceNumber?: string;
}

export interface ISchoolPopData {
  students: any;
  lowGrade: any;
  highGrade: any;
  name: string;
  nces_id: string;
  numberOfTeachers: number;
  super_admin: {
    email: string;
    name: string;
  };
  invoiceNumber?: string;
  coveredByDistLicense?: boolean;
}

export interface ISchoolData {
  /** Unique schoolid of the school, typically an nces id but in some cases may be driven by a classlink or clever integration. */
  schoolid: string;
  /** Name of the school, when combined with address.zip is considered unique. */
  schoolName: string;
  /** The customer id in QuickBooks (where applicable) */
  qboCustomerId?: number;

  phone?: string;
  url?: string;
  urlCompare?: string;
  address?: {
    street: string;
    city: string;
    state: string;
    stateFull?: string;
    zip: string;
    zip4?: string;
    cityURL?: string;
    zipURL?: string;
    html?: string;
  };
  lowGrade: string;
  highGrade: string;
  schoolLevel?: string;
  isCharterSchool?: string;
  isMagnetSchool?: string;
  isVirtualSchool?: string;
  isTitleISchool?: string;
  isTitleISChoolwideSchool?: string;
  district: {
    districtID: string;
    districtName: string;
    url?: string;
    rankURL?: string;
  };
  county?: {
    countyName: string;
    countyURL: string;
  };
  rankMovment?: number;
  isPrivate?: boolean;
  privateDays?: any;
  privateHours?: any;
  privateHasLibrary?: any;
  privateCoed?: any;
  privateOrientation?: any;
  dmLicense: {
    num_students?: number;
    num_teachers?: number;
    license_type?: string;
    invoice?: number;
    revenue?: number;
    expiration?: number;
    integrations?: string;
    domains: string[];
    note?: string;
    trial_6_8?: boolean;
    has_integral?: boolean;
    hide_admin_portal?: boolean;
    force_show_admin_portal?: boolean;
    is_trial?: boolean;
  };
  dmLicenseHistory?: ISchoolData["dmLicense"][];
  quoteStatus22?: {
    hasQuote: boolean;
    totalTeachers: number;
    totalLogs: number;
    logsPerTeacher: number;
  };
  timezone: string;
  rostering_ids?: string[];
  nces_flag?: boolean;
  nces_notes?: string;
}

export interface IPriceAndDiscountInfo {
  price: {
    plus: number;
    integral: number;
  };
  numberOfStudents?: number;
  discount: number;
  priceWithDiscount: {
    plus: number;
    integral: number;
  };
  minPrice?: {
    plus: number;
    integral: number;
  };
  schools?: ISchoolPopData[];
  licenseType?: TLicenseType;
  sendCustomerEmail?: boolean;
}

export type LicenseTree = {
  District: {
    districtID: string;
    districtName?: string;
    numberAdmins?: number;
    dmLicense?: IDistrictData["dmLicense"];
    match?: {
      name?: string;
      email?: string;
    };
    super_admin?: {
      name?: string;
      email?: string;
    };
    nces_flag?: any;
    nces_notes?: string;
    quoteNumber?: any;
  };
  Schools: Array<{
    schoolid: string;
    schoolName?: string;
    numberTeachers?: number;
    numberAdmins?: number;
    dmLicense?: ISchoolData["dmLicense"];
    match?: {
      name?: string;
      email?: string;
    };
    super_admin?: {
      name?: string;
      email?: string;
    };
    admin?: {
      name?: string;
      email?: string;
    };
    nces_flag?: any;
    nces_notes?: string;
    quoteNumber?: any;
  }>;
};

export interface LicenseInfo {
  type: string;
  id: string;
  licenseTier: "Plus" | "Integral";
  expiration: number;
  integrations: string;
  schoolAndDistLicense?: {
    licenseTier: "Plus" | "Integral";
    integrations: string;
    expiration: number;
  };
  admin?: { name: string; email: string };
}

export const DISTRICT_LICENSE_TO_GRADES: {
  //reflects quoted products, not necessarily every license option possible
  [key: string]: { low: number; high: number };
} = {
  "District License (Grades 6-12)": {
    low: 6,
    high: 12,
  },
  "District License (Grades 7-12)": {
    low: 7,
    high: 12,
  },
  "District License (Grades 9-12)": {
    low: 9,
    high: 12,
  },
  "District License (Grades 6-8)": {
    low: 6,
    high: 8,
  },
  "District License (Grades 7-8)": {
    low: 7,
    high: 8,
  },
};

export function getSchoolLowHigh(school: any): { low: number; high: number } {
  /**
   * returns the lowest and highest grades a school encompasses
   */
  let low = school.lowGrade;
  if (low === "K") low = 0;
  else if (low === "PK") low = -1;
  else low = parseInt(low);
  if (isNaN(low)) low = 6;

  let high = school.highGrade;
  if (high === "K") high = 0;
  else if (high === "PK") high = -1;
  else high = parseInt(school.highGrade);
  if (isNaN(high)) high = 12;
  return { low, high };
}

export function getStudentGradesCounts(schools: any[]): {
  elemTotal: number;
  middleTotal: number;
  highTotal: number;
  studentsTotal: number;
} {
  let elemTotal = 0;
  let middleTotal = 0;
  let highTotal = 0;
  let studentsTotal = 0;
  for (const org of schools) {
    org.students = parseInt(org.students);
    if (org.students) studentsTotal += org.students;
    else continue;

    let low = org.lowGrade;
    if (low === "K") low = 0;
    else if (low === "PK") low = -1;
    else low = parseInt(low);
    if (isNaN(low)) low = 6;
    let high = parseInt(org.highGrade);
    if (isNaN(high)) high = 12;

    // relative proportion in PK-5, 6-8, 9-12
    let weight1 = 0;
    let weight2 = 0;
    let weight3 = 0;
    let totalWeight = 0;
    for (let i = low; i <= high; i++) {
      totalWeight++;
      if (i <= 5) weight1++;
      else if (i <= 8) weight2++;
      else weight3++;
    }
    elemTotal += Math.round((weight1 / totalWeight) * org.students);
    middleTotal += Math.round((weight2 / totalWeight) * org.students);
    highTotal += Math.round((weight3 / totalWeight) * org.students);
  }
  return { elemTotal, middleTotal, highTotal, studentsTotal };
}

export function filterSchoolsByGrade(
  schools: any[],
  licenseType: TLicenseType
) {
  const filteredSchools: ISchoolPopData[] = [];
  schools?.forEach((school: ISchoolPopData) => {
    const { low, high } = getSchoolLowHigh(school);
    // check if school overlaps with license, if so add it to filteredSchools
    if (licenseType === "School License") filteredSchools.push(school);
    else {
      const gradeRange = DISTRICT_LICENSE_TO_GRADES[licenseType];
      const schoolInRange =
        (low >= gradeRange.low && low <= gradeRange.high) ||
        (high <= gradeRange.high && high >= gradeRange.low) ||
        (low < gradeRange.low && high > gradeRange.high);

      if (schoolInRange) filteredSchools.push(school);
    }
  });
  return filteredSchools;
}

export type TLicenseRange =
  | TGradeRange
  | "school"
  | "school_custom"
  | "individual";

export const getLicenseDescription = (
  type: TLicenseRange | undefined,
  tier: "plus" | "integral" | undefined
): `${TLicenseType} - ${"Plus" | "Integral"}` | "" => {
  if (!type || !tier) return "";
  const capTier: "Plus" | "Integral" = tier === "plus" ? "Plus" : "Integral";
  return type === "school"
    ? `School License - ${capTier}`
    : `${rangeToFullType[type] as TLicenseType} - ${capTier}`;
};

// export interface ILicenseOptions {
//   current: TLicenseOption;
//   recommended_upgrade: TLicenseOption | null;
//   entire_district: TLicenseOption[] | null;
// }

export interface IRenewalData {
  schoolYear: string;
  licenseName: string;
  nces: string;
  type: TLicenseRange;
  licenseType: TLicenseType;
  hasIntegral: boolean;
  licenseOptions: Array<[TLicenseOption, TLicenseOption] | TLicenseOption>;
  integrations: TIntegration;
  integral_trial?: boolean;
}

export interface TLicenseOption {
  type: TLicenseType;
  tier: "Plus" | "Integral";
  price: number;
  pathId: any;
}

export type TIntegration =
  | "none"
  | "clever"
  | "classlink/oneroster"
  | "schoology"
  | "canvas"
  | "classlink";

export interface IRenewalRequestBody {
  type: TLicenseType;
  licenseTier: "Plus" | "Integral";
  rosteringSystem?:
    | "ClassLink/OneRoster"
    | "Clever"
    | "Schoology"
    | "Canvas"
    | "None"
    | "";
  learningManagementSystem?: "Schoology" | "Canvas" | "None" | "";
  renewalToken: string;
  currentLicenseType: TLicenseType;
  currentHasIntegral: boolean;
  pathId: any;
}

export const rangeToFullType: {
  "6-12": "District License (Grades 6-12)";
  "7-12": "District License (Grades 7-12)";
  "9-12": "District License (Grades 9-12)";
  "6-8": "District License (Grades 6-8)";
  "7-8": "District License (Grades 7-8)";
  school: "School License";
  school_custom: "School License";
  individual: "Individual License";
  reverseLookup: (type: TLicenseType) => TGradeRange;
} = {
  "6-12": "District License (Grades 6-12)",
  "7-12": "District License (Grades 7-12)",
  "9-12": "District License (Grades 9-12)",
  "6-8": "District License (Grades 6-8)",
  "7-8": "District License (Grades 7-8)",
  school: "School License",
  school_custom: "School License",
  individual: "Individual License",
  reverseLookup: (type: TLicenseType): TGradeRange =>
    (Object.keys(rangeToFullType) as Array<keyof typeof rangeToFullType>).find(
      (key) => (rangeToFullType[key] as TLicenseType) === type
    ) as TGradeRange,
};

export const getGradeScope = (type: TLicenseType): string => {
  const match = type.match(/Grades \d+-\d+/);
  if (match) {
    return match[0];
  } else {
    return "";
  }
};

export const rosteringFullNames: {
  [key in TIntegration]:
    | "ClassLink/OneRoster"
    | "Clever"
    | "Schoology"
    | "Canvas"
    | "None";
} = {
  none: "None",
  clever: "Clever",
  "classlink/oneroster": "ClassLink/OneRoster",
  classlink: "ClassLink/OneRoster",
  schoology: "Schoology",
  canvas: "Canvas",
};

export const lmsFullNames: {
  [key in "none" | "schoology" | "canvas"]: "Schoology" | "Canvas" | "None";
} = {
  none: "None",
  schoology: "Schoology",
  canvas: "Canvas",
};

export enum RenewalActionType {
  VIEW_PAGE = "view page",
  DOWNLOAD_QUOTE = "download quote",
  DM_QUOTE_MACHINE = "dm quote machine",
  USER_QUOTE_MACHINE = "user quote machine",
  DM_QUOTE_SCRIPT = "deltamath quote (auto)",
  USER_QUOTE_SCRIPT = "user quote (auto)",
}

export type MailListEntry = {
  /** The link to the renewal page. */
  link: string;
  /** The email to whom the link should be sent. */
  email: string;
  /** The name of the customere (ie. the school name). */
  siteName?: string;
  /** The state where the site is located in */
  state?: string;
  /** The first name of the contact receiving the email (if known). */
  firstName?: string;
};

export type NotQualifiedRenewalReason =
  | "snn"
  | "individual-snn"
  | "8-12"
  | "ncesFlag"
  | "sendCustomerEmailFlag";

export type InvalidMailListEntry = MailListEntry & {
  /** The reasons why the renewal does not qualify for a renewal email. */
  reasons: NotQualifiedRenewalReason[];
  /** The optional array of reasons why the sendCustomerEmailFlag is present */
  sendCustomerEmailFlagReasons?: string[];
};

export type LicenseOption = {
  /**
   * The unique identifier for the license option.
   */
  pathId: string;

  /**
   * The type of license.
   */
  type: TLicenseType;
  /**
   * The tier of the license.
   */
  tier: "Integral" | "Plus";
  /**
   * The price of the license.
   */
  price: number;
  /**
   * The nces id of the school or district.
   */
  nces: string;
  /**
   * The name of the school or district.
   */
  name: string;
  /**
   * The address of the school or district.
   */
  address: string;
  /**
   * Optional flag to indicate if a customer email would be sent.
   */
  sendCustomerEmail?: boolean;
  /**
   * Optional reason why a customer email would not be sent.
   */
  noCustomerEmailReason?: string;

  /**
   * Optional flag to indicate if the entry in the upgrade path is the same as their current license.
   */
  isCurrentLicense?: boolean;
};

export type PlusLicenseOption = Array<[LicenseOption, LicenseOption]>;

export type IntegralLicenseOption = Array<LicenseOption>;

export type LicenseOptions = PlusLicenseOption | IntegralLicenseOption;

export interface IRenewal {
  _id: string;
  schoolYear: string;
  licenseName: string;
  nces: string;
  type: TLicenseRange;
  weight: number;
  coveredSchools?: string[];
  contacts: string[];
  renewalWeight: number;
  hasIntegral?: boolean;
  integrations?: TIntegration;
  individualPlus?: number;
  individualIntegral?: number;
  quotes?: string[];
  renewalQuoteMetaData?: Array<{
    email: string;
    when: Date;
    action: RenewalActionType;
    nces: string;
    weight: number;
    hasIntegral?: boolean;
    individualPlus?: number;
    individualIntegral?: number;
  }>;
  renewalCleared?: boolean;
  renewalClearedTime?: Date;
  renewalPlusDowngrade?: boolean;
  renewalPlusDowngradeCleared?: boolean;
  renewalGradeDowngrade?: boolean;
  renewalGradeDowngradeCleared?: boolean;
  nonRenewalConfirmed?: boolean;
  renewalNotes?: string;
  newCustomerFlag?: boolean;
  links?: Array<MailListEntry | InvalidMailListEntry>;
  is_trial?: boolean;
  upgradePath?: LicenseOptions;
  customerName?: string;
  createdAt: Date;
  updatedAt: Date;
  manuallyClearedBy?: { email: string; time: number };
  displayName?: string; //for front-end only - used in table view
  autoCleared?: boolean; //for front-end only - used to disable unchecking of cleared renewals
}

export const getIndividualQuoteMachineLink = (params: {
  email: string;
  nces: string;
  license?: "Plus" | "Integral" | "";
  licenseCount?: string;
  name?: string;
  noCompare?: string;
}) => {
  const baseURL = `https://deltamath.com/teachers-schools/`;

  const baseParams = {
    createQuote: "true",
    quoteType: "individual",
    lms: "none",
  };

  if (params.license === "") {
    delete params.license;
  }

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value != null)
  );

  const searchParams = new URLSearchParams({
    ...baseParams,
    ...filteredParams,
  });

  return `${baseURL}?${searchParams.toString()}`;
};

export function isSchoolNCES_ID(ncesID: string): boolean {
  return /^[0-9]{12}$/.test(String(ncesID));
}

export function isDistrictNCES_ID(ncesID: string): boolean {
  return /^[0-9]{7}$/.test(String(ncesID));
}

export function getGradesByLicenseType(licenseType: TGradeRange): string[] {
  const GRADES_BY_LICENSE_TYPE = {
    "8-12": ["8", "9", "10", "11", "12"],
    "9-12": ["9", "10", "11", "12"],
    "6-8": ["6", "7", "8"],
    "6-12": ["6", "7", "8", "9", "10", "11", "12"],
    "7-12": ["7", "8", "9", "10", "11", "12"],
    "7-8": ["7", "8"],
    school: [
      "K",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ],
  };
  return GRADES_BY_LICENSE_TYPE[licenseType] || [];
}

export const PossibleGrades = [
  "PK",
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
] as const;

type PossibleGrade = typeof PossibleGrades[number];

export function getGradeRange(
  lowGrade: string,
  highGrade: string
): PossibleGrade[] {
  const lowIndex = PossibleGrades.indexOf(lowGrade as PossibleGrade);
  const highIndex = PossibleGrades.indexOf(highGrade as PossibleGrade);

  if (lowIndex === -1 || highIndex === -1) {
    return [];
  }

  return PossibleGrades.slice(lowIndex, highIndex + 1);
}

//This function will determine whether a given school in would be covered by a given district plan. It uses similar logic to what we are trying to implement in the backend, for instance in the doesLicenseCoverSchool static in the district model. That method is specifically designed to take a current district license and determine whether a given school is covered by it. This function is more general and can be used to determine whether a school is covered by any license type. It is used to help us detect cases where the client is sending a district NCES to get a license tree but the DM license will actually be on a school since the license was converted on activation (b/c there was only one eligible school for the quoted district product)
export function schoolIsCoveredByLicenseType(
  licenseType: TGradeRange,
  school: ISchoolPopData
): boolean {
  const { lowGrade, highGrade } = school;

  if (typeof lowGrade === "undefined" || typeof highGrade === "undefined") {
    return true;
  }

  const coveredGrades = getGradesByLicenseType(licenseType);

  return (
    coveredGrades.includes(lowGrade) ||
    coveredGrades.includes(highGrade) ||
    intersection(coveredGrades, getGradeRange(lowGrade, highGrade)).length > 0
  );
}
