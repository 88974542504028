import clsx from "clsx";
import React, { forwardRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  type?:
    | "primary"
    | "secondary"
    | "outline"
    | "link"
    | "submitAnswer"
    | "points";
  size?: "large" | "standard" | "small" | "xsmall" | "wide";
  submit?: boolean;
  href?: string;
  target?: "_blank";
  className?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  ariaLabel?: string;
  id?: string;
  isLoading?: boolean;
  onMountFunc?: () => void;
};

const Button = forwardRef((props: Props, buttonRef: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.onMountFunc?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.type === "link") {
    /** add a layer of protection so that we don't render a NavLink with an undefined attribute */
    return (
      <>
        {props.href ? (
          <NavLink
            onClick={props.onClick}
            to={props.href ?? ""}
            target={props.target}
            className={clsx(
              "py-2 text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70",
              props.className
            )}
            ref={buttonRef}
          >
            {props.children}
          </NavLink>
        ) : (
          <button
            onClick={props.onClick}
            className={clsx(
              "py-2 text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70",
              props.className
            )}
            ref={buttonRef}
            disabled={props.disabled}
          >
            {props.children}
          </button>
        )}
      </>
    );
  }

  const padding =
    props.size === "xsmall"
      ? "px-4 py-2 leading-6 text-sm"
      : props.size === "small"
      ? "px-8 py-1 leading-6 text-sm"
      : props.size === "large"
      ? "px-8 py-3 leading-6"
      : props.size === "wide"
      ? "px-12 py-2 leading-6"
      : "px-8 py-2 leading-6";
  const styles = props.isLoading
    ? "rounded bg-dm-brand-blue-800 text-white"
    : !props.type || props.type === "primary"
    ? "rounded bg-dm-brand-blue-500 text-white hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800"
    : props.type === "secondary"
    ? "rounded bg-dm-brand-blue-200 hover:bg-dm-brand-blue-600 hover:text-white active:bg-dm-brand-blue-800 disabled:cursor-not-allowed disabled:text-white"
    : props.type === "outline"
    ? "rounded border border-dm-charcoal-800 text-dm-charcoal-800 hover:border-dm-brand-blue-600 hover:text-dm-brand-blue-600 active:bg-dm-charcoal-500 active:text-white"
    : props.type === "submitAnswer"
    ? "submit-answer-form m-1 inline-flex items-center rounded bg-dm-brand-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800"
    : props.type === "points"
    ? "rounded bg-dm-purple-600 text-white hover:bg-dm-purple-500 active:bg-dm-purple-500"
    : "";

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    } else if (props.href) {
      navigate(props.href);
    }
  };

  return (
    <button
      id={props.id}
      disabled={props.disabled || props.isLoading}
      onClick={onClick}
      type={props.submit ? "submit" : undefined}
      aria-label={props.ariaLabel}
      className={clsx(
        padding,
        (props.disabled || props.isLoading) && "cursor-not-allowed",
        props.disabled && "opacity-50",
        styles,
        props.className
      )}
      ref={buttonRef}
    >
      {props.isLoading ? (
        <div className="relative">
          <div className="invisible">{props.children}</div>
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div
              style={{ borderTopColor: "transparent" }}
              className="h-5 w-5 animate-spin rounded-full border-[3px] border-solid border-white"
            ></div>
          </div>
        </div>
      ) : (
        props.children
      )}
    </button>
  );
});
Button.displayName = "GenericButton";

export default Button;
