import { ComponentProps, ReactNode, useState } from "react";
import Modal from "../../student/components/generic/Modal";
import { useStep } from "usehooks-ts";
import { ResponsiveImage } from "./ResponsiveImage";
import { StepIndicator } from "./StepIndicator";

interface Step {
  title: string;
  imageSrcs: ComponentProps<typeof ResponsiveImage>["srcs"];
  imageAlt: string;
  text: ReactNode;
}

export const SteppedModal: React.FC<{
  lastButtonText: string;
  steps: Step[];
  onClose: () => void;
}> = ({ lastButtonText, steps, onClose }) => {
  const [visible, setVisible] = useState(true);

  const maxSteps = steps.length;
  const [stepIndex, { goToNextStep, canGoToNextStep }] = useStep(maxSteps);
  const step = steps[stepIndex - 1];

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onClose={() => setVisible(false)}
      title={
        <span className="-mb-2 block w-full px-4 text-center font-serif text-lg font-bold md:mb-0 md:p-0 md:text-2xl">
          {step.title}
        </span>
      }
      body={
        <div className="flex flex-col">
          <div className="mb-6 flex w-full justify-center md:mb-8">
            <ResponsiveImage srcs={step.imageSrcs} alt={step.imageAlt} />
          </div>
          <div className="text-sm leading-7">{step.text}</div>
          <StepIndicator
            className="mt-6 md:mt-10"
            totalSteps={steps.length}
            currentStep={stepIndex}
          />
        </div>
      }
      secondaryButtonText="Skip"
      secondaryOnClick={() => handleClose()}
      confirmButtonText={canGoToNextStep ? "Next" : lastButtonText}
      onConfirm={() => {
        if (canGoToNextStep) {
          goToNextStep();
        } else {
          handleClose();
        }
      }}
    />
  );
};
