import { useEffect, useState } from "react";
import { useDMQuery } from "../../../utils";
import { LearnerProblemResponse, Problem } from "../../types";
import CustomFileWrapper from "../Problem/CustomFileWrapper";
import { obfuscate } from "../../../student/utils";
import StatusTags from "../Problem/StatusTags";
import Button from "../../../student/components/generic/button";
import PortalModal from "../../../shared/PortalModal";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { previewAssessmentEvent } from "../../analytics/events";
import { useCourseContext } from "../../contexts/CourseContext";

type Props = {
  visible: boolean;
  toggleVisible: (visible: boolean) => void;
  courseId?: string;
  unitId?: string;
  subunitId?: string;
  type: "courseTest" | "practice" | "preQuiz" | "postQuiz" | "unitTest";
  title: string;
  navigateHref: string;
  navigateButtonText: string;
};

const AssignmentPreviewModal = (props: Props) => {
  const [problem, setProblem] = useState<Problem>();
  const [questionNumber, setQuestionNumber] = useState<number>(0);
  const [renderKey, setRerenderKey] = useState<number>(0);
  const courseContext = useCourseContext();
  const { track, getAssignmentData } = useLearnerAnalytics();

  const { data, refetch } = useDMQuery<LearnerProblemResponse[]>({
    path: `/learner/data/previewExamples?courseId=${props.courseId}&type=${
      props.type
    }${props.unitId ? `&unitId=${props.unitId}` : ""}${
      props.subunitId ? `&subunitId=${props.subunitId}` : ""
    }`,
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    // Fetches the preview if we have all the data based on the type and what has been passed in
    if (
      ((props.subunitId &&
        (props.type === "preQuiz" || props.type === "postQuiz")) ||
        (props.unitId && props.type === "unitTest") ||
        (props.courseId && props.type === "courseTest")) &&
      props.visible
    ) {
      refetch();
    }
  }, [
    props.subunitId,
    props.courseId,
    props.unitId,
    props.type,
    props.visible,
  ]);

  const loadProblemToState = (nextIndex: number) => {
    if (data) {
      const probData =
        typeof data[nextIndex].data === "string"
          ? obfuscate(`${data[nextIndex]._id}`).reveal(
              `${data[nextIndex].data}`
            )
          : data[nextIndex].data;

      setProblem({
        ...data[nextIndex],
        lines: data[nextIndex].lines || [],
        data: probData,
      });
      setRerenderKey(renderKey + 1);
    }
  };

  const previousProblem = () => {
    if (questionNumber <= 0) {
      return;
    }
    const previousIndex = questionNumber - 1;

    setQuestionNumber(previousIndex);
    loadProblemToState(previousIndex);
  };

  const nextProblem = () => {
    if (questionNumber >= (data || []).length - 1) {
      return;
    }
    const nextIndex = questionNumber + 1;

    setQuestionNumber(nextIndex);
    loadProblemToState(nextIndex);
  };

  useEffect(() => {
    setRerenderKey(renderKey + 1);
  }, [props.visible]);

  useEffect(() => {
    if (data && !problem && data.length > 0) {
      loadProblemToState(0);
    }
  }, [data]);

  useEffect(() => {
    if (props.visible) {
      const unitData = courseContext.getUnitData(props.unitId, props.courseId);
      const subunitIndex = (unitData?.subunitOrder ?? []).findIndex(
        (o) => o === props.subunitId
      );
      track(
        previewAssessmentEvent({
          ...getAssignmentData(props.type),
          // Don't use sectionNumber from getAssignmentData because we aren't in the subunit here
          sectionNumber: subunitIndex === -1 ? -1 : subunitIndex + 1,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  if (!data) {
    return <></>;
  }

  return (
    <PortalModal
      title={props.title}
      visible={props.visible}
      onClose={() => {
        props.toggleVisible(false);
        setProblem(undefined);
      }}
      largeModal
      footer={
        <div className="flex items-center justify-between">
          <Button type="outline" href={props.navigateHref}>
            {props.navigateButtonText}
          </Button>
          <div className="flex gap-6">
            <Button onClick={previousProblem} disabled={questionNumber <= 0}>
              Previous Example
            </Button>
            <Button
              onClick={nextProblem}
              disabled={questionNumber >= data.length - 1}
            >
              Next Example
            </Button>
          </div>
        </div>
      }
      //   onConfirm={nextProblem}
      //   confirmButtonText="Next Example"
      body={
        <>
          {problem && (
            <CustomFileWrapper
              skillId={""}
              key={`renderKey-${problem._id}-${renderKey}`}
              problem={problem}
              logData={undefined}
              checkAnswer={() => {
                console.log("CHECK ANSWER");
              }}
              isCheckAnswerLoading={false}
              unsubmitAllowed={false}
              showSolutions={false}
              skippedProblem={true}
              renderKey={renderKey}
              inModal
              hideAnswer
              noBorder
              header={
                <StatusTags
                  skillCode={problem.skillcode}
                  logData={undefined}
                  skippedProblem={undefined}
                  questionTitle={`Question ${questionNumber + 1} Example`}
                  solutionShowing={false}
                  assignmentType={props.type}
                />
              }
            />
          )}
        </>
      }
    />
  );
};

export default AssignmentPreviewModal;
