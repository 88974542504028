import { useParams } from "react-router-dom";
import {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { ClockIcon, PlayIcon } from "@heroicons/react/outline";
import {
  SectionDataStatus,
  AssignmentDueDateType,
  SkillDataType,
} from "../_constants";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { renderA11yString } from "./render-a11y-string";
import CryptoJS from "crypto-js";
import { deltamathAPI } from "../../manager/utils";
import renderMathInElement from "./auto-render";
import { isEmpty } from "lodash";
import { REACT_APP_STUDENT_LINK } from "../../utils";
import { EventMap, PageType } from "../../shared/types";
import { PAGE_TYPE_ID_MAP } from "../../shared/constants";

// Find section id from the URL in the sections object
export const getActiveSectionData = (
  activeSection: any,
  sectionsData: any
): any =>
  sectionsData?.find(
    (section: { _id: number }) => section._id == parseInt(activeSection)
  );

const sortUpcomingAssignments = (assignments: any) => {
  if (assignments.length <= 1) {
    return assignments;
  }

  return assignments.sort(
    (a: any, b: any) =>
      new Date(a?.sa?.dueDate.date).getTime() -
      new Date(b?.sa?.dueDate.date).getTime()
  );
};

export const getSectionData = (
  sectionType: AssignmentDueDateType,
  sectionId: any,
  assignmentsData: Array<any>
): Array<any> => {
  const assignments = assignmentsData[sectionId];

  if (typeof assignments == "undefined") return [];

  switch (sectionType) {
    case SectionDataStatus["past-due"]:
      return assignments?.filter(
        (section: any) =>
          section?.sa?.status == SectionDataStatus.late_credit_available ||
          section?.sa?.status == SectionDataStatus.no_late_available
      );

    case SectionDataStatus.completed:
      return assignments?.filter(
        (section: any) => section?.sa?.status == SectionDataStatus.completed
      );

    case SectionDataStatus["preview"]:
      return assignments?.filter(
        (section: any) =>
          section?.sa?.status == SectionDataStatus.sampleStudentOverride
      );

    case SectionDataStatus.upcoming:
    default:
      return sortUpcomingAssignments(
        assignments?.filter(
          (section: any) => section?.sa?.status == SectionDataStatus.upcoming
        )
      );
  }
};

// export const sectionDataSort = (
//   activeSection: any,
//   assignmentsData: any
// ): any => {
//   if (
//     !activeSection ||
//     !assignmentsData ||
//     typeof assignmentsData[activeSection] === "undefined"
//   ) {
//     return undefined;
//   }

//   const dataSort = {
//     [SectionDataStatus.upcoming]: {},
//     [SectionDataStatus["past-due"]]: {},
//     [SectionDataStatus.completed]: {},
//   };

//   const assignments = assignmentsData[activeSection];

//   dataSort[SectionDataStatus.upcoming] = assignments.filter(
//     (section: any) => section?.sa?.status == SectionDataStatus.upcoming
//   );
//   dataSort[SectionDataStatus["past-due"]] = assignments.filter(
//     (section: any) =>
//       section?.sa?.status == SectionDataStatus.late_credit_available ||
//       section?.sa?.status == SectionDataStatus.no_late_available
//   );
//   dataSort[SectionDataStatus.completed] = assignments.filter(
//     (section: any) => section?.sa?.status == SectionDataStatus.completed
//   );

//   return dataSort;
// };

// find which section with the soonest 'upcoming' assignment due date
export const findNearestUpcoming = (assignmentData: any) => {
  const sortedData: any = [];
  for (const key in assignmentData) {
    assignmentData[key]
      .filter(
        (item: any) =>
          item?.sa?.status == SectionDataStatus.upcoming &&
          !autoArchive(assignmentData, key)
      )
      .map((item: any) => {
        sortedData.push({ section: key, date: item?.sa?.dueDate?.date });
      });
  }

  const closestDate = sortedData.reduce((nearest: any, current: any) => {
    return new Date(nearest.date) < new Date(current.date) ? nearest : current;
  }, sortedData[0]);

  return closestDate?.section;
};

// Check if latest due date in section is older than a set number of days
// RULE: Find the latest due date (in the past). If today's date is August 1 or LATER,
// and the lastest due date is July 31 or BEFORE, then if the latest due date is 60 days
// or further into the past, auto archive the class. Otherwise, if the latest due date
// is 150 days into the past, then auto archive the class.

// TODO: maybe useCallback() or useMemo()?
export const autoArchive = (assignmentData: any, section: string | number) => {
  // check if any assignments are in the section
  const closestTimestamp = assignmentData?.[section]?.length
    ? // if the section has only 1 assignment, grab it
      assignmentData[section]?.length === 1
      ? assignmentData[section][0]
      : // else cycle through getting the assignment with the closest due date
        assignmentData[section]?.reduce((nearest: any, current: any) => {
          return new Date(nearest.sa?.dueDate?.date) >=
            new Date(current.sa?.dueDate?.date)
            ? nearest
            : current;
        })
    : undefined;

  if (!closestTimestamp) return false;

  const closestDate = new Date(closestTimestamp.sa?.dueDate?.date);
  const nowDate = new Date();
  const dueInPast = closestDate ? nowDate > new Date(closestDate) : false;

  if (!dueInPast) return false;

  const dayDiff =
    closestDate && dueInPast
      ? (nowDate.getTime() - closestDate?.getTime()) / (1000 * 3600 * 24)
      : 0;
  const isDateAugustOrLater =
    closestDate >= new Date(closestDate?.getFullYear(), 7, 1);
  const isTodayAugustOrLater = nowDate >= new Date(nowDate.getFullYear(), 7, 1);
  const isDifferentYears = closestDate?.getFullYear() !== nowDate.getFullYear();

  // console.log(section, '====>', assignmentData?.[section]?.length);
  // console.log('isDateAfterAugust:',isDateAugustOrLater);
  // console.log([section, dueInPast, closestDate, dayDiff, closestDate?.getFullYear(), nowDate.getFullYear()]);

  // if the years between assignment due date and today
  // are different, or the latest due date is
  // August 1st or after, just check for 150 day difference
  if (isDifferentYears || isDateAugustOrLater) {
    return dayDiff >= 150 ? true : false;
  } else if (isTodayAugustOrLater) {
    // if today is after August 1st (previously established assignment due date is before August 1st)
    return dayDiff >= 60 ? true : false;
  } else {
    // both assignment due date and today are before August 1st
    return dayDiff >= 150 ? true : false;
  }
};

export const skillToSolve = (as?: any, asgmt?: any) => {
  const activeSection = as || getContext().activeSection();
  const dmAssignmentData = asgmt || getContext().dmAssignmentData();
  const { teacherId, skillCode } = useParams();

  let skill = {};

  if (!skillCode || !dmAssignmentData[activeSection]) return skill;

  for (const section of dmAssignmentData[activeSection]) {
    if (section.ta?._id == teacherId) {
      Object.keys(section.ta.skills).every((key) => {
        if (section.ta.skills[key].uid == skillCode) {
          skill = {
            ta: {
              ...section.ta,
              currentSkill: { ...section.ta.skills[key] },
              skillName: key,
            },
            sa: {
              ...section.sa,
              currentSkill: { ...section.sa.data[key] },
            },
            noPassbackFlag: section.noPassbackFlag,
          };
          return false;
        }
        return true;
      });
    }

    if (Object.keys(skill).length) break;
  }

  return skill;
};
// fix for the 'React has detected a change in the order of Hooks' warning
function getContext() {
  const { activeSection, dmAssignmentData } = useContext(
    StudentSectionsContext
  );
  return {
    activeSection: () => activeSection,
    dmAssignmentData: () => dmAssignmentData,
  };
}

// https://react.dev/warnings/invalid-hook-call-warning
// calling Context inside this function was causing this warning sometimes,
// but passing the context elements in as parameters stopped the issue.
// Primarily used for call in mapAssignmentSkills()
export const skillDataDisplay = (skill: string, section: any, as?: any) => {
  // const { activeSection, userValues } = useContext(StudentSectionsContext);
  const activeSection = as || getContext().activeSection();
  const currentSkill = section?.ta?.skills[skill];
  const studentData = section?.sa?.data[skill];
  const type = currentSkill?.type;

  const skillData: SkillDataType = {
    uid: currentSkill?.uid,
    url: `${REACT_APP_STUDENT_LINK}/${activeSection}/${section?.ta?._id}/${currentSkill?.uid}`,
    isCompleted: false,
    completion: "",
    scoreOnSolve: "",
    percentCompleted: 0,
    type: type,
    name: currentSkill?.name,
    progress: {
      showSegments: true,
      ...(currentSkill?.weight ? { weight: currentSkill.weight } : null),
    },
    isVideo: false,
    isTest: !!section?.ta?.is_test,
    isStandardSkill: false,
    isTimedModule: false,
    isTeacherType: type === "teacher_created",
    // helpVideoAvailable:
    //   (userValues?.hasPlus && currentSkill?.video_available === true) || false,
    maxProblemsOneDone: !!studentData?.maxProblemsOneDone,
    maxProblemsOneDoneCorrect:
      !!studentData?.record &&
      !!currentSkill?.required &&
      studentData?.record >= currentSkill?.required,
    maxProblems: currentSkill?.maxProblems,
    isMaxProblemType: !!currentSkill?.maxProblems,
    solvedProblems: currentSkill?.solvedProblems,
    // isMaxProblemType: currentSkill?.maxProblems !== undefined,
    // isMaxProblemComplete:
    //   currentSkill?.maxProblems !== undefined &&
    //   currentSkill?.solvedProblems !== undefined &&
    //   currentSkill?.solvedProblems >= currentSkill?.maxProblems,
    ...(section?.ta?.is_test
      ? {
          obscureResults: section?.ta?.obscureResults,
          solutionsAvailable: section?.ta?.solutionsAvailable,
        }
      : null),
    ...(section?.ta?.show_solutions
      ? { showSolutionsSetting: section.ta.show_solutions }
      : null),
  };

  switch (type) {
    case "timed_mixed":
    case "timed":
      skillData.isTimedModule = true;
      skillData.isCompleted =
        studentData?.score >= 100 || !!skillData.maxProblemsOneDone;
      skillData.completion = `${studentData?.score || 0}%`;
      skillData.percentCompleted = studentData?.score || 0;
      skillData.progress = {
        ...skillData.progress,
        showSegments: false,
        total: 100,
        score: studentData?.score || 0,
        // TODO: record?
      };
      skillData.icon = (
        <ClockIcon
          className="inline h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
      );
      break;
    case "teacher_created":
    case "particular_problem":
    case "subtype_selected":
    case "proper_mixed":
    case "standard":
    case "pure_standard":
    case "special":
      skillData.isStandardSkill = true;
      skillData.isCompleted =
        studentData?.record >= currentSkill?.required ||
        !!skillData.maxProblemsOneDone;
      skillData.completion = `${studentData?.record || 0}/${
        currentSkill?.required
      }`;
      skillData.scoreOnSolve = `${studentData?.score || 0}/${
        currentSkill?.required
      }`;
      skillData.percentCompleted =
        (studentData?.record / currentSkill?.required) * 100 || 0;
      skillData.progress = {
        ...skillData.progress,
        showSegments: true,
        total: currentSkill?.required,
        score: studentData?.score || 0,
        record: studentData?.record || 0,
      };
      break;
    case "dm_video":
    case "youtube_video": {
      skillData.isVideo = true;
      const skillDataExists =
        section?.sa?.video_data && skill in section.sa.video_data;
      skillData.isCompleted = skillDataExists
        ? section?.sa?.video_data[skill]?.grade >= 100
        : false;
      skillData.completion = skillDataExists
        ? `${section?.sa?.video_data[skill]?.grade || 0}%`
        : "0%";
      skillData.progress = {
        showSegments: false,
        total: 100,
        score: skillDataExists ? section?.sa?.video_data[skill]?.grade : 0,
        // TODO: record?
      };
      skillData.percentCompleted = skillDataExists
        ? section?.sa?.video_data[skill]?.grade
        : 0;
      skillData.icon = (
        <PlayIcon className="inline h-4 w-4 text-gray-400" aria-hidden="true" />
      );
      skillData.video = section?.ta.skills[skill].video;
      if (section?.sa?.video_data) {
        skillData.isCompleted = section?.sa?.video_data[skill]?.grade >= 100;
        skillData.completion = `${section?.sa?.video_data[skill]?.grade || 0}%`;
        skillData.progress = {
          showSegments: false,
          total: 100,
          score: section?.sa?.video_data[skill]?.grade || 0,
        };
        skillData.percentCompleted = section?.sa?.video_data[skill]?.grade || 0;
      }
      break;
    }
    default:
      break;
  }
  return skillData;
};

export const getAssignmentDueDateType = (
  status: SectionDataStatus
): AssignmentDueDateType => {
  let assignmentType = SectionDataStatus.upcoming;

  switch (status) {
    case SectionDataStatus.late_credit_available:
    case SectionDataStatus.no_late_available:
      assignmentType = SectionDataStatus["past-due"];
      break;
    case SectionDataStatus.completed:
      assignmentType = SectionDataStatus.completed;
      break;
    case SectionDataStatus.sampleStudentOverride:
      assignmentType = SectionDataStatus["preview"];
      break;
    case SectionDataStatus.upcoming:
    default:
      assignmentType = SectionDataStatus.upcoming;
      break;
  }
  return assignmentType;
};

export const getPenalty = (penalty: number): string => {
  if (penalty == undefined) return "";
  let penaltyString = "";
  switch (penalty.toString()) {
    case "-1":
      penaltyString = "back to zero";
      break;
    case "0":
      penaltyString = "none";
      break;
    default:
      penaltyString = `${penalty} off`;
      break;
  }
  return penaltyString;
};

export const timeLimitText = (timeLimit: any) => {
  const hours = Math.floor(timeLimit / 60);
  const minutes = timeLimit % 60;
  let timeStr = "";
  if (hours == 1) {
    timeStr += `${hours} hour`;
  } else if (hours > 1) {
    timeStr += `${hours} hours`;
  }
  if (hours > 0 && minutes > 0) {
    timeStr += " and ";
  }
  if (minutes == 1) {
    timeStr += `${minutes} minute`;
  } else if (minutes > 1) {
    timeStr += `${minutes} minutes`;
  }

  return timeStr;
};

export const useCountdown = (targetDate: any) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return printClockSentence(countDown);
};

export const getTimeDiff = (targetDate: any) =>
  new Date(targetDate).getTime() - new Date().getTime();

// const printClock = (countDown: number) => {
//   // calculate time left
//   let hours: any = Math.floor(
//     (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
//   );
//   hours = hours < 10 ? `0${hours}` : hours;
//   let minutes: any = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
//   minutes = minutes < 10 ? `0${minutes}` : minutes;
//   let seconds: any = Math.floor((countDown % (1000 * 60)) / 1000);
//   seconds = seconds < 10 ? `0${seconds}` : seconds;

//   return `${hours}:${minutes}:${seconds}`;
// };

export const printClockSentence = (countDown: number) => {
  // a 10 second buffer is added to the endTime. We remove the buffer (11 seconds to be safe)
  // so the countdown clock is accurate, but we give them back that buffer in the last minute
  const countdownWithBuffer = countDown > 30000 ? countDown - 11000 : countDown;
  const totalMinutes = Math.ceil(countdownWithBuffer / (1000 * 60));

  if (countDown <= 0) {
    return "No Time Remaining";
  } else if (totalMinutes <= 1) {
    return "Last minute!";
  } else if (totalMinutes < 180) {
    return `${totalMinutes} ${totalMinutes > 1 ? "mins" : "min"}`;
  } else {
    return `${timeLimitText(totalMinutes)}`;
  }
  return "";
};

// Get Timed Assignment related info
export const timedAssg = (skill: any, includeName = false) => {
  //console.log(skill)
  const isTimed = !!skill?.ta?.timeLimitData;
  const skillName = includeName ? `${skill?.ta?.name}: ` : "";
  // if (!isTimed) return null;

  const timeLimit = skill?.ta?.timeLimitData?.timeLimit;
  const timeLimitMS = timeLimit * 60 * 1000;
  const offset = skill?.ta?.timeLimitData?.offset || 0;
  const originalEndTime = skill?.ta?.timeLimitData?.endTime;
  const endTime = originalEndTime ? originalEndTime - offset : undefined;
  const serverTime = skill?.ta?.timeLimitData?.serverTime;
  const additionalTime = skill?.ta?.timeLimitData?.additional;
  const endTimeDate = endTime ? new Date(endTime * 1000).getTime() : undefined;
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const isUnlimited = skill?.ta?.timeLimitData?.timeLimit >= 100000000;
  const unlimitedEndTimeDate =
    isUnlimited && skill?.ta?.timeLimitData?.baseEndTime
      ? new Date(skill.ta.timeLimitData.baseEndTime * 1000).getTime()
      : undefined;
  const { activeSection, dmAssignmentData, setDmAssignmentData } = useContext(
    StudentSectionsContext
  );
  const [timerText, setTimerText] = useState(
    isUnlimited ? "Unlimited Time" : ""
  );
  let timeUp = endTimeDate && endTimeDate - new Date().getTime() <= 0;

  const [unlimitedTimeUp, setUnlimitedTimeUp] = useState(
    unlimitedEndTimeDate && unlimitedEndTimeDate <= new Date().getTime()
  );

  if (intervalRef.current) clearInterval(intervalRef.current);

  let needsUpdate = true;

  intervalRef.current = setInterval(() => {
    if (!endTimeDate) {
      if (intervalRef?.current) clearInterval(intervalRef.current);
      return;
    }
    const nowTime = new Date().getTime();
    const diff = endTimeDate - nowTime;
    if (!isUnlimited) {
      if (diff <= 0) {
        setTimerText("");
      } else if (diff <= 20000) {
        setTimerText(`${skillName}You have 20 seconds remaining.`);
      } else if (diff / timeLimitMS <= 0.5) {
        setTimerText(`${skillName}Your time is halfway up.`);
      } else {
        setTimerText("");
      }
    }

    if (isUnlimited && unlimitedEndTimeDate) {
      setUnlimitedTimeUp(unlimitedEndTimeDate <= nowTime);
    }
    timeUp = diff <= 0;
    // console.log(skill?.ta?.name, ":isUnlimited", isUnlimited);
    // console.log(skill?.ta?.name, ":unlimitedTimeUp", unlimitedTimeUp);
    // console.log(skill?.ta?.name, ":unlimitedEndTimeDate", unlimitedEndTimeDate);
    // console.log(skill?.ta?.name, ":nowTime", nowTime);

    if (!isUnlimited && diff <= 0 && intervalRef?.current) {
      clearInterval(intervalRef.current);
      if (needsUpdate) {
        updateStatus(
          dmAssignmentData,
          setDmAssignmentData,
          activeSection,
          skill?.ta?._id
        );
        needsUpdate = false;
      }
    }
    if (
      isUnlimited &&
      unlimitedEndTimeDate &&
      unlimitedEndTimeDate - nowTime <= 0 &&
      intervalRef?.current
    ) {
      clearInterval(intervalRef.current);
      if (needsUpdate) {
        updateStatus(
          dmAssignmentData,
          setDmAssignmentData,
          activeSection,
          skill?.ta?._id
        );
        needsUpdate = false;
      }
    }
  }, 1000);

  return {
    isTimed: isTimed,
    timeLimit: timeLimit,
    endTime: endTime,
    serverTime: serverTime,
    additionalTime: additionalTime,
    timerText: timerText,
    isUnlimited: isUnlimited,
    isOverUnlimitedTime: unlimitedTimeUp,
    // offset: offset, //TEMPORARY FOR TESTING
    // originalEndTime: originalEndTime, //TEMPORARY FOR TESTING
    // diff: timeDiff?.current, //TEMPORARY FOR TESTING
    // currentTimeUsed: skill?.ta?.timeLimitData?.currentTime, //TEMPORARY FOR TESTING
    isOver:
      isTimed &&
      endTime &&
      serverTime &&
      (serverTime >= originalEndTime || timeUp),
  };
};

const updateStatus = (
  dmAssignmentData: any,
  setDmAssignmentData: any,
  activeSection: any,
  taId: any
) => {
  const assignmentIndex: any = dmAssignmentData[activeSection]?.findIndex(
    (assignment: any) => assignment.ta._id === taId
  );
  if (
    assignmentIndex > -1 &&
    dmAssignmentData[activeSection][assignmentIndex]?.sa?.status !==
      "completed" &&
    dmAssignmentData[activeSection][assignmentIndex]?.sa?.status !==
      "sampleStudentOverride"
  ) {
    const newAssignmentObj = { ...dmAssignmentData };
    newAssignmentObj[activeSection][assignmentIndex].sa.status = "completed";
    setDmAssignmentData(newAssignmentObj);
  }
};

// If timed problem, process offset based on serverTime and student's clock
export const processAssignmentData = (data: any) => {
  Object.keys(data).forEach((section) => {
    data[section].filter((assignment: any) => {
      if (!assignment?.ta?.timeLimitData?.serverTime) return false;
      assignment = processIndividualAssignment(assignment);
    });
  });

  return data;
};

export const processIndividualAssignment = (assignment: any) => {
  const serverTime = assignment?.ta?.timeLimitData?.serverTime;

  if (!serverTime) return assignment;

  const latestServerTime = assignment?.ta?.timeLimitData?.latestServerTime;

  if (latestServerTime && serverTime <= latestServerTime) return assignment;

  const currentTime = Math.floor(Date.now() / 1000);
  assignment.ta.timeLimitData.latestServerTime = serverTime;
  assignment.ta.timeLimitData.offset = serverTime - currentTime;
  assignment.ta.timeLimitData.currentTime = currentTime; //TEMPORARY FOR TESTING

  return assignment;
};

/* Custom Hook for Managing Input Field Focus */
export function useFieldFocus(initialState = "") {
  const [globalFocusedInput, setGlobalFocusedInput] = useState(initialState);

  const handlers = useMemo(
    () => ({
      handleGlobalFocus: (mqID: any): void => {
        setGlobalFocusedInput(mqID);
      },
    }),
    []
  );

  return [globalFocusedInput, handlers] as const;
}

// https://usehooks.com/useScript/
export function useScript(src: string) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle");
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle");
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script: any = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // Create script
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.setAttribute("data-status", "loading");
        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: any) => {
          script.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error"
          );
        };
        script.addEventListener("load", setAttributeFromEvent);
        script.addEventListener("error", setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute("data-status"));
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: any) => {
        setStatus(event.type === "load" ? "ready" : "error");
      };
      // Add event listeners
      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent);
          script.removeEventListener("error", setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );
  return status;
}

/* Solve Utilities */

// const latexRef = useRef<Map<string, any> | null>(null);

// export const displayProblem = (
//   displayData: any,
//   problemData: any,
//   resizingData?: any,
//   locString?: string // TEMPORARY FOR DEV TESTING OF SPOKEN KATEX
// ) => {
//   // console.log(
//   //   locString,
//   //   "displayProblem problemData:",
//   //   problemData === undefined,
//   //   problemData,
//   //   displayData
//   // );
//   if (!displayData?.length || problemData === undefined) return <></>;
//   // console.log("past return");
//   ReactTooltip.rebuild();
//   return (
//     <div className="relative pt-8">
//       {displayData.map((line: any, index: any) => {
//         const marginTopStyle =
//           line?.format?.space !== undefined
//             ? { marginTop: `${line.format.space}px` }
//             : {};
//         const fontSizeStyle =
//           line?.format?.size !== undefined
//             ? { fontSize: `${line.format.size}` }
//             : {};
//         const inlineStyles = { ...marginTopStyle, ...fontSizeStyle };

//         const expStyles = line?.format?.expSize
//           ? { fontSize: `${line.format.expSize}` }
//           : {};
//         const tooltip = line?.exp
//           ? {
//               "data-for": "problem-tooltip",
//               "data-tip": line?.exp,
//               "data-multiline": true,
//               "data-place": "left",
//             }
//           : {};

//         // TEMPORARY, to show spoken KaTeX when user clicks an eq or line
//         const spokentooltip = {
//           "data-for": `${locString}-spoken-tooltip`,
//           "data-tip": line?.spoken,
//           "data-multiline": true,
//           "data-place": "top",
//           "data-effect": "solid",
//           "data-event": "click",
//         };
//         const customClasses = clsx(
//           line.format?.classes,
//           line.format?.answer ? "row-contains-answer-for-print" : null,
//           line.format?.copiedFromQuestion
//             ? "row-contains-line-copied-from-question"
//             : null,
//           line.format?.pdfHide ? "row-contains-line-hidden-on-pdf" : null,
//           line.hide_from_solution ? "hide-from-solution" : null
//         );
//         // TODO: for use with "Show Additional Steps" dropdown
//         const hidden = line.format?.hidden;
//         if (line.type === "eq") {
//           const eqFontSize =
//             resizingData?.[`row-${index}`]?.fontSize !== undefined
//               ? { fontSize: `${resizingData?.[`row-${index}`]?.fontSize}em` }
//               : {};
//           const eqLeftPercent =
//             resizingData?.[`row-${index}`]?.leftPercent !== undefined
//               ? { width: `${resizingData?.[`row-${index}`]?.leftPercent}%` }
//               : {};
//           return (
//             <div
//               key={`eq-${index}`}
//               className={clsx(
//                 "logic-row eq-type row hasJax mt-9 first:mt-0",
//                 customClasses
//               )}
//               style={inlineStyles}
//               data-index={`row-${index}`}
//               data-linetype="eq"
//               data-eqgroup={line.equationGroup}
//             >
//               <div className="sr-only">{line.spoken}</div>
//               <div
//                 className={clsx(
//                   "resize-katex jax line-equation col-xs-12 col-md-9",
//                   "max-sm:!px-0"
//                 )}
//                 style={{ ...eqFontSize }}
//                 {...spokentooltip}
//               >
//                 <div
//                   className="left-equation jax"
//                   style={{ ...eqLeftPercent }}
//                   dangerouslySetInnerHTML={{ __html: line.katexLeft }}
//                   data-index={`eq-left-${index}`}
//                 ></div>
//                 <div
//                   className={clsx("right-equation jax")}
//                   dangerouslySetInnerHTML={{ __html: line.katexRight }}
//                   data-index={`eq-right-${index}`}
//                 ></div>
//               </div>
//               <div
//                 className="col-md-3 hidden pl-1 text-sm leading-tight md:block"
//                 style={expStyles}
//                 dangerouslySetInnerHTML={{ __html: line.exp ? line.exp : null }}
//               ></div>
//               {line.exp ? (
//                 <InformationCircleIcon
//                   className="absolute right-1 h-4 w-4 text-gray-400 md:hidden"
//                   {...tooltip}
//                   aria-hidden={true}
//                 />
//               ) : null}
//             </div>
//           );
//         } else if (line.type === "line") {
//           return (
//             <div
//               key={`line-${index}`}
//               className={clsx(
//                 "logic-row line-type row hasJax mt-9 first:mt-0",
//                 customClasses
//               )}
//               style={inlineStyles}
//               data-index={`row-${index}`}
//               data-linetype="line"
//             >
//               <div className="sr-only">{line.spoken}</div>
//               <div
//                 className={clsx(
//                   "resize-katex jax",
//                   "max-sm:!px-0",
//                   line?.format?.rowClass
//                     ? line.format.rowClass
//                     : "col-md-9 col-xs-12",
//                   // TODO: erase classes that might not be needed for js or styling, like this maintain-equation-alignment
//                   line?.format?.maintainEquationAlignment
//                     ? "maintain-equation-alignment"
//                     : null
//                 )}
//                 dangerouslySetInnerHTML={{ __html: line.katex }}
//                 data-index={`line-${index}`}
//                 {...spokentooltip}
//               ></div>
//               {line.exp ? (
//                 <>
//                   <div
//                     className="col-md-3 hidden pl-1 text-sm leading-tight md:block"
//                     style={expStyles}
//                     dangerouslySetInnerHTML={{ __html: line.exp }}
//                   ></div>
//                   <InformationCircleIcon
//                     className="absolute right-1 h-4 w-4 text-gray-400 md:hidden"
//                     {...tooltip}
//                     aria-hidden={true}
//                   />
//                 </>
//               ) : null}
//             </div>
//           );
//         } else if (line.type === "html") {
//           return (
//             <div
//               key={`html-line-${index}`}
//               className={clsx(
//                 "html-type row hasJax mt-9 first:mt-0",
//                 customClasses
//               )}
//               style={inlineStyles}
//             >
//               <div
//                 className={clsx(
//                   "problem-html relative",
//                   line?.format?.htmlClass ? line.format.htmlClass : "col-xs-12"
//                 )}
//                 key={`html-${index}`}
//                 dangerouslySetInnerHTML={{
//                   __html:
//                     line.html.slice(-5) === ".html"
//                       ? problemData?.htmlTemplates?.[line.html.slice(0, -5)]
//                       : line.html,
//                 }}
//               ></div>
//             </div>
//           );
//         }
//       })}
//       {/* TEMPORARY, FOR TESTING OF SPOKEN KATEX */}
//       <ReactTooltip
//         id={`${locString}-spoken-tooltip`}
//         className="max-w-[80%] font-sans text-xs leading-7"
//       />
//       <ReactTooltip id="problem-tooltip" className="max-w-[80%] text-xs" />
//     </div>
//   );
// };

// based on katexSanitize purpose
export const processlines = (lines: any[]) => {
  if (!lines) return;

  let equationGroup = 1;
  let lastLineEquation = false;

  for (const line of lines) {
    if (line.type === "line") {
      line.katex = window.katex.renderToString(String(line.line), {
        displayMode: true,
        colorIsTextColor: true,
        throwOnError: false,
      });
      try {
        line.spoken = renderA11yString(
          String(line.line),
          undefined,
          true,
          line.format?.a11ySettings || false
        );
      } catch (error) {
        console.log("Error", error);
        console.log("Line with error:", String(line.line));
        line.spoken = line.line;
      }
    } else if (line.type === "eq") {
      const sign =
        line.format?.sign === "" ? "\\phantom{=}" : line.format?.sign || `=`;
      const spokenSign =
        line.format?.sign === "" ? "" : line.format?.sign || `=`;
      line.katexLeft = window.katex.renderToString(line.left + sign, {
        displayMode: true,
        colorIsTextColor: true,
        throwOnError: false,
      });
      line.katexRight = window.katex.renderToString(`\\,\\,` + line.right, {
        displayMode: true,
        colorIsTextColor: true,
        throwOnError: false,
      });
      try {
        line.spoken = renderA11yString(
          line.left + spokenSign + " " + line.right,
          undefined,
          true,
          line.format?.a11ySettings || false
        );
      } catch (error) {
        console.log("Error", error);
        console.log(
          "Line with error:",
          line.left + spokenSign + " " + line.right
        );
        line.spoken = line.left + spokenSign + " " + line.right;
      }
    }

    if (line.type === "eq") {
      line.equationGroup = equationGroup;
      lastLineEquation = true;
    } else if (lastLineEquation && !line?.format?.maintainEquationAlignment) {
      equationGroup++;
      lastLineEquation = false;
    }
  }

  return lines;
};

/* resize KaTeX lines to fit the width of their parent DOM element */
export const resizeKatex = (pageType: PageType, elements: any) => {
  const parentNode = PAGE_TYPE_ID_MAP[pageType];
  const showSolution = pageType === "solution" || pageType === "modal";

  const katexRow = document.querySelectorAll(`#${parentNode} .logic-row`);
  const elementSizes: any =
    elements[showSolution ? "solution" : "question"] || {};
  const maxByGroup: any = [];
  const allSidesFit: any = [];

  if (katexRow.length) {
    katexRow.forEach((el: any) => {
      if (!el?.dataset?.index || !el?.dataset?.linetype) return;
      switch (el?.dataset?.linetype) {
        case "line": {
          const subEl = el.querySelector(".resize-katex");
          const katexEl = subEl.querySelector(".katex-display > .katex");
          subEl.classList.add("overflow-hidden");

          // remove any previous font-size adjustments to accurately get the width ratio
          if (subEl.style.removeProperty) {
            subEl.style.removeProperty("font-size");
          } else {
            subEl.style.removeAttribute("font-size");
          }

          const origWidth = elementSizes[el.dataset.index]?.origWidth;
          const elWidth = subEl.clientWidth - 32; // padding on each side is 14px, plus a little extra
          elementSizes[el.dataset.index] = {
            origWidth:
              origWidth === undefined || origWidth === 0
                ? katexEl?.clientWidth
                : origWidth,
            elWidth: elWidth,
          };
          if (elementSizes[el.dataset.index].origWidth > elWidth) {
            const fontSize = elWidth / elementSizes[el.dataset.index].origWidth;
            subEl.style.fontSize = `${fontSize}em`;
          }

          // if after adjusting the font size the .katex block is still longer than it's parent
          // keep the overflow-hidden class on so katexEl can scroll on x
          if (katexEl?.clientWidth <= elWidth) {
            subEl.classList.remove("overflow-hidden");
          }

          break;
        }
        case "eq": {
          const eqGroup = el?.dataset?.eqgroup || undefined;
          const subEl = el.querySelector(".resize-katex");
          subEl.classList.add("overflow-hidden");

          // remove any previous font-size adjustments to accurately get the width ratio
          if (subEl.style.removeProperty) {
            subEl.style.removeProperty("font-size");
          } else {
            subEl.style.removeAttribute("font-size");
          }

          const leftEq = el.querySelector(
            ".left-equation .katex-display > .katex"
          );
          const rightEq = el.querySelector(
            ".right-equation .katex-display > .katex"
          );
          const origWidthLeft = elementSizes[el.dataset.index]?.origWidthLeft;
          const origWidthRight = elementSizes[el.dataset.index]?.origWidthRight;
          const elWidth = subEl.clientWidth - 32; // padding on each side is 14px, plus a little extra
          elementSizes[el.dataset.index] = {
            origWidthLeft:
              origWidthLeft === undefined || origWidthLeft === 0
                ? leftEq?.clientWidth
                : origWidthLeft,
            origWidthRight:
              origWidthRight === undefined || origWidthRight === 0
                ? rightEq?.clientWidth
                : origWidthRight,
            elWidth: elWidth,
            eqGroup: eqGroup,
          };
          if (!maxByGroup[eqGroup]) {
            maxByGroup[eqGroup] = { left: 0, right: 0 };
          }
          if (!allSidesFit[eqGroup]) {
            allSidesFit[eqGroup] = true;
          }
          maxByGroup[eqGroup].left = Math.max(
            maxByGroup[eqGroup].left,
            elementSizes[el.dataset.index].origWidthLeft
          );
          maxByGroup[eqGroup].right = Math.max(
            maxByGroup[eqGroup].right,
            elementSizes[el.dataset.index].origWidthRight
          );

          if (
            maxByGroup[eqGroup].left > elWidth / 2 + (35 / 2 / elWidth) * 100 ||
            maxByGroup[eqGroup].right > elWidth / 2 - (35 / 2 / elWidth) * 100
          ) {
            allSidesFit[eqGroup] = false;
          }
          subEl.classList.remove("overflow-hidden");
          break;
        }
        default:
          break;
      }
    });

    if (Object.keys(maxByGroup).length > 0) {
      katexRow.forEach((el: any) => {
        if (el?.dataset?.linetype !== "eq") return;
        const { origWidthLeft, origWidthRight, elWidth, eqGroup } =
          elementSizes[el.dataset.index];
        const leftPercent =
          ((Math.max(
            0,
            elWidth - (maxByGroup[eqGroup].left + maxByGroup[eqGroup].right)
          ) /
            2 +
            maxByGroup[eqGroup].left) /
            elWidth) *
          100;
        const leftFontsize = (elWidth * leftPercent) / 100 / origWidthLeft;
        const rightFontsize =
          (elWidth * (100 - leftPercent)) / 100 / origWidthRight;
        const fontSize = Math.min(1, leftFontsize, rightFontsize);

        elementSizes[el.dataset.index] = {
          ...elementSizes[el.dataset.index],
          leftPercent,
          fontSize,
        };
      });
    }
  }
  //add .table-responsive to the parent of all .table-page
  const tablePage = document.querySelectorAll(".table-page");
  tablePage.forEach((el: any) =>
    el.parentElement.classList.add("table-responsive")
  );

  return elementSizes;
};

export const resizeKatexLine = (el: any, fontSize?: string) => {
  const katexEl = el.querySelector(".katex");
  if (!el || !katexEl) return;

  el.classList.add("overflow-hidden");

  // remove any previous font-size adjustments to accurately get the width ratio
  if (el.style.removeProperty) {
    if (fontSize === undefined) el.style.removeProperty("font-size");
  } else {
    if (fontSize === undefined) el.style.removeAttribute("font-size");
  }

  const origWidth = katexEl.getBoundingClientRect().width;
  const elWidth = el.clientWidth - 28; // room for padding

  if (origWidth > elWidth) {
    const fontSize = elWidth / origWidth;
    el.style.fontSize = `${fontSize}em`;
  } else if (fontSize !== undefined) {
    el.style.fontSize = fontSize;
  }

  el.classList.remove("overflow-hidden");
};

export function getRenderMathSettings() {
  return {
    colorIsTextColor: true,
    delimiters: [
      { left: "$$", right: "$$", display: true },
      { left: "\\(", right: "\\)", display: false },
      { left: "\\[", right: "\\]", display: true },
      { left: "`", right: "`", display: false },
    ],
    ignoredClasses: ["do-not-render-math"],
  };
}

export const processInlineMath = (latex: string, displayMode?: boolean) => {
  if (!latex) return;
  const renderOptions = {
    colorIsTextColor: true,
    throwOnError: false,
    ...(displayMode ? { displayMode: true } : {}),
  };
  let spokenText;
  try {
    spokenText = renderA11yString(latex);
  } catch (error) {
    console.log("Error:", error);
    console.log("Latex with error:", latex);
    spokenText = latex;
  }
  return {
    katex: window.katex.renderToString(latex, renderOptions),
    spoken: spokenText,
    latex,
  };
};

export const displayInlineMath = (inlineMath: any, displayMode?: boolean) => {
  if (!inlineMath) return <></>;

  if (typeof inlineMath === "string")
    inlineMath = processInlineMath(inlineMath, displayMode);

  return (
    <Fragment>
      <span className="sr-only">{inlineMath.spoken}</span>
      <span dangerouslySetInnerHTML={{ __html: inlineMath.katex }}></span>
    </Fragment>
  );
};

export const scrollToView = (
  ref?: any,
  behavior: any = "smooth",
  block = "start"
) => {
  if (ref) {
    ref?.current?.scrollIntoView({
      behavior: behavior,
      block: block,
    });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: behavior,
    });
  }
};

export const checkForCustomFiles = async (problem: any) => {
  // async function checkForCustomFiles(problem: any) {
  let filesToGet: string[] = []; // rarely it could be two
  if (problem.data.sharedExternalFile) {
    filesToGet.push(
      problem.data.sharedExternalFile.indexOf("shared/") === -1
        ? "shared/" + problem.data.sharedExternalFile
        : problem.data.sharedExternalFile
    );
    if (
      problem.data.sharedExternalFileExtend &&
      !problem.test &&
      problem.data.sharedExternalFile
    ) {
      filesToGet.push(problem.custom_file || problem.skillcode);
    }
  } else if (
    (problem.data.externalUrlExists || problem.ansType === "custom") &&
    !problem.test
  ) {
    filesToGet.push(problem.custom_file || problem.skillcode);
  }
  filesToGet = filesToGet.map((file) => {
    if (problem.test) {
      return file.replace("shared/", "shared-").replace(".html", "");
    } else {
      return `custom_files/${file.replace(
        "shared/",
        "shared-"
      )}.json?time=${Math.round(new Date().getTime() / 1800000)}`; //query param updates every 30 minutes
    }
  });
  /*
      Remaining TODO when ported to teacher application
          this.skillcodesService.replaceCustomFileNameMap used for DeltaMath staff to test new custom files before going live
          in the programming environment, custom files are attached to problem already, not from API
  */

  const files = problem.test
    ? await Promise.all(
        filesToGet.map((fileName) =>
          fetch(`http://localhost:8002/get_shared_file/${fileName}`).then(
            (response) => response.json()
          )
        )
      )
    : await Promise.all(
        filesToGet.map((fileName) =>
          fetch(`${deltamathAPI()}/${fileName}`).then((response) =>
            response.json()
          )
        )
      );
  // Eric/Hannah: use our actual system for getting responses from our API. Just wrote fetch here as a shortcut.
  if (problem.test) {
    // This is for running locally, we load this script and we want to make sure it is executed
    if (
      problem.customCode &&
      problem.customCode.code &&
      !(
        problem.data.sharedExternalFile &&
        !problem.data.sharedExternalFileExtend
      )
    ) {
      files.push(problem.customCode);
    }
  }
  return files;
};

export const generateProblemScripts = (
  files: any,
  problem: any,
  answerData?: any
) => {
  // define the problem's custom data, add answerData, if included (to render student solutions)
  problem.data.data = {
    ...problem.data.data,
    ...(answerData ? { answerData } : null),
  };

  for (const customFile of files) {
    if (isEmpty(customFile)) {
      continue;
    }
    processHtmlTemplates(customFile, problem); // copy templates from customFile to problem
    const problemScripts = processCustomFile(customFile); // eval custom code with wrapping function to create a closure

    if (!problem.problemScripts) {
      // first time through problem.problemScripts is undefined, set entire object
      problem.problemScripts = problemScripts(problem.data.data);
    } else {
      // potentially a second time through, overwrite only those that exist (2nd time would be the specific module extending a shared file)
      const tempScripts = problemScripts(problem.data.data);
      for (const key in tempScripts) {
        if (tempScripts[key] !== false) {
          problem.problemScripts[key] = tempScripts[key];
        }
      }
    }
  }
  return problem;
};

export const processCustomFile = (customFile: any) => {
  let scripts = customFile.code;

  // change all url("images/...")" references in the modules to point to the
  // root, where all the module images sit, like "images/icons/pencil3.png"
  scripts = scripts.replaceAll('url("images/', 'url("/images/');
  scripts = scripts.replace(
    /(questionScripts|solutionScripts|answerScripts)\s*=\s*function\s*\(\)/g,
    "$1 = function(page)"
  );
  scripts =
    "problemScripts = function(data){\
    \t\
    " +
    scripts +
    '\n\
      \t\t\t\t\t\t\t\t\tif(typeof solutionScripts === "undefined") var solutionScripts = false;\
      if(typeof questionScripts === "undefined") var questionScripts = false;\
      if(typeof answerScripts === "undefined") var answerScripts = false;\
      return {\
          questionScripts: questionScripts,\
          solutionScripts: solutionScripts,\
          answerScripts: answerScripts\
      };\n};\n//# sourceURL=customCode.js';

  let problemScripts: any;
  try {
    eval(scripts);
  } catch (e) {
    console.log(e);
  }
  return problemScripts;
};

const processHtmlTemplates = (customFile: any, problem: any) => {
  if (!problem.htmlTemplates) problem.htmlTemplates = {};
  if (!customFile.htmlTemplates) return;
  for (const key in customFile.htmlTemplates) {
    problem.htmlTemplates[key] = customFile.htmlTemplates[key];
  }
  //console.log("problem.htmlTemplates", problem.htmlTemplates)
};

// render a fake latex object first to initialize KaTeX renderer
export const fakeLatexRender = () => {
  const testElement = document.getElementById("random-math");

  if (testElement === null) {
    const randomMathElement = document.createElement("span");
    randomMathElement.id = "random-math";
    randomMathElement.innerText = "\\[x^2\\]";
    randomMathElement.classList.add("sr-only");
    randomMathElement.ariaHidden = "true";
    document.body.appendChild(randomMathElement);
    renderMathInElement(randomMathElement);
  }
};

/* update full sa/ta assignmentData for this assignment */
export const updateFullAssignmentData = (
  dataObj: any,
  taId: number,
  activeSection: any,
  dmAssignmentData: any,
  setDmAssignmentData: any
) => {
  if (!dataObj) return;

  dmAssignmentData[activeSection].filter((assignment: any, index: number) => {
    if (assignment.ta._id === taId) {
      const assignmentObj = { ...dmAssignmentData };

      assignmentObj[activeSection][index] = {
        ...assignmentObj[activeSection][index],
        ...processIndividualAssignment(dataObj),
      };
      setDmAssignmentData({ ...assignmentObj });
      return;
    }
  });
};

/* Global configuration options for MathQuill fields */
export const dmMqConfig = (
  config?: Record<string, any>
): Record<string, any> => ({
  restrictMismatchedBrackets: true,
  spaceBehavesLikeTab: true,
  sumStartsWithNEquals: false,
  supSubsRequireOperand: true,
  charsThatBreakOutOfSupSub: "+-=<>",
  autoCommands: "pi theta sqrt sum ge le approx pm nthroot infty cup",
  autoOperatorNames:
    "sin cos tan arccos arcsin arctan arccot arcsec arccsc lim csc sec cot log ln Ans ans or undefined DNE",
  substituteTextarea: function () {
    const subbedElement = document.createElement("textarea");
    const attributes: Record<string, string> = {
      inputmode: "none",
      autocorrect: "off",
      autocapitalize: "off",
      autocomplete: "off",
      spellcheck: "false",
    };
    Object.keys(attributes).forEach((key) => {
      subbedElement.setAttribute(key, attributes[key]);
    });
    return subbedElement;
  },
  ...config,
});

/**
 * Generates MathQuill fields from any elements on the current page with a "mathquill-editable" class
 * @param focusFunc function that will be executed when an MQ field is focused
 * @param mqConfig any special MathQuill config options
 * @param contextSpecificCallback function optionally executed for each MQ field
 * @return eventMap, which stores all event listeners generated in this function (so they can be cleaned up when needed)
 */
export const generateMQ = (
  focusFunc: (el: Element, mq: any, isTouchDevice: boolean) => void,
  mqConfig?: Record<string, any>,
  contextSpecificCallback?: (el: Element, mq: any, index: number) => void
): EventMap => {
  const MQ = (window as any).MQ;

  const mqLatexMap: Map<string, string> = new Map();

  const eventMap: EventMap = new Map();

  const mqWrappers = document.querySelectorAll(".mathquill-editable");
  if (mqWrappers.length) {
    /* Determine if user is on a touch device */
    const isTouchDevice: boolean = (window as any).is_touch_device();

    /* Set global config defaults */
    MQ.config(dmMqConfig(mqConfig));

    mqWrappers.forEach((el: Element, index: number) => {
      /* Create MQ Element */
      const mq = MQ.MathField(el);

      /* Configure MQ handler to prevent long decimals */
      mq.config({
        handlers: {
          edit: (mq: any) => {
            const latex = mq.latex();
            if (/\.\d{8,}/.test(latex)) mq.latex(mqLatexMap.get(mq.id));
            else if (/0{8,}/.test(latex)) mq.latex(mqLatexMap.get(mq.id));
            else mqLatexMap.set(mq.id, mq.latex());
          },
        },
      });

      /* If the wrapper has an aria-label, set that label on the MQ element, remove from wrapper */
      if (el.hasAttribute("aria-label")) {
        mq.setAriaLabel(el.getAttribute("aria-label"));
        el.removeAttribute("aria-label");
      }

      /* Add focus in event listener */
      const textareaEl = el.querySelectorAll("textarea")[0];
      const focusInFunc = () => focusFunc(el, mq, isTouchDevice);

      if (textareaEl) {
        textareaEl.addEventListener("focusin", focusInFunc);
        // update the event listeners in focusEvents map
        eventMap.set({ element: textareaEl, type: "focusin" }, focusInFunc);
      }

      /* Add click event to more easily focus MathQuill inputs on touchscreen */
      if (isTouchDevice) {
        const clickAndFocus = () => {
          mq.focus();
        };
        el.addEventListener("click", clickAndFocus);
        // update the event listeners in focusEvents map
        eventMap.set({ element: el, type: "click" }, clickAndFocus);
      }

      contextSpecificCallback?.(el, mq, index);
    });
  }

  return eventMap;
};

/**
 * Cleans up all events stored in the supplied map
 */
export const eventCleanUp = (eventMap: EventMap) => {
  eventMap.forEach((evListenerFunc, elObject) => {
    elObject.element.removeEventListener(elObject.type, evListenerFunc);
  });
};

// if assignment requires a passcode, a token is placed in the student's
// local storage that must be passed to certain endpoints, such as
// problemByAssignment & startTimedAssignment
export const setLSPasscodeToken = (taId: string) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const pcToken = localStorage.getItem("pc_" + taId + "_" + user?._id);

  if (pcToken !== null) {
    return { passcode_token: pcToken };
  } else {
    return {};
  }
};

// function that returns an object with two methods, used to encode and decode problem.data
export const obfuscate = (passphrase: string) => {
  return {
    hide: <T = any,>(o: T): string =>
      CryptoJS.AES.encrypt(JSON.stringify(o), passphrase).toString(),
    reveal: <T = any,>(val: string): T =>
      JSON.parse(
        CryptoJS.AES.decrypt(val, passphrase).toString(CryptoJS.enc.Utf8)
      ),
  };
};
