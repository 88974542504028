import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  Course,
  CoursesResponse,
  Subunit,
  Unit,
  Skill,
  myCourseDataType,
  myCoursesUnitDataType,
  SubunitsResponse,
} from "../types";
import { useDMQuery } from "../../utils";
import { compact, cloneDeep } from "lodash";
import { LearnerHolder } from "./LearnerContext";

interface CourseApi {
  getCourseData(courseId: string | undefined): Course | undefined;
  getAllCoursesData(): Course[] | undefined;
  getUnitData(
    unitId: string | undefined,
    coursePathOrId: string | undefined
  ): Unit | undefined;
  getSkillData(unitId: string): Record<string, Skill>;
  getSubunitData(
    subunitId: string | undefined,
    unitPathOrId: string | undefined,
    coursePathOrId: string | undefined
  ): Subunit | undefined;
  getSkillName(sk: string, subunitId: string | undefined): string | undefined;
}

type CourseState = {
  courses: Course[];
  units: Unit[];
  subunits: Subunit[];
  isLoadingCourse: boolean;
  isLoadingSubunits: boolean;
};

export class CourseHolder implements CourseApi {
  readonly state: CourseState;
  private setUnitIdsToFetchSubunits: (unitId: string[]) => void;

  getCourseData(courseId: string | undefined): Course | undefined {
    if (!courseId) {
      return undefined;
    }
    return this.state.courses.find(
      (c) => c.id === courseId || c.friendlyPath === courseId
    );
  }

  getAllCoursesData(): Course[] {
    return this.state.courses;
  }

  getUnitData(
    unitId: string | undefined,
    coursePathOrId: string | undefined
  ): Unit | undefined {
    const course = this.getCourseData(coursePathOrId);

    const unit = this.state.units.find(
      (u) =>
        (u.id === unitId || (u.friendlyPath && u.friendlyPath === unitId)) &&
        (!course || course.id === u.courseId)
    );

    return unit;
  }

  getSkillData(unitId?: string): Record<string, Skill> {
    const allSkillInfo: Record<string, Skill> = {};
    this.getUnitData(unitId, undefined)?.subunits?.forEach(
      (subunit: Subunit) => {
        subunit.skills.forEach((skill: Skill, index: number) => {
          allSkillInfo[skill.skillCode] = {
            ...skill,
            skillName: skill.skillName,
            subunitId: subunit.id,
            unitId: subunit.unitId,
            courseId: subunit.courseId,
            position: index,
          };
        });
      }
    );

    return allSkillInfo;
  }

  // This should handle any extra data loading or specific logic that has to do with a course
  setActiveCourse(courseId: string | undefined) {
    if (!courseId) {
      return;
    }

    const units = this.state.units.filter((u) => u.courseId === courseId);

    if (
      !units ||
      (compact(units.flatMap((x) => x.subunitOrder)).length !== 0 &&
        compact(units.flatMap((x) => x.subunits)).length === 0)
    ) {
      this.getSubunits(units.map((u) => u.id));
    }
  }

  getSubunitData(
    subunitId: string | undefined,
    unitPathOrId: string | undefined,
    coursePathOrId: string | undefined
  ): Subunit | undefined {
    if (!subunitId) {
      return;
    }
    const unit = this.getUnitData(unitPathOrId, coursePathOrId);

    return this.state.subunits.find(
      (su) =>
        (su.id === subunitId || su.friendlyPath === subunitId) &&
        (!unit || unit.id === su.unitId)
    );
  }

  getSkillName(sk: string, subunitId: string | undefined): string | undefined {
    if (!subunitId) {
      return sk;
    }

    const subunit = this.state.subunits.find((su) => su.id === subunitId);
    return (
      subunit?.skills?.find((skill) => skill.skillCode === sk)?.skillName || sk
    );
  }

  /**
   * Returns the set of all courses (in sort order) that includes both courses the
   * learner has some progress in and the course that the learner is currently viewing.
   */
  getCurrentLearnerCourses(
    currentCourseId: string | undefined,
    learnerContext: LearnerHolder
  ): myCourseDataType[] {
    const allCoursesData = this.getAllCoursesData();
    return compact(
      allCoursesData.map((course) => {
        const myProgress = learnerContext.getProgress(course.id);
        const myUnitData: Array<myCoursesUnitDataType> = compact(
          course.unitOrder.map((unitId) => {
            const unit = course?.units?.find((u) => u.id === unitId);
            if (!unit || unit?.subunitOrder.length <= 0) return null;
            const myUnitProgress = myProgress?.units?.find(
              (u) => u.unitId === unitId
            );
            const subunitsProgress = unit?.subunitOrder.map((suId) => {
              const subunit = myUnitProgress?.subunits.find(
                (sub) => sub.subunitId === suId
              );

              // If progress is 0 but pre-quiz has been started, I set progress to a small amount so the
              // segment on the unit icon in unit summary will show as light blue (in progress).
              // These values are not used anywhere else, only for determining the color of the section segment
              const progress =
                subunit && subunit?.progress === 0 && !!subunit?.preQuiz
                  ? 0.01
                  : subunit?.progress;
              return {
                id: suId,
                progress: progress || 0,
              };
            });

            if (subunitsProgress !== undefined) {
              // If progress is 0 but unit test has been started, set progress
              // to a small amount so progress pie chart shows it in progress
              const unitTestProgress =
                myUnitProgress?.unitTest &&
                myUnitProgress?.unitTest?.progress === 0
                  ? 0.01
                  : myUnitProgress?.unitTest?.progress;

              subunitsProgress.push({
                id: "unit-test",
                progress: unitTestProgress || 0,
              });
            }

            return {
              id: unit.id || "",
              name: unit.name || "",
              path: unit.path,
              progress: myUnitProgress?.progress,
              ...(subunitsProgress !== undefined && {
                subunits: subunitsProgress,
              }),
            };
          })
        );

        if (myProgress || currentCourseId === course.id) {
          return {
            id: course.id,
            name: course.name,
            path: course.path,
            progress: myProgress?.progress,
            ...(myUnitData !== undefined && { units: myUnitData }),
          };
        }
        return null;
      })
    );
  }

  private async getSubunits(unitIds: string[]): Promise<void> {
    // call this to update the unitToFetch state in the provider
    // the update of this will trigger a refetch of useDMQuery getting the subunits
    // Which will update the state
    // We are doing it this way rather than just axios calls to utilize caching
    this.setUnitIdsToFetchSubunits(unitIds);
  }

  constructor(
    state: CourseState,
    updateUnitToFetchSubunits: (unitId: string[]) => void
  ) {
    this.state = state;
    this.setUnitIdsToFetchSubunits = updateUnitToFetchSubunits;
  }
}

const CourseContext = React.createContext<CourseHolder>({} as CourseHolder);

export default CourseContext;

export function CourseContextProvider({
  children,
}: {
  children: ReactElement;
}) {
  const [unitsToFetch, setUnitsToFetch] = useState<string[] | undefined>();
  const [state, setState] = useState<CourseState>({
    courses: [],
    units: [],
    subunits: [],
    // Set to true to avoid flashing an error message when the page first renders
    isLoadingCourse: true,
    isLoadingSubunits: false,
  });

  const saveState = (partialState: Partial<CourseState>) => {
    setState({ ...state, ...partialState });
  };

  // **************
  // * Course Data
  // **************

  const { refetch: courseRefetch } = useDMQuery<CoursesResponse>({
    path: "/learner/course/courses",
    queryOptions: {
      enabled: false,
      onSuccess: (data: CoursesResponse) => {
        saveState({
          courses: data.map((c) => {
            return {
              ...c.course,
              path: c.course.friendlyPath || c.course.id,
              units: c.units.map((u) => {
                return {
                  ...u,
                  path: u.friendlyPath || u.id,
                  subunits: u.subunits || [],
                };
              }),
            };
          }),
          units: data.flatMap((c) =>
            c.units.map((u) => {
              return {
                ...u,
                path: u.friendlyPath || u.id,
              };
            })
          ),
          isLoadingCourse: false,
        });
      },
      onError() {
        saveState({ isLoadingCourse: false, isLoadingSubunits: false });
      },
    },
  });

  useEffect(() => {
    saveState({ isLoadingCourse: true });
    courseRefetch();
    // Including saveState here causes it to constantly refetch courses
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseRefetch]);

  // **************
  // * Subunit Data
  // **************
  const { refetch: subunitRefetch } = useDMQuery<SubunitsResponse>({
    path: `/learner/course/unit/subunits?unitIds=${(unitsToFetch || []).join(
      ","
    )}`,
    queryOptions: {
      enabled: false,
      onSuccess: (data: SubunitsResponse) => {
        const units: Unit[] = state.units.filter((u) =>
          data.subunits.some((d) => d.unitId === u.id)
        );

        data.subunits.forEach((subunit) => {
          const unit = units.find((u) => u.id === subunit.unitId);
          if (!unit) {
            return;
          }
          // Populate the courseId on the subunit
          unit.subunits = [
            ...(unit.subunits || []),
            {
              ...subunit,
              path: subunit.friendlyPath || subunit.id,
              courseId: unit.courseId,
            },
          ];
        });

        // make sure the updated unit exists on the course
        const courses = cloneDeep(state.courses).filter((c) =>
          units.some((u) => u.courseId === c.id)
        );
        courses.forEach((c) => {
          const unitsForCourse = units.filter((u) => u.courseId === c.id);
          c.avgQuizTime = data?.avgCourseTestTime || 0;
          if (c.units) {
            c.units = [
              ...c.units.filter(
                (u) => !unitsForCourse.some((ufc) => ufc.id === u.id)
              ),
              ...unitsForCourse,
            ];
          } else if (unitsForCourse.length > 0) {
            c.units = unitsForCourse;
          }
        });

        const updatedCourses: Course[] = [];
        state.courses.forEach((stateCourse) => {
          const replaceCourse = courses.find(
            (course) => course.id === stateCourse.id
          );
          if (replaceCourse) {
            updatedCourses.push(replaceCourse);
          } else {
            updatedCourses.push(stateCourse);
          }
        });

        saveState({
          courses: [...updatedCourses],
          units: [
            ...state.units
              .filter((x) => !units.some((u) => u.id === x.id))
              .map((u) => {
                return {
                  ...u,
                  path: u.friendlyPath || u.id,
                  avgQuizTime: data?.avgUnitTestTimes?.[u.id] || 0,
                };
              }),
            ...units.map((u) => {
              return {
                ...u,
                path: u.friendlyPath || u.id,
                avgQuizTime: data?.avgUnitTestTimes?.[u.id] || 0,
              };
            }),
          ],
          subunits: [
            ...state.subunits,
            ...data.subunits.map((s) => {
              return {
                ...s,
                path: s.friendlyPath || s.id,
              };
            }),
          ],
          isLoadingSubunits: false,
        });
      },
      onError() {
        saveState({ isLoadingSubunits: false });
      },
    },
  });

  useEffect(() => {
    if (unitsToFetch && unitsToFetch.length > 0) {
      saveState({ isLoadingSubunits: true });
      subunitRefetch();
    }
    // Including saveState here causes it to constantly refetch subunits
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subunitRefetch, unitsToFetch]);

  const updateUnitsToFetch = (unitIds: string[]) => {
    if (!unitIds.every((u) => (unitsToFetch || []).some((ids) => u === ids))) {
      setUnitsToFetch(unitIds);
    }
  };

  return (
    <CourseContext.Provider value={new CourseHolder(state, updateUnitsToFetch)}>
      {children}
    </CourseContext.Provider>
  );
}

export function useCourseContext(): CourseHolder {
  return useContext(CourseContext);
}
