import { useUserContext } from "../../../shared/contexts/UserContext";
import clsx from "clsx";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { NavLink } from "react-router-dom";
import { ProfileLink } from "./ProfileLink";

const ProfileButton: React.FC<{ link: string }> = ({ link }) => {
  const learnerContext = useLearnerContext();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userName =
    user?.first === undefined && user?.last === undefined
      ? "signing out..."
      : `${user.first} ${user.last}`;

  const userContext = useUserContext();

  const logout = () => {
    learnerContext.logoutLearner();
    window.location.href = `${REACT_APP_LEARNER_LINK}/sign-in`;
  };

  return (
    <div className="flex justify-between gap-x-2 px-2 text-sm text-dm-gray-200 hover:text-dm-gray-600">
      <ProfileLink
        isMinimized={learnerContext.isSidebarMinimized}
        userName={userName}
        link={link}
      />
      {!learnerContext.isSidebarMinimized &&
        userContext.state.decodedJwt?.data?.decampTarget === undefined && (
          <button
            className="align-middle text-sm text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70"
            onClick={logout}
            aria-label="log out"
          >
            Log Out
          </button>
        )}
    </div>
  );
};

export default ProfileButton;
